import { RefObject } from 'react';
import { clsx } from 'clsx';
import { useCopy } from '../hooks/useCopy';
import { Button } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/solid';
import { CopyIcon } from '../Icons';

type CopyButtonProps = {
  valueToCopy: string | RefObject<HTMLElement>;
  label: string;
  variant?: 'inline' | 'button';
};

export const CopyButton = ({
  label,
  valueToCopy,
  variant = 'button',
}: CopyButtonProps) => {
  const [onCopy, isCopied] = useCopy(valueToCopy);
  const Icon = isCopied ? CheckIcon : CopyIcon;

  return (
    <Button
      className="group flex items-center gap-1.5 whitespace-nowrap outline-2 outline-offset-2 data-[focus]:outline-rose-700"
      type="button"
      onClick={onCopy}
    >
      {/* TODO: Tooltip */}
      <span
        className={clsx(
          'font-semibold text-teal-800 group-hover:text-teal-900',
          variant === 'inline' && 'sr-only',
        )}
      >
        {isCopied ? 'Copied!' : `Copy ${label}`}
      </span>

      <Icon className="size-4 text-teal-700 group-hover:text-teal-800" />
    </Button>
  );
};
