import { clsx } from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { Button, ButtonProps, Field, Input } from '@headlessui/react';
import { MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/outline';
import { FieldError } from './FieldError';
import { TaskLabel } from './TaskLabel';
import { TaskQuestionInputBaseProps } from './types';

type IncrementButtonProps = {
  isDecrement?: boolean;
  step: number;
  value?: number;
  min?: number;
  max?: number;
  variant: 'medium' | 'large';
} & Omit<
  ButtonProps,
  'className' | 'type' | 'aria-label' | 'children' | 'style'
>;

const IncrementButton = ({
  isDecrement = false,
  step,
  variant,
  disabled,
  value,
  min,
  max,
  ...rest
}: IncrementButtonProps) => {
  const Icon = isDecrement ? MinusCircleIcon : PlusCircleIcon;
  let isOutOfRange = false;

  if (value) {
    if (isDecrement && min !== undefined) {
      isOutOfRange = value <= min;
    } else if (max !== undefined) {
      isOutOfRange = value >= max;
    }
  }

  return (
    <Button
      {...rest}
      type="button"
      aria-label={`${isDecrement ? 'Decrement' : 'Increment'} value by ${step}`}
      disabled={disabled ?? isOutOfRange}
      className={clsx(
        'group shrink-0 focus:outline-none',
        variant === 'large' && 'px-7',
      )}
    >
      <div className="rounded-full text-sage-700 group-hover:text-sage-800 group-focus-visible:ring-2 group-focus-visible:ring-teal-600 group-data-[disabled]:text-gray-300">
        <Icon
          className={clsx(
            variant === 'medium' && 'size-[22px]',
            variant === 'large' && 'size-8',
          )}
          strokeWidth={variant === 'medium' ? 1.5 : 2}
        />
      </div>
    </Button>
  );
};

type TaskNumberFieldProps = TaskQuestionInputBaseProps;

export const TaskNumberField = ({
  variant = 'large',
  error: serverError,
  label,
  disabled,
  defaultValue,
  min = 0,
  max = Number.MAX_SAFE_INTEGER,
  step = 1,
  onChange,
  ...rest
}: TaskNumberFieldProps) => {
  const { name } = rest;
  const inputRef = useRef<HTMLInputElement>(null);

  if (typeof min === 'string') {
    min = Number.parseFloat(min) || 0;
  }

  if (typeof max === 'string') {
    max = Number.parseFloat(max) || Number.MAX_SAFE_INTEGER;
  }

  if (typeof step === 'string') {
    step = Number.parseFloat(step) || 1;
  }

  const [value, setValue] = useState<string>(
    defaultValue?.toString() || min.toString(),
  );

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.reportValidity();
    }
  }, [value]);

  const handleValueChange = (isDecrement = false) => {
    let newValue = Number.parseInt(value || '0');

    if (Number.isNaN(newValue)) {
      newValue = 0;
    }

    newValue = isDecrement
      ? Math.max(min, newValue - step)
      : Math.min(max, newValue + step);

    setValue(newValue.toString());
  };

  const handleDecrement = () => {
    handleValueChange(true);
  };

  const handleIncrement = () => {
    handleValueChange();
  };

  const errorMessageId = `form-field-error-message-${name}`;
  const showServerError = Boolean(serverError);

  return (
    <Field disabled={disabled}>
      <div
        className={clsx(
          variant === 'medium' && 'flex flex-wrap items-center gap-x-6 gap-y-2',
        )}
      >
        <TaskLabel variant={variant} label={label} />

        <div
          className={clsx(
            'flex items-center gap-x-2',
            variant === 'large' &&
              'h-20 w-fit flex-wrap rounded border border-stone-200',
            variant === 'large' && showServerError && 'mb-2',
            variant === 'medium' && 'ml-auto grow-0',
          )}
        >
          <IncrementButton
            isDecrement
            onClick={handleDecrement}
            step={step}
            variant={variant}
            min={min}
            value={Number.parseFloat(value)}
          />

          <Input
            {...rest}
            ref={inputRef}
            type="number"
            min={min}
            max={max}
            step={step}
            invalid={showServerError}
            className={clsx(
              'hide-spin-buttons peer rounded border border-stone-200 py-2 text-center transition-shadow duration-150 hover:border-gray-300 focus:border-teal-500 focus:ring-teal-500 data-[invalid]:border-red-300 data-[invalid]:hover:border-red-500 data-[invalid]:focus:border-red-500 data-[invalid]:focus:ring-red-500 [&:user-invalid]:border-red-300 [&:user-invalid]:hover:border-red-500 [&:user-invalid]:focus:border-red-500 [&:user-invalid]:focus:ring-red-500',
              variant === 'large' &&
                'max-w-64 border-transparent text-[40px] font-medium leading-[48px]',
              variant === 'medium' &&
                'box-content min-w-4 max-w-20 shrink-0 grow-0 px-3',
            )}
            aria-errormessage={errorMessageId}
            value={value}
            onChange={(event) => {
              if (onChange) {
                onChange(event);
              }

              setValue(event.target.value);
            }}
          />

          <IncrementButton
            onClick={handleIncrement}
            step={step}
            variant={variant}
            max={max}
            value={Number.parseFloat(value)}
          />
        </div>

        <FieldError id={errorMessageId} show={showServerError}>
          {serverError}
        </FieldError>
      </div>
    </Field>
  );
};
