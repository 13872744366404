import { Fragment } from 'react';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import { components } from '@mosey/api-types';
import { USStateName } from '@mosey/utils/constants/us-states';
import { RegionFilter } from './RegionFilter';
import { AddLocationLink } from './AddLocationLink';
import { useTasksOverviewLocationId } from '../../utils/hooks';

interface RegionFilterOverflowProps {
  regions: components['schemas']['Region'][];
}

export const RegionFilterOverflow = ({
  regions,
}: RegionFilterOverflowProps) => {
  const locationId = useTasksOverviewLocationId();
  const selectedLocationName = locationId
    ? USStateName[locationId.toUpperCase() as keyof typeof USStateName]
    : null;
  const buttonText = selectedLocationName || 'Filter by location';
  const moreCount = regions.length;

  if (regions.length === 0) {
    return null;
  }

  return (
    <li data-static-list-item="true">
      <Popover as={Fragment}>
        {({ open }) => {
          const Icon = open ? ChevronUpIcon : ChevronDownIcon;

          return (
            <>
              <PopoverButton className="flex h-full w-max items-center gap-x-2 rounded border border-teal-350 bg-teal-300 px-4 py-2 text-sm font-medium text-zinc-800 hover:border-teal-500 hover:bg-teal-400 hover:text-black focus-visible:outline-2 focus-visible:outline-teal-800">
                <div className="shrink-0">
                  <span
                    className="hidden sm:block"
                    aria-label={`${moreCount} more region filters`}
                  >
                    <span className="inline-block w-[2em] min-w-[2em] text-right">
                      {moreCount}
                    </span>{' '}
                    more
                  </span>
                  <span
                    className="sm:hidden"
                    aria-label={
                      selectedLocationName
                        ? `Filtered to ${selectedLocationName}`
                        : buttonText
                    }
                  >
                    {buttonText}
                  </span>
                </div>

                <Icon className="size-4 shrink-0" />
              </PopoverButton>

              <PopoverPanel
                anchor={{ to: 'bottom end', gap: 8, padding: 8 }}
                as="ul"
                transition
                className="z-[1000] grid w-fit grid-cols-1 gap-2 rounded border border-gray-200 bg-white p-4 shadow-lg transition duration-100 ease-out data-[closed]:scale-95 data-[closed]:opacity-0 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5"
              >
                {regions.map((region) => (
                  <li key={region.id}>
                    <RegionFilter region={region} />
                  </li>
                ))}

                <AddLocationLink />
              </PopoverPanel>
            </>
          );
        }}
      </Popover>
    </li>
  );
};
