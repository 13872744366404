import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { components, LegalEntityRegionStatus } from '@mosey/api-types';
import { useLegalEntityRegions } from '../../../../hooks/useUser';
import { RegionFilter } from './RegionFilter';
import { AllRegionsFilter } from './AllRegionsFilter';
import { SelectedRegionFilter } from './SelectedRegionFilter';
import { AddLocationLink } from './AddLocationLink';
import { RegionFilterOverflow } from './RegionFilterOverflow';
import { areRegionArraysEqual, getFittingRegions } from './utils';
import { useTasksOverviewLocationId } from '../../utils/hooks';

type LegalEntityRegionRef = components['schemas']['LegalEntityRegionRef'];

export const TaskOverviewRegionFilters = () => {
  const regions = useLegalEntityRegions().filter(
    (region) => region.status !== LegalEntityRegionStatus.exiting,
  );
  const locationId = useTasksOverviewLocationId();
  const measuringContainerRef = useRef<HTMLUListElement>(null);
  const containerRef = useRef<HTMLUListElement>(null);
  const [fittingRegions, setFittingRegions] = useState<LegalEntityRegionRef[]>(
    [],
  );
  const resizeTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const overflowRegions = useMemo(() => {
    return regions
      .filter((region) => {
        const isFittingRegion = Boolean(
          fittingRegions.find(
            (fittingRegion) => fittingRegion.id === region.id,
          ),
        );

        return !isFittingRegion && region.code !== locationId?.toUpperCase();
      })
      .map(({ region }) => region);
  }, [locationId, regions, fittingRegions]);

  const updateFittingRegions = (debounce = false) => {
    if (measuringContainerRef.current) {
      const newFittingRegions = getFittingRegions(
        regions,
        measuringContainerRef.current,
      );

      if (!areRegionArraysEqual(fittingRegions, newFittingRegions)) {
        if (resizeTimeoutRef.current) {
          clearTimeout(resizeTimeoutRef.current);
        }

        if (debounce) {
          resizeTimeoutRef.current = setTimeout(
            setFittingRegions,
            250,
            newFittingRegions,
          );
        } else {
          setFittingRegions(newFittingRegions);
        }
      }
    }
  };

  useLayoutEffect(() => {
    if (measuringContainerRef.current) {
      const resizeObserver = new ResizeObserver(() =>
        updateFittingRegions(true),
      );

      if (measuringContainerRef.current) {
        resizeObserver.observe(measuringContainerRef.current);
      }

      updateFittingRegions();

      return () => {
        resizeObserver.disconnect();
      };
    }
  });

  return (
    <div className="relative isolate box-border w-full max-w-full px-[30px] py-1">
      {/* This list is for calculating how many filters can fit before overflow */}

      <ul
        ref={measuringContainerRef}
        aria-hidden="true"
        className="invisible flex h-0 w-full max-w-full grow-0 gap-2 overflow-hidden px-[2px]"
      >
        <AllRegionsFilter />
        <SelectedRegionFilter />

        {overflowRegions.length > 0 && (
          <RegionFilterOverflow regions={overflowRegions} />
        )}

        <AddLocationLink />

        {regions.map(({ region }) => (
          <li key={region.id} data-region-code={region.id}>
            <RegionFilter region={region} />
          </li>
        ))}
      </ul>

      {/* This is the real list that gets rendered based on the caluclations */}
      <ul
        ref={containerRef}
        className="flex w-full max-w-full grow-0 gap-2 overflow-hidden px-[2px] py-5"
      >
        <AllRegionsFilter />

        {locationId &&
          !fittingRegions.find(
            ({ code }) => code === locationId.toUpperCase(),
          ) && <SelectedRegionFilter />}

        {fittingRegions.map(({ region }) => {
          return (
            <li key={region.id}>
              <RegionFilter region={region} />
            </li>
          );
        })}

        {overflowRegions.length > 0 ? (
          <RegionFilterOverflow regions={overflowRegions} />
        ) : (
          <AddLocationLink />
        )}
      </ul>
    </div>
  );
};
