import { json, redirect } from 'react-router-dom';
import { RefreshTokenError } from '../auth';

type CallParams = {
  url: string;
  method: string;
  credentials?: RequestCredentials;
  body?: unknown;
  contentType?: string;
};

type GetParams = Omit<CallParams, 'method' | 'body'>;
type DeleteParams = GetParams;
type PostParams = Omit<CallParams, 'method'>;
type PutParams = PostParams;
type PatchParams = PostParams;

type ApiConfig = {
  baseUrl: string;
  getToken(): string | null;
  refreshToken(): Promise<unknown>;
};

export class Api {
  config: ApiConfig;
  constructor(config: ApiConfig) {
    this.config = config;
  }

  call = async ({
    url,
    method,
    body = {},
    contentType = 'application/json',
    credentials = 'omit',
  }: CallParams) => {
    const token = this.config.getToken();
    const headers: HeadersInit = {
      Authorization: `Bearer ${token}`,
    };

    if (contentType) {
      headers['Content-Type'] = contentType;
    }

    const request: RequestInit = {
      method,
      body: method !== 'GET' ? JSON.stringify(body) : null,
      mode: 'cors',
      credentials,
      headers: headers,
    };

    const resolvedUrl = this.resolveUrl(url);
    const response = await fetch(resolvedUrl, request);

    if (response.ok) {
      return response;
    }

    if (response.status === 401) {
      // Try refreshing the access token and retrying the request
      try {
        await this.config.refreshToken();
      } catch (error) {
        if (
          Object.values(RefreshTokenError).includes(error as RefreshTokenError)
        ) {
          const redirectUrl = encodeURIComponent(
            `${location.pathname}${location.search}${location.hash}`,
          );

          throw redirect(
            `/logout${redirectUrl !== '%2F' ? `?redirect=${redirectUrl}` : ''}`,
          );
        }

        throw error;
      }

      // Update the request to include the new access token
      const newToken = this.config.getToken();
      headers.Authorization = `Bearer ${newToken}`;
      request.headers = headers;

      // Retry the original request
      const retryResponse = await fetch(
        `${this.config.baseUrl}${url}`,
        request,
      );

      if (retryResponse.ok) {
        return retryResponse;
      }

      // If there are any other errors, give up
      throw retryResponse;
    }

    throw response;
  };

  get = (params: GetParams) => {
    return this.call({ ...params, method: 'GET' });
  };

  post = (params: PostParams) => {
    return this.call({ ...params, method: 'POST' });
  };

  put = (params: PutParams) => {
    return this.call({ ...params, method: 'PUT' });
  };

  patch = (params: PatchParams) => {
    return this.call({ ...params, method: 'PATCH' });
  };

  delete = (params: DeleteParams) => {
    return this.call({ ...params, method: 'DELETE' });
  };

  batch = async <
    T extends Record<
      string,
      CallParams & { onError?: (response: Response) => unknown }
    >,
  >(
    requests: T,
  ) => {
    const keys: Array<keyof T> = Object.keys(requests);
    const responses = await Promise.allSettled(
      Object.values(requests).map((request) => this.call(request)),
    );
    const data: Partial<Record<keyof T, unknown>> = {};

    for (let i = 0; i < responses.length; i++) {
      const key = keys[i];
      const { onError } = requests[key];
      const response = responses[i];

      if (response.status === 'rejected') {
        if (onError) {
          data[key] = onError(response.reason);
          continue;
        }

        throw response.reason;
      }

      if (response.status === 'fulfilled') {
        if (response.value.status === 302) {
          return response.value;
        }
        data[key] = await response.value.json();
      }
    }

    return json(data);
  };

  private resolveUrl = (url: string) => {
    try {
      return new URL(url).toString();
    } catch (_error) {
      return `${this.config.baseUrl}${url}`;
    }
  };
}

export const createSearchParamsFromObject = <
  T extends Parameters<typeof Object.entries>[0],
>(
  queryParameters: T,
) => {
  const searchParams = new URLSearchParams();

  Object.entries(queryParameters).forEach(([key, value]) => {
    if (value === null) {
      searchParams.append(key, 'null');
    } else if (Array.isArray(value)) {
      for (let j = 0; j < value.length; j++) {
        searchParams.append(key, value[j]);
      }
    } else if (value !== undefined) {
      searchParams.append(key, value.toString());
    }
  });

  return searchParams;
};
