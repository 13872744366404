import { clsx } from 'clsx';
import { MenuItemCount } from './MenuItemCount';

export interface MenuBaseItemProps
  extends Omit<React.ComponentPropsWithoutRef<'div'>, 'className'> {
  icon?: React.ReactNode;
  selected?: boolean;
  count?: number;
  children: React.ReactNode;
}

/**
 * To use this component in different places, add the class "group/menu-base-item" to
 * the component in order for this component to recognize hover, focus, etc.
 */
export const MenuBaseItem = ({
  icon,
  selected,
  count,
  children,
  ...rest
}: MenuBaseItemProps) => {
  return (
    <div
      {...rest}
      className={clsx(
        'flex w-full items-center gap-x-3 px-3 py-2 text-sm',
        !selected && 'text-zinc-800 hover:bg-teal-300 hover:text-black',
        selected && 'bg-teal-700 text-teal-50',
      )}
    >
      {icon && (
        <div className={clsx('size-5 shrink-0', selected && 'text-white')}>
          {icon}
        </div>
      )}

      <div className="truncate font-medium">{children}</div>

      <MenuItemCount count={count} />
    </div>
  );
};
