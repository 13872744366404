import { clsx } from 'clsx';
import { ComponentPropsWithoutRef, useEffect } from 'react';
import {
  CheckCircleIcon,
  ExclamationIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
} from '@heroicons/react/outline';
import { useScrollIntoView } from '../hooks';

interface BlockAlertProps
  extends Omit<ComponentPropsWithoutRef<'div'>, 'className'> {
  message: React.ReactNode;
  children?: React.ReactNode;
  actions?: React.ReactNode;
  variant: 'error' | 'warning' | 'info' | 'success';
  show?: boolean;
  scrollIntoView?: boolean;
}

export const BlockAlert = ({
  message,
  children,
  actions,
  variant,
  show = false,
  scrollIntoView = false,
  ...containerProps
}: BlockAlertProps) => {
  let Icon;
  const [scrollIntoViewRef, triggerScroll] = useScrollIntoView(scrollIntoView, {
    block: 'center',
  });

  useEffect(() => {
    if (show && scrollIntoView) {
      triggerScroll();
    }
  }, [show, scrollIntoView, triggerScroll]);

  switch (variant) {
    case 'error':
      Icon = ExclamationIcon;
      break;

    case 'warning':
      Icon = ExclamationCircleIcon;
      break;

    case 'info':
      Icon = InformationCircleIcon;
      break;

    case 'success':
      Icon = CheckCircleIcon;
      break;
  }

  return (
    <div
      className="contents"
      aria-live={containerProps['aria-live'] || 'polite'}
    >
      {show && (
        <div
          {...containerProps}
          ref={scrollIntoViewRef}
          className={clsx(
            'flex w-full gap-x-2 rounded-sm border p-4 shadow-sm',
            variant === 'error' && 'border-rose-200 bg-rose-50 text-rose-900',
            variant === 'warning' &&
              'border-amber-200 bg-amber-50 text-amber-900',
            variant === 'info' && 'border-teal-400 bg-teal-50 text-teal-900',
            variant === 'success' && 'border-lime-200 bg-lime-50 text-lime-900',
          )}
        >
          <Icon className="mt-0.5 size-5 shrink-0" />

          <div>
            <p className="font-semibold">{message}</p>
            {children}
          </div>

          <div className="ml-auto flex items-center gap-x-2 pl-2">
            {actions}
          </div>
        </div>
      )}
    </div>
  );
};
