import { PageHeader } from '@mosey/components/page-header/PageHeader';
import FakeNav from './components/FakeNav';
import { PageTitle } from '@mosey/components/page-header/PageTitle';
import { Outlet } from 'react-router-dom';
import { PageNavLink } from '@mosey/components/page-header/PageNavLink';

export const TaxAccountWrapper = () => {
  return (
    <FakeNav>
      <PageHeader>
        <PageTitle>
          Elsewhere Cafe, Inc. — California Withholding Account
        </PageTitle>
        <div className="ml-auto flex items-center gap-x-8">
          <PageNavLink to="details">Account</PageNavLink>
          <PageNavLink to="notices" relative="route">
            Notices
          </PageNavLink>
          <PageNavLink to="logins">Logins</PageNavLink>
        </div>
      </PageHeader>
      <Outlet />
    </FakeNav>
  );
};
