import { LoaderFunction, Navigate, Outlet } from 'react-router-dom';
import { api } from '../../../utils/fetchApi';
import { useUser } from '../../../hooks/useUser';

export const loader: LoaderFunction = async ({ params: { wizardSlug } }) => {
  return api({
    url: `/api/wizard/${wizardSlug}/config`,
    method: 'GET',
  });
};

interface Props {
  children?: React.ReactNode;
}

export const Component = ({ children }: Props) => {
  const user = useUser();

  if (user.role !== 'admin') {
    return <Navigate to="/home" replace />;
  }

  if (children) {
    return children;
  }

  return <Outlet />;
};
