import { TruncatedList } from '@mosey/components/layout/TruncatedList';
import { TaskSummaryCard } from '@mosey/components/layout/Card';
import { CardWrapper } from '@mosey/components/layout/CardWrapper';
import {
  useTasksOverviewData,
  useTasksOverviewLocationId,
} from '../../utils/hooks';
import { generateResolverTasksBrowserUrl } from '../../utils';
import { useLegalEntityRegions } from '../../../../hooks/useUser';
import { ResolverType } from '../../utils/types';

export const SetupCollection = () => {
  const locationId = useTasksOverviewLocationId();
  const regions = useLegalEntityRegions();
  const { summary } = useTasksOverviewData();

  if (!locationId) {
    const setupAlerts = regions.filter(
      (region) => summary[region.code]?.is_setup,
    );

    return (
      <TruncatedList
        title="Complete Setup"
        body="Setup tasks are based on your responses to questions."
        itemLabel="locations"
        as="nav"
        aria-live="polite"
      >
        {setupAlerts.map((region) => (
          <CardWrapper key={region.code}>
            <TaskSummaryCard
              title={region.name}
              status="setup-collection"
              variant="collection"
              size="medium"
              to={generateResolverTasksBrowserUrl({
                locationId: region.code,
                resolverType: ResolverType.Setup,
              })}
              aria-label={`${region.name} setup`}
            />
          </CardWrapper>
        ))}
      </TruncatedList>
    );
  }

  return null;
};
