import { clsx } from 'clsx';
import {
  Popover as HeadlessUIPopover,
  PopoverButton,
  PopoverPanel,
} from '@headlessui/react';
import * as styles from './styles';

interface PopoverTypes {
  buttonText: string;
  ariaButtonText?: string;
  ButtonLeftIcon?: React.ElementType;
  ButtonRightIcon?: React.ElementType;
  containerClassName?: string;
}

type Props = PopoverTypes & React.ComponentProps<typeof HeadlessUIPopover>;

export const Popover = ({
  buttonText,
  ariaButtonText,
  ButtonLeftIcon,
  ButtonRightIcon,
  children,
  containerClassName,
}: Props) => {
  return (
    <HeadlessUIPopover
      className={`flow-root text-sm lg:relative ${containerClassName}`}
    >
      {(renderProps) => (
        <>
          <PopoverButton className={styles.button} aria-label={ariaButtonText}>
            {ButtonLeftIcon && (
              <ButtonLeftIcon className={styles.icon} aria-hidden="true" />
            )}
            <span className="flex-none">{buttonText}</span>
            {ButtonRightIcon && (
              <ButtonRightIcon
                className={clsx(styles.icon, 'ml-auto')}
                aria-hidden="true"
              />
            )}
          </PopoverButton>

          <PopoverPanel
            transition
            className={clsx(
              styles.container,
              'p-6 transition duration-100 ease-out data-[closed]:scale-95 data-[closed]:opacity-0',
            )}
          >
            {typeof children === 'function' ? children(renderProps) : children}
          </PopoverPanel>
        </>
      )}
    </HeadlessUIPopover>
  );
};
