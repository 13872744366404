import { RefreshTokenError, getAccessToken } from '@mosey/utils/auth';
import { Api } from '@mosey/utils/data/api';
import {
  getPlatformAccessToken,
  refreshPlatformToken,
  refreshToken,
} from './auth';
import * as config from '../settings/config';
import { ApiStatus, IApiData, IFetchApi } from './types';

const apiInstance = new Api({
  baseUrl: config.API_BASE_URL,
  getToken: getAccessToken,
  refreshToken: refreshToken,
});
const platformApiInstance = new Api({
  baseUrl: config.API_BASE_URL,
  getToken: getPlatformAccessToken,
  refreshToken: refreshPlatformToken,
});

const buildApiDataResponse = async (resp: Response): Promise<IApiData> => {
  // If there are no headers, assume the content is json. This can
  // happen if, for example a test is mocking a response and didn't
  // specify headers
  const contentType = resp.headers?.get('content-type') || ['application/json'];

  if (contentType && contentType.includes('application/json')) {
    return resp
      .json()
      .then((data) => ({
        status: ApiStatus.Success,
        statusCode: resp.status,
        error: null,
        data,
      }))
      .catch((_) => ({
        status: ApiStatus.Success,
        statusCode: resp.status,
        error: null,
        data: null,
      }));
  }
  if (
    contentType &&
    (contentType.includes('application/pdf') || contentType.includes('image'))
  ) {
    const data = await resp.blob();
    return {
      status: ApiStatus.Success,
      statusCode: resp.status,
      error: null,
      data,
    };
  }

  return {
    status: ApiStatus.Error,
    statusCode: resp.status,
    error: { details: 'Unhandled content type' },
    data: resp.json(),
  };
};

const buildApiDataErrorResponse = async (resp: Response): Promise<IApiData> => {
  const errorDetails = await resp.json();
  return {
    status: ApiStatus.Error,
    statusCode: resp.status,
    error: errorDetails,
    data: null,
  };
};

export const fetchApi = async ({
  url,
  method,
  credentials = 'omit',
  body = {},
  isPlatform = false,
  contentType = 'application/json',
}: IFetchApi): Promise<IApiData> => {
  const token = isPlatform ? getPlatformAccessToken() : getAccessToken();
  const headers: HeadersInit = {
    Authorization: `Bearer ${token}`,
  };

  if (contentType) {
    headers['Content-Type'] = contentType;
  }

  const request: RequestInit = {
    method,
    body: method !== 'GET' ? JSON.stringify(body) : null,
    mode: 'cors',
    credentials,
    headers: headers,
  };

  let resolvedUrl;

  try {
    resolvedUrl = new URL(url).toString();
  } catch (_error) {
    resolvedUrl = `${config.API_BASE_URL}${url}`;
  }

  const resp = await fetch(resolvedUrl, request);

  if (resp.ok) {
    const out = await buildApiDataResponse(resp);
    return out;
  }
  if (resp.status === 401) {
    // Try refreshing the access token and retrying the request
    try {
      if (isPlatform) {
        await refreshPlatformToken();
      } else {
        await refreshToken();
      }
    } catch (err) {
      if (Object.values(RefreshTokenError).includes(err as RefreshTokenError)) {
        return {
          status: ApiStatus.ErrorUnauthorized,
          statusCode: resp.status,
          error: err,
          data: null,
        };
      }
      return {
        status: ApiStatus.Error,
        statusCode: resp?.status,
        error: err,
        data: null,
      };
    }

    // Update the request to include the new access token
    const newToken = isPlatform ? getPlatformAccessToken() : getAccessToken();
    headers.Authorization = `Bearer ${newToken}`;
    request.headers = headers;

    // Retry the original request
    const retryResp = await fetch(`${config.API_BASE_URL}${url}`, request);
    if (retryResp.ok) {
      const out = await buildApiDataResponse(retryResp);
      return out;
    }

    // If there are any other errors, give up
    const out = await buildApiDataErrorResponse(retryResp);
    return out;
  }

  const out = await buildApiDataErrorResponse(resp);
  return out;
};

export const api = async ({
  url,
  method,
  credentials = 'omit',
  body = {},
  isPlatform = false,
  contentType = 'application/json',
}: IFetchApi) => {
  if (isPlatform) {
    return platformApiInstance.call({
      url,
      method,
      credentials,
      body,
      contentType,
    });
  } else {
    return apiInstance.call({
      url,
      method,
      credentials,
      body,
      contentType,
    });
  }
};

export const apiBatch = async (
  requests: Record<
    string,
    Omit<IFetchApi, 'isPlatform'> & {
      onError?: (response: Response) => unknown;
    }
  >,
) => apiInstance.batch(requests);
