import { clsx } from 'clsx';
import { NavLink, NavLinkProps, useLocation } from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/outline';

export type BaseCardProps = {
  variant: 'card' | 'nav' | 'collection' | 'wizard';
  size: 'medium' | 'large';
  icon?: React.ReactNode;
  title: React.ReactNode;
  description?: React.ReactNode;
  footer?: React.ReactNode;
  isExactMatch?: boolean;
  truncate?: boolean;
  showChevron?: boolean;
} & Omit<NavLinkProps, 'className'>;

// TODO: Break this up into composable pieces
export const BaseCard = ({
  variant,
  size,
  icon,
  title,
  description,
  footer,
  isExactMatch,
  truncate = true,
  showChevron = false,
  ...rest
}: BaseCardProps) => {
  const location = useLocation();
  const fullPath = location.pathname + location.hash;
  const isMediumCollection = variant === 'collection' && size === 'medium';

  return (
    <NavLink
      {...rest}
      className={({ isActive }) =>
        clsx(
          'block border outline-none focus-visible:border-rose-700 focus-visible:ring-1 focus-visible:ring-rose-700',
          variant !== 'wizard' &&
            'border-gray-200 hover:border-teal-350 hover:bg-teal-200',
          isMediumCollection && icon && 'py-1.5 pl-1.5 pr-3',
          isMediumCollection && 'px-3 py-1.5',
          !isMediumCollection && 'p-3',

          variant === 'nav' &&
            'relative z-0 -mt-px hover:z-10 hover:border-b focus-visible:z-20 group-first/task-summary-card:rounded-t-lg group-last/task-summary-card:rounded-b-lg',
          variant === 'card' && 'rounded-lg shadow-sm hover:shadow',
          variant === 'collection' && 'rounded-full shadow-sm hover:shadow',

          variant === 'wizard' &&
            'rounded-lg border-sage-400 bg-sage-200 hover:border-sage-500 hover:bg-teal-350',

          isActive &&
            (!isExactMatch || fullPath === rest.to) &&
            variant === 'nav' &&
            'bg-teal-100 text-black',
        )
      }
      rel={rest.target === '_blank' ? 'noreferrer' : rest.rel}
    >
      <div className="flex w-full gap-3">
        {icon && (
          <div
            aria-hidden="true"
            className={clsx(
              'flex size-8 shrink-0 items-center justify-center rounded-full border',
              //   TODO: Other variants
              variant === 'wizard' && 'border-teal-350 bg-white',
            )}
          >
            <div className="size-[18px]">{icon}</div>
          </div>
        )}

        <div className="flex w-full flex-1 flex-col items-start justify-center gap-0.5 self-center overflow-hidden text-zinc-800">
          <p
            className={clsx(
              'w-full font-semibold',
              size === 'medium' && 'text-sm',
            )}
          >
            {title}
          </p>

          {description && (
            <p
              className={clsx(
                'w-full text-sm',
                truncate && 'truncate',
                variant !== 'wizard' && 'text-zinc-600',
                variant === 'wizard' && 'text-sage-800',
              )}
            >
              {description}
            </p>
          )}

          {footer && (
            <div className="flex w-full max-w-full gap-x-1 text-zinc-600">
              {footer}
            </div>
          )}
        </div>

        {showChevron && (
          <ChevronRightIcon
            className={clsx(
              'size-4 shrink-0 self-center',
              variant !== 'wizard' && 'text-zinc-600',
              variant === 'wizard' && 'text-sage-800',
            )}
          />
        )}
      </div>
    </NavLink>
  );
};
