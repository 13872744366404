import { QueryFilter } from '@mosey/components/menus/QueryFilter';
import { TasksFilterCategory } from '@mosey/api-types';
import { useSubmit } from 'react-router-dom';
import { FilterOption } from '@mosey/components/menus/Filter';
import { useTasksOverviewUrl } from '../utils/hooks';

export const TasksOverviewCategoryFilter = () => {
  const submit = useSubmit();
  const tasksOverviewUrl = useTasksOverviewUrl();
  return (
    <QueryFilter
      queryKey="category"
      handleChange={(option: FilterOption) => {
        submit(
          { category: option.value, intent: 'update-tasks-filter-preference' },
          {
            method: 'POST',
            action: tasksOverviewUrl,
            navigate: false,
          },
        );
      }}
      options={[
        {
          label: 'All categories',
          value: TasksFilterCategory.all,
        },
        {
          label: 'Payroll',
          value: TasksFilterCategory.payroll,
        },
        {
          label: 'HR',
          value: TasksFilterCategory.hr,
        },
        {
          label: 'Entity',
          value: TasksFilterCategory.registration,
        },
        {
          label: 'Tax',
          value: TasksFilterCategory.tax,
        },
      ]}
    />
  );
};
