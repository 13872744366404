import { useEffect, useState } from 'react';
import { useMatch } from 'react-router-dom';
import { clsx } from 'clsx';
import {
  BookOpenIcon,
  LibraryIcon,
  SearchIcon,
} from '@heroicons/react/outline';
import { NavItem } from '@mosey/components/navigation/NavItem';
import { SubNavItem } from '@mosey/components/navigation/SubNavItem';
import {
  CollapseLeftIcon,
  ExpandRightIcon,
  SupportIcon,
  CalendarIcon,
  TaskListIcon,
  SettingsIcon,
  LocationIcon,
  RadarIcon,
  EmailIcon,
} from '@mosey/components/Icons';
import { useUser } from '../../../../hooks/useUser';
import { ImpersonationAlert } from '../../../../components/base/ImpersonationAlert';
import { useTasks } from '../../../tasks-framework/utils/hooks';
import { UserMenu } from '../../../../components/base/UserMenu';

type AppNavProps = {
  children?: React.ReactNode;
};

const FakeNav = ({ children }: AppNavProps) => {
  const user = useUser();
  const isHome = useMatch('/home');
  const isLocationResolver = Boolean(
    useMatch('locations/:countryId/:locationId/resolver/:resolverType?/*'),
  );
  const isGenericResolver = Boolean(useMatch('resolver/:resolverType?/*'));
  const tasks = useTasks();
  const [isNavClosed, setIsNavClosed] = useState(!isHome);
  const {
    legal_entity: { business_profile_submitted: isBusinessProfileSubmitted },
  } = user;

  useEffect(() => {
    setIsNavClosed(
      !isHome ||
        ((isLocationResolver || isGenericResolver) && tasks.length > 1),
    );
  }, [isHome, isLocationResolver, isGenericResolver, tasks.length]);

  const isNavDisabled = !isBusinessProfileSubmitted;
  const navItemDisabledMessage =
    'Finish setting up your business profile to unlock.';

  const OpenCloseIcon = isNavClosed ? ExpandRightIcon : CollapseLeftIcon;

  return (
    <div className="flex min-h-screen">
      <aside
        className={clsx(
          'relative z-[1000] hidden h-screen shrink-0 border-r bg-white transition-nav duration-300 ease-in-out sm:block',
          isNavClosed && 'w-[68px]',
          !isNavClosed && 'w-[212px]',
        )}
      >
        <nav className="flex h-full grow flex-col pt-[20px]">
          <UserMenu isNavClosed={isNavClosed} />

          <hr />

          <button
            className="absolute right-[-11px] top-[69px] z-50 flex size-[21px] items-center justify-center rounded-full border-2 border-gray-200 bg-white outline-none focus-visible:border-teal-600"
            onClick={() => {
              setIsNavClosed(!isNavClosed);
            }}
            aria-label={`${isNavClosed ? 'Open' : 'Close'} navigation menu`}
          >
            <OpenCloseIcon
              className={clsx(
                'size-full',
                isNavClosed ? 'text-rose-500' : 'text-rose-700',
              )}
            />
          </button>

          <ul className="flex h-full flex-col gap-y-2 overflow-y-auto overflow-x-hidden px-2 pb-6 pt-[26px]">
            <NavItem
              name="Tax Accounts"
              to="/tax-accounts"
              Icon={LibraryIcon}
              isClosed={isNavClosed}
              isDisabled={isNavDisabled}
              disabledTooltipMessage={navItemDisabledMessage}
            />
            <NavItem
              name="Locations"
              to="/locations/usa"
              Icon={LocationIcon}
              isClosed={isNavClosed}
              isDisabled={isNavDisabled}
              disabledTooltipMessage={navItemDisabledMessage}
            />
            <NavItem
              name="Tasks"
              to="/tasks"
              Icon={TaskListIcon}
              isClosed={isNavClosed}
              isDisabled={isNavDisabled}
              disabledTooltipMessage={navItemDisabledMessage}
            >
              <SubNavItem name="Assigned to me" to="/tasks/me" truncate />
              <SubNavItem name="All" to="/tasks/all" truncate />
            </NavItem>
            <NavItem
              name="Calendar"
              to="/calendar"
              Icon={CalendarIcon}
              isClosed={isNavClosed}
              isDisabled={isNavDisabled}
              disabledTooltipMessage={navItemDisabledMessage}
            />
            <NavItem
              name="Legislation"
              to="/legislation"
              Icon={RadarIcon}
              isClosed={isNavClosed}
              isDisabled={isNavDisabled}
              disabledTooltipMessage={navItemDisabledMessage}
            />
            <NavItem
              name="Mailroom"
              to="/mail"
              Icon={EmailIcon}
              isClosed={isNavClosed}
              isDisabled={isNavDisabled}
              disabledTooltipMessage={navItemDisabledMessage}
            />

            <NavItem
              name="Handbook"
              to="/handbook"
              Icon={BookOpenIcon}
              isClosed={isNavClosed}
              isDisabled={isNavDisabled}
              disabledTooltipMessage={navItemDisabledMessage}
            />

            <div className="mt-auto">
              <hr className="mb-4 mt-2" />
            </div>

            <NavItem
              name="Search"
              to="/search"
              Icon={SearchIcon}
              isClosed={isNavClosed}
              isDisabled={isNavDisabled}
              disabledTooltipMessage={navItemDisabledMessage}
            />
            <NavItem
              name="Settings"
              to="/settings"
              Icon={SettingsIcon}
              isClosed={isNavClosed}
              isDisabled={isNavDisabled}
              disabledTooltipMessage={navItemDisabledMessage}
            />
            <NavItem
              name="Support"
              to="https://support.mosey.com"
              Icon={SupportIcon}
              isClosed={isNavClosed}
              target="_blank"
            />
          </ul>
        </nav>
      </aside>

      <div className="flex h-screen w-full flex-col overflow-y-hidden bg-white">
        <ImpersonationAlert />

        <main className="flex-1 overflow-auto">{children}</main>
      </div>
    </div>
  );
};

export default FakeNav;
