interface WizardInterstitialProps {
  title: React.ReactNode;
  body: React.ReactNode;
  graphic?: React.ReactNode;
  actions: React.ReactNode;
}

export const WizardInterstitial = ({
  title,
  body,
  graphic,
  actions,
}: WizardInterstitialProps) => {
  return (
    <div className="flex grow flex-col items-center justify-center overflow-y-auto">
      {graphic && <div className="mb-6 w-full max-w-2xl">{graphic}</div>}

      <h2 className="mb-1 text-center text-4xl font-bold tracking-tight">
        {title}
      </h2>
      <p className="mb-8 text-zinc-800">{body}</p>

      <div className="flex items-center gap-x-4">{actions}</div>
    </div>
  );
};
