import { FunctionComponent, useState, useEffect } from 'react';
import {
  Navigate,
  useSearchParams,
  useNavigate,
  createSearchParams,
} from 'react-router-dom';
import { clsx } from 'clsx';
import { Button } from '@mosey/components/buttons/Button';
import { useScrollIntoView } from '@mosey/components/hooks';
import {
  BatchApiStatusHandler,
  Renderer,
  Section,
  SectionHeading,
  formSpecToRendererConfig,
} from '../components';
import { FormProvider, useForm } from 'react-hook-form';
import { useBatchApi } from '../hooks';
import { useParams } from 'react-router';
import { cleanIntakeDates } from '../utils/intake';
import { successCallbackPath } from '../utils/paths';
import { fetchApi } from '../utils/fetchApi';
import { IApiData, IFetchApi, ApiStatus } from '../utils/types';
import { transformServerFieldErrors } from '../utils/form';
import { FormSpec, Requirement } from '../types';
import { FormError } from '@mosey/components/forms/FormError';
import { RequirementFeeText } from '../components/RequirementFeeText';
import { RequirementEtaText } from '../components/RequirementEtaText';
import { Automation } from '../types/api';
import { useSafeLocationUpdate } from '../hooks/useSafeLocationUpdate';
import { AutomationAutoSubmit } from './AutomationAutoSubmit';

type AutomationExecutionWithRedirectProps = {
  automation: Automation;
  automationPath: string;
  callbackUrl?: string;
  formIndex: number;
  onFormComplete: () => void;
  onAutomationComplete?: () => void;
  onEnterLater?: () => void;
  isFullWidth?: boolean;
  scrollOnMount?: boolean;
  showHeading?: boolean;
};

type AutomationExecutionProps = AutomationExecutionWithRedirectProps & {
  automation: Automation & {
    forms: FormSpec[];
  };
};

const AutomationExecution: FunctionComponent<AutomationExecutionProps> = ({
  automation,
  automationPath,
  callbackUrl,
  formIndex,
  onFormComplete,
  onAutomationComplete,
  onEnterLater,
  isFullWidth,
  scrollOnMount,
  showHeading = true,
}) => {
  const updateLocation = useSafeLocationUpdate();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [scrollIntoViewRef, triggerScroll] = useScrollIntoView(scrollOnMount);
  const forms = automation.forms;

  const formSpec = forms[formIndex];
  const formIsEmpty = formSpec.sections[0].form_fields.length === 0;
  const isLastForm = formIndex === forms.length - 1;

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [hasSubmitError, setHasSubmitError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const formMethods = useForm({ defaultValues: formSpec.default_values });
  const {
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    reset,
  } = formMethods;

  useEffect(() => {
    reset(forms[formIndex].default_values);
  }, [formIndex]);

  useEffect(() => {
    if (formIsEmpty && !isLastForm) {
      onFormComplete();
    }
  }, [formIsEmpty, isLastForm]);

  const onSubmit = (data: unknown) => {
    setIsSubmitting(true);
    clearErrors();
    setHasSubmitError(false);
    setErrorMessage(undefined);
    const cleanedData = cleanIntakeDates(data);
    const payload = {
      form_submission: { form_id: formSpec.id, data: cleanedData },
    };

    fetchApi({
      url: `/api/automation/${automationPath}`,
      method: 'POST',
      body: payload,
    }).then(({ status, error }: IApiData) => {
      if (status == ApiStatus.Success) {
        if (isLastForm && callbackUrl) {
          if (searchParams.get('skip_success_screen') === 'true') {
            updateLocation(callbackUrl, true);
          } else {
            navigate(successCallbackPath(callbackUrl), { replace: true });
          }
        } else if (isLastForm && onAutomationComplete) {
          onAutomationComplete();
        } else {
          onFormComplete();
          triggerScroll();
        }
      } else {
        setHasSubmitError(true);
        if (error && error.detail) {
          if (Array.isArray(error.detail)) {
            // Avoid showing general submit error when we have field-level errors
            setHasSubmitError(false);
            const errorMap = transformServerFieldErrors(error.detail);
            Object.keys(errorMap).forEach((fieldKey) =>
              setError(fieldKey, errorMap[fieldKey], { shouldFocus: true }),
            );
          } else {
            setErrorMessage(error.detail);
          }
        }
      }
      setIsSubmitting(false);
    });
  };

  return (
    <Section
      className="flex flex-col items-center"
      isFullscreen={isFullWidth}
      ref={scrollIntoViewRef}
    >
      {showHeading && (
        <SectionHeading
          className={clsx('pb-4', {
            'w-full': isFullWidth,
            'w-96': !isFullWidth,
          })}
          text={formSpec.title}
        />
      )}
      <FormProvider {...formMethods}>
        <form
          className={clsx(
            showHeading && 'my-8',
            isFullWidth && 'w-full',
            !isFullWidth && 'w-96',
          )}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div>
            <Renderer
              config={formSpecToRendererConfig(formSpec)}
              errors={errors}
            />
            {isLastForm && automation.fee && (
              <div className="my-8 flex flex-col gap-4">
                <RequirementFeeText fee={automation.fee} />
              </div>
            )}
            {isLastForm && automation.eta?.estimation && (
              <div className="my-8 flex flex-col gap-4">
                <RequirementEtaText eta={automation.eta.estimation} />
              </div>
            )}
            {formIsEmpty && isLastForm && (
              <div className="mb-4 flex size-full items-center justify-center py-2">
                It looks like we have everything we need! Click submit to
                continue.
              </div>
            )}
          </div>
          <div className="flex gap-x-4">
            {onEnterLater && (
              <Button
                type="button"
                variant="grayTertiary"
                size="large"
                isFullWidth
                onClick={onEnterLater}
              >
                Enter later
              </Button>
            )}
            <Button
              type="submit"
              isFullWidth
              size="large"
              isLoading={isSubmitting}
            >
              {forms.length - 1 === formIndex ? 'Submit' : 'Continue'}
            </Button>
          </div>
          {hasSubmitError && <FormError errorMessage={errorMessage} />}
        </form>
      </FormProvider>
    </Section>
  );
};

// handles redirects for AutomationExecution so
// that it doesnt have to handle null forms
const AutomationExecutionWithRedirect = (
  props: AutomationExecutionWithRedirectProps,
) => {
  const {
    automation,
    callbackUrl,
    formIndex,
    onAutomationComplete,
    automationPath,
  } = props;
  const navigate = useNavigate();
  const destination = automation.automation_url;
  useEffect(() => {
    if (destination) {
      const searchParams = callbackUrl
        ? createSearchParams({ callback_url: callbackUrl }).toString()
        : '';
      navigate({
        pathname: destination,
        search: searchParams,
      });
    }
  }, [destination]);

  if (automation.allow_auto_submit) {
    const theForm =
      automation.forms && automation.forms.length > 0
        ? automation.forms![0]
        : undefined;
    return (
      <AutomationAutoSubmit
        form={theForm}
        automationPath={automationPath}
        callbackUrl={callbackUrl}
        onAutomationComplete={onAutomationComplete}
      />
    );
  }

  if (
    !props.automation.forms ||
    props.automation.forms.length === 0 ||
    formIndex >= props.automation.forms.length
  ) {
    if (destination) {
      return <Navigate to={destination} />;
    } else if (callbackUrl) {
      return <Navigate to={successCallbackPath(callbackUrl)} replace />;
    } else {
      return <Navigate to="/" />;
    }
  }

  return (
    <AutomationExecution
      {...props}
      automation={automation as AutomationExecutionProps['automation']}
    />
  );
};

type AutomationViewProps = {
  requirement?: Requirement;
  onAutomationComplete?: () => void;
  onFormLoad?: () => void;
  onEnterLater?: () => void;
  isFullWidth?: boolean;
  scrollOnMount?: boolean;
  showHeading?: boolean;
};

export const AutomationView: FunctionComponent<AutomationViewProps> = ({
  requirement,
  onAutomationComplete,
  onEnterLater,
  isFullWidth,
  scrollOnMount,
  showHeading = true,
  onFormLoad,
}) => {
  const [formIndex, setFormIndex] = useState<number>(0);
  const [searchParams] = useSearchParams();
  const callbackUrl = searchParams.get('callback_url') || undefined;

  useEffect(() => {
    if (onFormLoad) {
      onFormLoad();
    }
  }, [formIndex, onFormLoad]);

  // get requirementId from props or search params
  const params = useParams();
  const automationPath = requirement?.id || params['*'];
  if (!automationPath) {
    throw new Error('Nothing to automate');
  }

  const apiCalls: IFetchApi[] = [
    {
      url: `/api/automation/${automationPath}`,
      method: 'GET',
    },
  ];

  const batchResponse = useBatchApi(apiCalls, [formIndex]);

  const componentPropsFn = ([
    automationResponse,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ]: any[]): AutomationExecutionWithRedirectProps => {
    return {
      automationPath: automationPath,
      automation: automationResponse,
      callbackUrl: callbackUrl,
      onFormComplete: () => {
        setFormIndex(formIndex + 1);
      },
      formIndex,
      onAutomationComplete,
      onEnterLater,
      isFullWidth,
      scrollOnMount,
      showHeading,
    };
  };

  return (
    <BatchApiStatusHandler
      batchResponse={batchResponse}
      component={AutomationExecutionWithRedirect}
      componentProps={componentPropsFn}
    />
  );
};
