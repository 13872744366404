import { BookOpenIcon, ClipboardCheckIcon } from '@heroicons/react/outline';
import { LightningBoltListIcon, QuestionListIcon } from '../Icons';
import { BaseCard, BaseCardProps } from './BaseCard';

export type WizardCardProps = {
  variant?: 'questions' | 'setup' | 'handbook';
} & Omit<BaseCardProps, 'icon' | 'variant' | 'size' | 'showChevron'>;

export const WizardCard = ({ variant, ...rest }: WizardCardProps) => {
  let Icon;

  switch (variant) {
    case 'questions':
      Icon = QuestionListIcon;
      break;

    case 'setup':
      Icon = LightningBoltListIcon;
      break;

    case 'handbook':
      Icon = BookOpenIcon;
      break;

    default:
      Icon = ClipboardCheckIcon;
  }

  return (
    <BaseCard
      {...rest}
      variant="wizard"
      size="large"
      icon={<Icon className="text-teal-800" />}
      showChevron
    />
  );
};
