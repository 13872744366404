import { clsx } from 'clsx';
import { FunctionComponent, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { InformationCircleIcon } from '@heroicons/react/outline';
import { Button } from '@mosey/components/buttons/Button';
import { useScrollIntoView } from '@mosey/components/hooks/index';
import { ResolverType } from '../views/tasks-framework/utils/types';
import { generateResolverTasksBrowserUrl } from '../views/tasks-framework/utils';

type QuestionTasksBannerProps = {
  regionCode: string;
  taskId?: string;
  show: boolean;
  isNested?: boolean;
  isSetup?: boolean;
};

export const QuestionTasksBanner: FunctionComponent<
  QuestionTasksBannerProps
> = ({ regionCode, taskId, show, isNested = false, isSetup = false }) => {
  const [scrollIntoViewRef, triggerScroll] = useScrollIntoView(false);
  const location = useLocation();

  useEffect(() => {
    /**
     * If the banner is not nested, that means it's above the navigator and we
     * only want to scroll in that case. Nested uses are easily discoverable.
     */
    if (show && !isNested) {
      /**
       * JM component are also using useScrollIntoView so we need to ensure this
       * happens after those.
       */
      setTimeout(triggerScroll, 0);
    }
  }, [show, isNested, triggerScroll, location]);

  return (
    <div
      ref={scrollIntoViewRef}
      className={clsx(!isNested && 'px-6 pt-6', !show && 'hidden')}
      style={{ scrollMarginTop: '5rem' }}
      aria-live="polite"
    >
      <div
        className={clsx(
          'flex w-full rounded-sm border border-solid border-teal-400 bg-teal-50 px-4 py-3 text-teal-900',
          {
            'flex-col': isNested,
            'items-center': !isNested,
          },
        )}
      >
        <div
          className={clsx('flex items-center gap-x-2', {
            'mb-2': isNested,
          })}
        >
          <InformationCircleIcon className="size-6 self-center stroke-1" />
          {isNested && (
            <div className="text-base font-medium">
              Additonal information required
            </div>
          )}
        </div>
        <div
          className={clsx('text-base font-normal', {
            'mb-6': isNested,
            'ml-2 mr-2': !isNested,
          })}
        >
          Please answer a few questions to get the most up-to-date compliance
          tasks.
        </div>
        <div
          className={clsx('w-28', {
            '': isNested,
            'ml-auto mr-12': !isNested,
          })}
        >
          <Button
            type="button"
            variant="secondary"
            as={Link}
            state={{ resolverFinishUrl: location.pathname }}
            to={generateResolverTasksBrowserUrl({
              locationId: regionCode,
              resolverType: isSetup
                ? ResolverType.Assessment
                : ResolverType.Question,
              taskId,
            })}
          >
            Answer Questions
          </Button>
        </div>
      </div>
    </div>
  );
};
