import {
  ActionFunctionArgs,
  Navigate,
  redirect,
  useFetcher,
  useNavigation,
  useParams,
  useRouteLoaderData,
  useSubmit,
} from 'react-router-dom';
import { PolicySectionLoader } from '../../types';
import { FormProvider, useForm } from 'react-hook-form';
import { SubmitTarget } from 'react-router-dom/dist/dom';
import { Renderer, formSpecToRendererConfig } from '../../../../components';
import { Button } from '@mosey/components/buttons/Button';
import { TextLink } from '@mosey/components/navigation/TextLink';
import { useEffect, useMemo } from 'react';
import { api } from '../../../../utils/fetchApi';

export const PolicySectionConfigureForm = () => {
  const { policySlug, sectionId } = useParams();
  const forms = useRouteLoaderData('policy-section') as PolicySectionLoader;

  const { nextForm, selectedForm } = useMemo(() => {
    for (let formIndex = 0; formIndex < forms.length; formIndex++) {
      if (forms[formIndex].policy.slug === policySlug) {
        return {
          selectedForm: forms[formIndex],
          nextForm: forms[formIndex + 1],
        };
      }
    }
    return {
      selectedForm: forms[0],
      nextForm: forms[1],
    };
  }, [policySlug, forms]);
  const section = selectedForm?.policy.policy_section;

  const submit = useSubmit();
  const { state } = useNavigation();
  const { Form } = useFetcher();
  const formMethods = useForm({
    defaultValues: selectedForm?.form.default_values,
  });
  const {
    handleSubmit,
    formState: { errors },
    reset,
  } = formMethods;

  useEffect(() => {
    reset(selectedForm?.form.default_values || {});
  }, [selectedForm?.form.default_values, reset]);

  const onSubmit = (data: SubmitTarget) => {
    submit(
      {
        sectionId,
        policyId: selectedForm.policy.id,
        nextPolicySlug: nextForm?.policy.slug,
        data,
      } as SubmitTarget,
      {
        method: 'POST',
        encType: 'application/json',
        navigate: true,
      },
    );
  };

  if (!selectedForm || !section) {
    // shouldn't happen
    return <Navigate to={`/handbook/section/${sectionId}/configure`} />;
  }
  return (
    <section className="p-8">
      <h2 className="border-b-1 border-gray-200 pb-2 text-lg">
        Configure {selectedForm?.policy.title}
      </h2>
      <FormProvider {...formMethods}>
        <Form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
          <div className="mt-8 max-w-md">
            <Renderer
              config={formSpecToRendererConfig(selectedForm?.form)}
              errors={errors}
            />
          </div>
          <div className="flex items-center gap-6">
            <Button type="submit" isLoading={state !== 'idle'}>
              Save & Continue
            </Button>
            <TextLink
              to="/handbook/overview"
              variant="secondary"
              skipInk={false}
            >
              Cancel
            </TextLink>
          </div>
        </Form>
      </FormProvider>
    </section>
  );
};

PolicySectionConfigureForm.action = async ({ request }: ActionFunctionArgs) => {
  const { sectionId, policyId, nextPolicySlug, data } = await request.json();
  try {
    await api({
      method: 'POST',
      url: `/api/handbook/policies/${policyId}/form`,
      body: {
        data,
      },
    });
    if (nextPolicySlug) {
      return redirect(
        `/handbook/section/${sectionId}/configure/${nextPolicySlug}`,
      );
    } else {
      return redirect(`/handbook/section/${sectionId}/policies`);
    }
  } catch (_err) {
    return { ok: false };
  }
};
