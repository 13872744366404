import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import FakeNav from './components/FakeNav';
import { PageHeader } from '@mosey/components/page-header/PageHeader';
import { PageTitle } from '@mosey/components/page-header/PageTitle';
import { Table } from '@mosey/components/table/Table';
import { HeaderRow } from '@mosey/components/table/HeaderRow';
import { HeaderCell } from '@mosey/components/table/HeaderCell';
import { TableBody } from '@mosey/components/table/TableBody';
import { TableRow } from '@mosey/components/table/TableRow';
import { TableCell } from '@mosey/components/table/TableCell';
import { hasAccountConnectionStarted } from './utils';
import { Button } from '@mosey/components/buttons/Button';
import clsx from 'clsx';

export const ActiveTaxAccountBadge = ({
  bordered = false,
}: {
  bordered?: boolean;
}) => (
  <div
    className={clsx(
      'flex w-fit items-center gap-2 rounded-full  px-3 py-1.5',
      bordered ? 'border bg-white' : 'bg-[#f1f4f4]',
    )}
  >
    <div className="size-1.5 rounded-full bg-[#20bcc2]" />
    <div className="text-xs font-bold uppercase text-gray-700">active</div>
  </div>
);

const Inactive = () => (
  <div className="flex w-fit items-center gap-2 rounded-full bg-gray-50 px-3 py-1.5">
    <div className="size-1.5 rounded-full bg-gray-300" />
    <div className="text-xs font-bold uppercase text-gray-500">inactive</div>
  </div>
);

const Pending = () => (
  <div className="flex w-fit items-center gap-2 rounded-full bg-[#f1f4f4] px-3 py-1.5">
    <div className="size-1.5 rounded-full bg-amber-500" />
    <div className="text-xs font-bold uppercase text-amber-600">pending</div>
  </div>
);

type Account = {
  id: string;
  name: string;
  customer: string;
  status: 'active' | 'inactive' | 'pending';
  initiatedAt: string;
  completedAt?: string;
};

const Row = ({ account }: { account: Account }) => {
  const navigate = useNavigate();
  return (
    <TableRow
      onClick={
        account.status === 'active'
          ? () => navigate(`/tax-accounts/${account.id}/details`)
          : undefined
      }
    >
      <TableCell>{account.customer}</TableCell>
      <TableCell>{account.name}</TableCell>
      <TableCell>
        {account.status === 'active' && <ActiveTaxAccountBadge />}
        {account.status === 'inactive' && <Inactive />}
        {account.status === 'pending' && <Pending />}
      </TableCell>
      <TableCell>{account.initiatedAt}</TableCell>
      <TableCell>{account.completedAt || '--'}</TableCell>
    </TableRow>
  );
};

export const TaxAccounts = () => {
  const data = useLoaderData() as Array<Account>;
  return (
    <FakeNav>
      <PageHeader>
        <PageTitle>Accounts</PageTitle>
        <Button as={Link} to="/tax-accounts/create">
          Add account
        </Button>
      </PageHeader>
      <div className="p-8">
        <Table>
          <HeaderRow>
            <HeaderCell>Customer</HeaderCell>
            <HeaderCell>Account</HeaderCell>
            <HeaderCell>Status</HeaderCell>
            <HeaderCell>Initiated at</HeaderCell>
            <HeaderCell>Completed at</HeaderCell>
          </HeaderRow>
          <TableBody>
            {data.map((account, index) => (
              <Row key={index} account={account} />
            ))}
          </TableBody>
        </Table>
      </div>
    </FakeNav>
  );
};

TaxAccounts.loader = () => {
  const accountConnections = [
    {
      id: 'd33a57eef8d6e285',
      name: 'California Secretary of State Registration Account',
      customer: 'Acme Inc',
      status: 'inactive',
      initiatedAt: 'Jan 26, 2025',
      completedAt: 'Jan 27, 2025',
    },
    {
      id: 'e2740c16b9344610',
      name: 'San Francisco Business Account',
      customer: 'Coffee Today, LLC',
      status: 'inactive',
      initiatedAt: 'Jan 3, 2025',
      completedAt: 'Jan 5, 2025',
    },
    {
      id: '54a5950bec9dec59',
      name: 'San Francisco Business Account',
      customer: 'Cup of Joe LLC',
      status: 'inactive',
      initiatedAt: 'Jan 1, 2025',
      completedAt: 'Jan 2, 2025',
    },
    {
      id: '6fbd2adb175ecdda',
      name: 'California Income Tax Withholding Account',
      customer: 'Elsewhere Cafe, Inc.',
      status: 'active',
      initiatedAt: 'Jan 1, 2025',
      completedAt: 'Jan 2, 2025',
    },
    {
      id: '6fbd2adb175ecdda',
      name: 'California Sales & Use Tax Account',
      customer: 'Elsewhere Cafe, Inc.',
      status: 'inactive',
      initiatedAt: 'Dec 30, 2024',
      completedAt: 'Jan 2, 2025',
    },
    {
      id: 'ba3f855e9e362f2b',
      name: 'California Sales & Use Tax Account',
      customer: 'Cup of Joe LLC',
      status: 'inactive',
      initiatedAt: 'Dec 20, 2025',
      completedAt: 'Dec 21, 2025',
    },
  ] as Array<Account>;

  if (hasAccountConnectionStarted()) {
    accountConnections.unshift({
      id: 'd402f9bbd5459d63',
      name: 'California Income Tax Witholding Account',
      customer: 'Acme Inc',
      status: 'pending',
      initiatedAt: new Date()
        .toDateString()
        .substring(4)
        .replace(' 2025', ', 2025'),
    });
    accountConnections.unshift({
      id: 'd402f9bbd5459d64',
      name: 'California Unemployment Insurance Account',
      customer: 'Acme Inc',
      status: 'pending',
      initiatedAt: new Date()
        .toDateString()
        .substring(4)
        .replace(' 2025', ', 2025'),
    });
    accountConnections.unshift({
      id: 'd402f9bbd5459d65',
      name: 'California Paid Family Medical Leave Account',
      customer: 'Acme Inc',
      status: 'pending',
      initiatedAt: new Date()
        .toDateString()
        .substring(4)
        .replace(' 2025', ', 2025'),
    });
  }
  return accountConnections;
};
