export enum ResolverType {
  // Setup
  Review = 'review',
  Assessment = 'assessment', // AKA Configure
  Setup = 'setup',

  // Recurring tasks
  Question = 'question',
  Todo = 'todo',
  Overdue = 'overdue',
  Done = 'done',
  Automated = 'automated',
  Managed = 'managed',
  InProgress = 'in_progress',
  Handbook = 'handbook',
}

export type ResolverRouteParams = {
  resolverType?: ResolverType;
  countryId?: 'usa';
  // TODO: Type this as USStateAbbrev
  locationId?: string;
};

export type LocationResolverRouteParams = ResolverRouteParams & {
  countryId: 'usa';
  // TODO: Type this as USStateAbbrev
  locationId: string;
};

export type TaskRouteParams = ResolverRouteParams & {
  taskId: string;
};
