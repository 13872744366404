import { CardWrapper } from '@mosey/components/layout/CardWrapper';
import { TaskSummaryCard } from '@mosey/components/layout/Card';
import { TruncatedList } from '@mosey/components/layout/TruncatedList';
import {
  useTasksOverviewData,
  useTasksOverviewLocationId,
} from '../../utils/hooks';
import zeroState from '../../../../assets/tasks-framework/tasks-overview-sidebar-automated-zero.svg';
import { ResolverType } from '../../utils/types';
import { generateResolverTasksBrowserUrl } from '../../utils';

export const InProgressCollection = () => {
  const { inProgress } = useTasksOverviewData();
  const locationId = useTasksOverviewLocationId();

  if (inProgress.length === 0) {
    return (
      <div className="rounded-lg border-1 border-teal-350 bg-teal-100 px-4 pt-6">
        <p className="font-semibold text-zinc-800">
          No automations in progress
        </p>
        <p className="text-sm text-zinc-600">
          Tasks you automate will show up here.
        </p>

        <img
          src={zeroState}
          alt=""
          className="mt-4 w-full object-cover object-bottom"
        />
      </div>
    );
  }

  return (
    <TruncatedList
      title="Automations in Progress"
      body="You will be notified if action is required."
      itemLabel="automations"
      isStacked
      as="nav"
      aria-live="polite"
    >
      {inProgress.map(({ id, title, status }) => {
        return (
          <CardWrapper key={id} as="li">
            <TaskSummaryCard
              title={title}
              status={status === 'deferred' ? 'todo' : status}
              variant="nav"
              size="medium"
              to={generateResolverTasksBrowserUrl({
                locationId,
                resolverType: ResolverType.InProgress,
                taskId: id,
              })}
            />
          </CardWrapper>
        );
      })}
    </TruncatedList>
  );
};
