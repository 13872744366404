import { FunctionComponent, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { CheckCircleIcon, ErrorIcon } from '@mosey/components/Icons';
import { Button } from '@mosey/components/buttons/Button';
import {
  BackButton,
  BatchApiStatusHandler,
  SectionHeading,
} from '../../components';
import { useBatchApi } from '../../hooks';
import { Business, BusinessIFormValues } from '../../types';
import { FatalError } from '../FatalError';
import { FormBusiness } from '../../components/forms/FormBusiness';
import { cleanIntakeDates } from '../../utils/intake';
import { fetchApi } from '../../utils/fetchApi';
import { ApiStatus } from '../../utils/types';

type BusinessEditProps = {
  business: Business;
};

export const BusinessEdit: FunctionComponent<BusinessEditProps> = ({
  business,
}) => {
  const formMethods = useForm<BusinessIFormValues>({
    mode: 'onBlur',
    defaultValues: {
      person_type: 'is_business',
      name: business.name,
      incorporation_country: business.incorporation_country,
      incorporation_region: business.incorporation_region?.id,
      incorporation_date: business.incorporation_date,
      physical_address:
        business.physical_address as BusinessIFormValues['physical_address'],
      mailing_address:
        business.mailing_address as BusinessIFormValues['mailing_address'],
      is_physical_address_same_as_mailing_address:
        business.physical_address?.id === business.mailing_address?.id,
      personal_address:
        business.physical_address as BusinessIFormValues['personal_address'],
      ownership_start_date: business.ownership_start_date,
      ownership_percentage: business.ownership_percentage,
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [hasSubmitError, setHasSubmitError] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const {
    handleSubmit,
    clearErrors,
    reset,
    formState: { isDirty, isValid },
  } = formMethods;

  const onCancel = () => {
    reset();
  };

  const onSubmit = async (formData: BusinessIFormValues) => {
    if (!isValid) return;

    setHasSubmitError(false);
    clearErrors();
    setIsLoading(true);

    const cleanedData = cleanIntakeDates(formData);

    try {
      const { status } = await fetchApi({
        url: `/api/legal_entity/business/${business.id}`,
        method: 'PUT',
        body: cleanedData,
      });

      if (
        status === ApiStatus.Error ||
        status === ApiStatus.ErrorUnauthorized
      ) {
        setHasSubmitError(true);
      } else {
        reset(cleanedData);
        setSubmitSuccess(true);
        setTimeout(() => setSubmitSuccess(false), 1500);
      }
    } catch (_error) {
      setHasSubmitError(true);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (!isDirty) return;

    // Reset validation messages if needed when the user touchs form inputs
    if (hasSubmitError) {
      setHasSubmitError(false);
    } else if (submitSuccess) {
      setSubmitSuccess(false);
    }
  }, [isDirty]);

  return (
    <div className="flex flex-col">
      <div className="p-16">
        <div className="mb-6">
          <BackButton />
        </div>
        <SectionHeading className="flex-1 pb-4" text="Edit Business Profile" />

        <div className="flex flex-col py-9 xl:max-w-xl">
          <section>
            <FormProvider {...formMethods}>
              <form>
                <FormBusiness isEdit />
              </form>
            </FormProvider>
          </section>
        </div>
      </div>
      {(isDirty || submitSuccess) && (
        <div className="b-0 l-0 sticky bottom-0 flex h-[72px] items-center justify-end border-t border-gray-200 bg-sage-50 px-6 py-4">
          {submitSuccess && (
            <div className="ml-3 flex items-center text-lg text-lime-500">
              <span>
                <CheckCircleIcon className="mr-1 size-5" />
              </span>
              <div>Business information has been updated!</div>
            </div>
          )}

          {hasSubmitError && (
            <div className="ml-3 flex items-center text-lg text-red-600">
              <span>
                <ErrorIcon className="mr-1 size-5" />
              </span>
              <div>Something went wrong, please try again.</div>
            </div>
          )}

          {!submitSuccess && (
            <div className="flex gap-x-2">
              <Button
                type="button"
                variant="secondary"
                onClick={onCancel}
                disabled={isLoading}
              >
                Cancel
              </Button>

              <div className="w-28">
                <Button
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
                  isFullWidth
                  isLoading={isLoading}
                  disabled={isLoading}
                >
                  Save Changes
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export const BusinessEditView: FunctionComponent = () => {
  const { businessId } = useParams<Record<string, string>>();

  if (!businessId) {
    return <FatalError />;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const batchResponse = useBatchApi([
    {
      url: `/api/legal_entity/business/${businessId}`,
      method: 'GET',
    },
  ]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const componentPropsFn = ([businessResponse]: any[]): BusinessEditProps => {
    return {
      business: businessResponse,
    };
  };

  return (
    <BatchApiStatusHandler
      batchResponse={batchResponse}
      component={BusinessEdit}
      componentProps={componentPropsFn}
    />
  );
};
