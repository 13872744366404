import { Outlet } from 'react-router-dom';
import { PageHeader } from '@mosey/components/page-header/PageHeader';
import { PageTitle } from '@mosey/components/page-header/PageTitle';
import { useWizardConfigMaybe } from './tasks-framework/utils/hooks';
import { WizardHeader } from './tasks-framework/wizard/WizardHeader';

export const Home = () => {
  const wizardConfig = useWizardConfigMaybe();

  return (
    <div className="flex h-full flex-col overflow-hidden">
      <PageHeader>
        {wizardConfig ? <WizardHeader /> : <PageTitle>Home</PageTitle>}
      </PageHeader>

      <Outlet />
    </div>
  );
};
