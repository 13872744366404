import { Link, Navigate, useParams } from 'react-router-dom';
import TaskResolverInterstitialGraphicAssessment from '@mosey/components/graphics/task-resolver-interstitial-graphic-assessment.svg';
import TaskResolverInterstitialGraphicSetup from '@mosey/components/graphics/task-resolver-interstitial-graphic-setup.svg';
import { Button } from '@mosey/components/buttons/Button';
import { useWizardInterstitial } from '../utils/hooks';
import { WizardInterstitial } from './WizardInterstitial';

export const WizardStepIndex = () => {
  const { wizardSlug, wizardStepSlug } = useParams();
  const interstitial = useWizardInterstitial();

  if (interstitial) {
    let graphic = null;

    // TODO: Move this to Wizard Config once we have Admin capabilities
    if (wizardSlug === 'new-coverage' || wizardSlug === 'new-coverage-local') {
      if (wizardStepSlug === 'question') {
        graphic = (
          <img src={TaskResolverInterstitialGraphicAssessment} alt="" />
        );
      } else if (wizardStepSlug === 'requirement') {
        graphic = <img src={TaskResolverInterstitialGraphicSetup} alt="" />;
      }
    }

    return (
      <WizardInterstitial
        title={interstitial.title}
        body={interstitial.body}
        graphic={graphic}
        actions={
          <Button as={Link} size="xlarge" to="tasks">
            Continue
          </Button>
        }
      />
    );
  }

  return <Navigate to="tasks" replace />;
};
