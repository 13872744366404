import { paths } from '@mosey/api-types';
import { useMatches, useParams, useRouteLoaderData } from 'react-router-dom';

export const useMailroomData = () => {
  const matches = useMatches();
  const routeId = matches.find(({ id }) => {
    return id.startsWith('mail/');
  })?.id;

  if (!routeId) {
    throw new Error(
      'The useMailroomData hook must be used within a Mail route.',
    );
  }

  return useRouteLoaderData(routeId) as {
    messages: paths['/api/legal_entity/mail']['get']['responses']['200']['content']['application/json'];
    users: paths['/api/users']['get']['responses']['200']['content']['application/json'];
    requirements?: paths['/api/requirements']['post']['responses']['200']['content']['application/json'];
    legalEntityRegion?: paths['/api/legal_entity/locations/{region_code}']['get']['responses']['200']['content']['application/json'];
  };
};

export const useCurrentMail = () => {
  const { mailId } = useParams();
  const { messages } = useMailroomData();

  return messages.find((m) => m.id === mailId);
};
