import { RequirementContext, RequirementOverview } from '../types';

export const requirementPath = (
  reqDataId: string,
  reqDueDate?: string,
  requirementCtx?: RequirementContext,
): string => {
  const query = new URLSearchParams();

  if (reqDueDate) {
    query.set('dueDate', reqDueDate);
  }

  if (requirementCtx?.employee_id) {
    query.set('employeeId', requirementCtx.employee_id);
  }

  return `/requirement/${reqDataId}?${query.toString()}`;
};

export const taskPath = (legalEntityRequirement: string): string => {
  return `/tasks/${legalEntityRequirement}`;
};

export const genRequirementPath = (
  requirement: RequirementOverview,
): string => {
  if (requirement.id) {
    return taskPath(requirement.id);
  }

  return requirementPath(
    requirement.data_id,
    requirement.due_date,
    requirement.requirement_context,
  );
};

export const successCallbackPath = (callbackUrl: string, replace = true) => {
  const params = new URLSearchParams();
  params.append('callback_url', callbackUrl);
  params.append('replace', JSON.stringify(replace));
  return `/success-callback?${params.toString()}`;
};
