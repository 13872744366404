import { components } from '@mosey/api-types';
import { SectionHeading } from '../components';
import { json, useLoaderData } from 'react-router-dom';
import { api } from '../utils/fetchApi';
import { AddIntegrationLink } from '../components/AddIntegrationLink';
import { Integrations } from '../components/settings/Integrations';

export const IntegrationsView = () => {
  const connections = useLoaderData() as Array<
    components['schemas']['Connection']
  >;
  return (
    <div className="m-16 flex max-w-3xl flex-col">
      <SectionHeading
        className="flex flex-1 items-center pb-4"
        text="Integrations"
      />

      {connections.length > 0 ? (
        <Integrations connections={connections} />
      ) : (
        <div className="mt-10">
          <AddIntegrationLink />
        </div>
      )}
    </div>
  );
};

IntegrationsView.loader = () => {
  return api({
    url: '/api/connections',
    method: 'GET',
  });
};

IntegrationsView.action = async () => {
  try {
    return await api({
      url: `/api/connections`,
      method: 'POST',
      body: { integration_slug: 'csv-import', provider: 'rippling' },
    });
  } catch (_err) {
    // failures dont impact whether payroll sync will work
    return json({});
  }
};
