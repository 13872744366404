import { Link, Navigate, Outlet, useParams } from 'react-router-dom';
import { PageHeader } from '@mosey/components/page-header/PageHeader';
import { PageTitle } from '@mosey/components/page-header/PageTitle';
import { PageNavLink } from '@mosey/components/page-header/PageNavLink';
import { StateSealCropped } from '@mosey/components/state-seals/StateSealCropped';
import { useLegalEntityRegions } from '../hooks/useUser';
import { useWizardConfigMaybe } from './tasks-framework/utils/hooks';
import { WizardHeader } from './tasks-framework/wizard/WizardHeader';

export const LocationDetail = () => {
  const regions = useLegalEntityRegions();
  const { locationId } = useParams();
  const wizardConfig = useWizardConfigMaybe();

  if (!locationId) {
    throw new Error('<LocationDetail /> requires a locationId');
  }

  const region = regions.find(
    (region) => region.code === locationId.toUpperCase(),
  );

  if (!region) {
    return <Navigate to="/locations/usa" replace />;
  }

  return (
    <div className="flex h-full flex-col overflow-hidden">
      <PageHeader>
        {wizardConfig ? (
          <WizardHeader />
        ) : (
          <>
            <Link
              className="-ml-1 flex items-center gap-x-4 p-1 focus-visible:outline-rose-700"
              to={`/locations/usa/${locationId}/overview`}
            >
              <div className="size-10">
                <StateSealCropped code={region.code} opacity={1} />
              </div>

              <PageTitle>{region.name}</PageTitle>
            </Link>

            <div className="ml-auto flex items-center gap-x-8">
              {/* TODO: Get todo and mail counts */}
              <PageNavLink to="overview">Overview</PageNavLink>
              <PageNavLink to="mail" relative="route">
                Mail
              </PageNavLink>
              <PageNavLink to="accounts">Accounts</PageNavLink>
            </div>
          </>
        )}
      </PageHeader>

      <Outlet />
    </div>
  );
};
