import { WizardCard } from '@mosey/components/layout/WizardCard';
import { isTaskTodo } from '@mosey/utils/tasks';
import {
  useTasksOverviewData,
  useTasksOverviewLocation,
} from '../../utils/hooks';
import { generateResolverTasksBrowserUrl } from '../../utils';
import { ResolverType } from '../../utils/types';

export const SetupAlert = () => {
  const location = useTasksOverviewLocation();
  const { setupTasks = [] } = useTasksOverviewData();

  if (location && setupTasks.some(isTaskTodo)) {
    return (
      <WizardCard
        variant="setup"
        title="Complete registrations"
        description={`Ensure you are set up with the appropriate agencies in ${location.name}.`}
        to={generateResolverTasksBrowserUrl({
          locationId: location.code,
          resolverType: ResolverType.Setup,
        })}
        truncate={false}
      />
    );
  }

  return null;
};
