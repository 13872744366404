import { useState } from 'react';
import { Link } from 'react-router-dom';
import { XIcon } from '@heroicons/react/outline';
import { Button } from '@mosey/components/buttons/Button';
import { useTasksOverviewData } from '../../utils/hooks';
import {
  useIsHandbookEnabled,
  useIsHandbookPresent,
} from '../../../handbook/hooks/useIsHandbook';
import handbookIcon from '../../../../assets/handbook.svg';

export const HandbookCTA = () => {
  const { handbookTodos } = useTasksOverviewData();
  const isHandbookEnabled = useIsHandbookEnabled();
  const isHandbookPresent = useIsHandbookPresent();
  const [showHandbookCta, setShowHandbookCta] = useState<boolean>(
    handbookTodos.length > 0 &&
      (!isHandbookEnabled || !isHandbookPresent) &&
      localStorage.getItem('isHandbookCtaDismissed') !== 'true',
  );

  return (
    <div className="contents" aria-live="polite">
      {showHandbookCta && (
        <div
          id="handbook-cta"
          className="relative flex gap-x-4 rounded-md border border-sage-500 bg-sage-100 p-4"
        >
          <button
            className="absolute right-3 top-3 border-none p-0 outline-offset-2 focus-visible:outline-2 focus-visible:outline-rose-700"
            aria-controls="handbook-cta"
            onClick={() => {
              localStorage.setItem('isHandbookCtaDismissed', 'true');
              setShowHandbookCta(false);
            }}
          >
            <XIcon className="size-4 text-sage-700" aria-label="Dismiss" />
          </button>

          <img src={handbookIcon} alt="" className="w-32" />

          <div>
            <p className="text-sm font-semibold text-zinc-700">
              Launch your multi-state employee handbook in minutes.
            </p>
            <p className="mb-2 mt-1 text-xs text-zinc-600">
              Everything you need to make a compliant handbook for all 50
              states.
            </p>

            <Button as={Link} to="/handbook" size="small" variant="secondary">
              Get started
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
