import clsx from 'clsx';
import { Label } from '@headlessui/react';

export type TaskLabelProps = {
  variant?: 'medium' | 'large';
  label: React.ReactNode;
  description?: React.ReactNode;
  isVisuallyHidden?: boolean;
  children?: React.ReactNode;
};

export const TaskLabel = ({
  variant = 'large',
  label,
  isVisuallyHidden = false,
}: TaskLabelProps) => {
  return (
    <div
      className={clsx(
        'flex shrink flex-col',
        variant === 'large' && 'mb-6 gap-y-2',
        variant === 'medium' && 'gap-y-1',
        isVisuallyHidden && 'sr-only',
      )}
    >
      <Label className="block max-w-3xl cursor-pointer">{label}</Label>
    </div>
  );
};
