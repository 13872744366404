import { fetchApi } from '../../../utils/fetchApi';

const STORAGE_KEY = 'mosey-universal-tax-accounts';

export function markAccountConnectionStarted() {
  localStorage.setItem(STORAGE_KEY, 'true');
}

export function hasAccountConnectionStarted() {
  return localStorage.getItem(STORAGE_KEY) === 'true';
}

export async function fakeMailLoader() {
  const usersResponse = await fetchApi({
    url: '/api/users',
    method: 'GET',
  });
  return {
    users: usersResponse.data,
    messages: [
      {
        id: 'a34e60b791ae6e1e',
        region_code: 'ca',
        source: 'mailroom',
        source_id: '29236527',
        type: 'external-mail',
        description: '',
        sender: 'Employment Development Department',
        received_at: '2024-11-04',
        url: 'https://api.mosey.com/api/legal_entity/mail/a34e60b791ae6e1e',
        is_read: true,
        assigned_user: {
          id: 'e17f2f69441490d1',
          first_name: 'Alex',
          last_name: 'Kehayias',
          email: 'alex@mosey.com',
        },
        status: 'resolved',
        summary: {
          id: 6414,
          title:
            'Notice of contribution rates and statement of UI reserve account for the period of January 1, 2025, to December 31, 2025',
          sender: 'Employment Development Department',
          recipient: 'Mosey Works, Inc.',
          sent_date: '2024-12-31',
          classification: 'tax_rate_change',
          explanation:
            'The letter notifies Mosey Works, Inc. of their Unemployment Insurance (UI) and Employment Training Tax (ETT) contribution rates for 2025. It specifies the UI contribution rate of 2.40% and the ETT rate of 0.10%, with a taxable wage limit per employee of $7,000.',
        },
      },
    ],
  };
}
