import { useEffect } from 'react';
import { captureException } from '@sentry/react';
import {
  isRouteErrorResponse,
  useNavigate,
  useRouteError,
} from 'react-router-dom';
import { NotFound } from './NotFound';
import { FatalError } from './FatalError';

export const ErrorBoundary = () => {
  const navigate = useNavigate();
  const error = useRouteError();

  useEffect(() => {
    if (error instanceof Response) {
      if (error.status === 302) {
        return navigate(error.headers.get('Location') || '/');
      }
    }

    captureException(error);
  }, [error, navigate]);

  if (isRouteErrorResponse(error) && error.status === 404) {
    return <NotFound />;
  }

  return (
    <div className="h-screen overflow-hidden">
      <FatalError />
    </div>
  );
};
