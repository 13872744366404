import { Link, useMatch } from 'react-router-dom';
import { Button } from '@mosey/components/buttons/Button';
import { Stepper } from '@mosey/components/navigation/Stepper/Stepper';
import { Step } from '@mosey/components/navigation/Stepper/Step';
import { PageTitle } from '@mosey/components/page-header/PageTitle';
import {
  useFinishUrl,
  useWizardConfig,
  useWizardCurrentStep,
  useWizardRouteStepIndex,
  useWizardUrl,
} from '../utils/hooks';

export const WizardHeader = () => {
  const { title, steps } = useWizardConfig();
  const currentStep = useWizardCurrentStep();
  const routeStepIndex = useWizardRouteStepIndex();
  const wizardUrl = useWizardUrl();
  const isConclusion = useMatch(`${wizardUrl}/complete`);
  const finishUrl = useFinishUrl();

  return (
    <div className="flex w-full items-center gap-x-6">
      {/* TODO: Make <PageTitle /> more flexible with flex */}
      <div>
        <PageTitle>{title}</PageTitle>
      </div>

      <div className="mx-auto w-full">
        <Stepper
          minValue={0}
          maxValue={1}
          progress={(routeStepIndex + 1) / (steps.length + 2)}
          label={`${title} wizard progress`}
        >
          <Step isMarked to={`${wizardUrl}/welcome`}>
            Welcome
          </Step>

          {steps.map((step, index) => {
            return (
              <Step
                key={step.slug}
                isMarked={index <= routeStepIndex || isConclusion !== null}
                to={`${wizardUrl}/step/${step.slug}`}
              >
                {step.title}
              </Step>
            );
          })}

          <Step to={`${wizardUrl}/complete`}>Complete</Step>
        </Stepper>
      </div>

      {currentStep && (
        <div className="ml-auto">
          <Button as={Link} to={finishUrl} size="large" variant="secondary">
            Finish later
          </Button>
        </div>
      )}
    </div>
  );
};
