import { clsx } from 'clsx';

export interface BannerWithGraphicProps {
  variant?: 'default' | 'automation';
  title: React.ReactNode;
  body?: React.ReactNode;
  graphic: React.ReactNode;
}

export const BannerWithGraphic = ({
  variant = 'default',
  title,
  body,
  graphic,
}: BannerWithGraphicProps) => {
  return (
    <div className="@container">
      <div
        className={clsx(
          'flex rounded-lg text-zinc-800 @2xl:border',
          variant === 'default' && 'border-teal-350 @2xl:bg-teal-100',
          variant === 'automation' && 'border-violet-100',
        )}
      >
        <div className="flex grow flex-col justify-center gap-y-2 @2xl:max-w-[50%] @2xl:p-10">
          <p className="text-2xl font-bold">{title}</p>

          {body && <div>{body}</div>}
        </div>

        <div className="ml-auto hidden max-w-[50%] shrink justify-end @2xl:flex">
          {graphic}
        </div>
      </div>
    </div>
  );
};
