import { Link, Navigate } from 'react-router-dom';
import { Button } from '@mosey/components/buttons/Button';
import noTasksGraphic from '../../../assets/tasks-framework/tasks-overview-sidebar-automated-zero.svg';
import { WizardInterstitial } from './WizardInterstitial';
import {
  useNextTask,
  useTasks,
  useWizardCurrentStep,
  useWizardCurrentStepIndex,
  useWizardNextStepUrl,
  useWizardRouteStepIndex,
  useWizardUrl,
} from '../utils/hooks';

export const WizardResolverIndex = () => {
  const tasks = useTasks();
  const currentStep = useWizardCurrentStep();
  const currentStepIndex = useWizardCurrentStepIndex();
  const routeStepIndex = useWizardRouteStepIndex();
  const nextStepUrl = useWizardNextStepUrl();
  const nextTask = useNextTask();
  const wizardUrl = useWizardUrl();

  if (tasks.length === 0) {
    return (
      <WizardInterstitial
        title="No action required"
        body={
          currentStep && routeStepIndex > currentStepIndex
            ? 'This step is empty, for now. Complete prior steps and check back.'
            : 'This step is empty based on your configuration.'
        }
        graphic={<img src={noTasksGraphic} alt="" />}
        actions={
          <>
            <Button
              as={Link}
              size="xlarge"
              variant={currentStep ? 'secondary' : 'primary'}
              to={nextStepUrl}
            >
              Continue
            </Button>

            {currentStep && (
              <Button
                as={Link}
                size="xlarge"
                to={`${wizardUrl}/step/${currentStep.slug}`}
              >
                Back to step {currentStepIndex + 1}
              </Button>
            )}
          </>
        }
      />
    );
  }

  return <Navigate to={nextTask ? nextTask.id : tasks[0].id} replace />;
};
