import { Animation } from '../animation/Animation';

export const LoadingTasks = () => {
  return (
    <Animation
      loadAnimation={() => import('./assets/loading-tasks.json')}
      className="mt-[-10%] aspect-[750/480] w-full"
    />
  );
};
