import {
  MenuItem as HeadlessUIMenuItem,
  MenuItemProps as HeadlessUIMenuItemProps,
} from '@headlessui/react';
import { MenuBaseItem, MenuBaseItemProps } from './common/MenuBaseItem';

type MenuItemProps<T extends React.ElementType> = Omit<
  HeadlessUIMenuItemProps<T>,
  'className'
> &
  Omit<MenuBaseItemProps, 'children'>;

export const MenuItem = <T extends React.ElementType>({
  children,
  icon,
  selected,
  count,
  ...rest
}: MenuItemProps<T>) => {
  return (
    <HeadlessUIMenuItem {...rest} className="group/menu-base-item block w-full">
      {(bag) => {
        return (
          <MenuBaseItem selected={selected} icon={icon} count={count}>
            {typeof children === 'function' ? children(bag) : children}
          </MenuBaseItem>
        );
      }}
    </HeadlessUIMenuItem>
  );
};
