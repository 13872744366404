import { clsx } from 'clsx';
import { NavLink } from 'react-router-dom';
import { CheckIcon } from '@heroicons/react/outline';
import { MailType } from '@mosey/api-types';
import { formatDateFromString } from '@mosey/utils/dates';
import { useCurrentMail, useMailroomData } from './utils/hooks';
import { nameFromUser } from '../../utils/format';

const MailTypeToName: Record<MailType, string> = {
  [MailType.registered_agent]: 'Registered Agent',
  [MailType.external_mail]: 'Mailroom',
};

export const MailroomSidebar = () => {
  const { messages } = useMailroomData();
  const currentMail = useCurrentMail();

  return (
    <aside className="w-80 min-w-80 overflow-auto">
      <nav>
        <ul>
          {messages.map((m) => {
            const isSelectedMail = m.source_id === currentMail?.source_id;
            const assignedUser = m.assigned_user
              ? nameFromUser(m.assigned_user)
              : null;

            return (
              <li key={m.source_id}>
                <NavLink
                  className={clsx(
                    'block border-b p-4',
                    isSelectedMail && 'bg-teal-200',
                    !isSelectedMail && 'hover:bg-teal-200',
                  )}
                  to={`../${m.id}`}
                >
                  <div className="mb-1 flex items-start space-x-2">
                    {m.status === 'resolved' ? (
                      <span className="mt-1 size-4 rounded-full border-transparent bg-lime-500">
                        <CheckIcon className="size-4 text-white" />
                      </span>
                    ) : (
                      <span className="mt-1 size-4 rounded-full border bg-gray-100" />
                    )}

                    <div
                      className={clsx(
                        'line-clamp-3 flex-1 text-sm font-bold',
                        isSelectedMail && 'text-teal-800',
                        !isSelectedMail && 'text-teal-700',
                      )}
                    >
                      {m.sender
                        ? m.sender.replaceAll('\n', '')
                        : MailTypeToName[m.type]}
                    </div>

                    <time
                      dateTime={m.received_at}
                      className="text-xs text-gray-400"
                    >
                      {formatDateFromString(m.received_at)}
                    </time>
                  </div>

                  <div className="ml-6 space-y-1">
                    {m.description && (
                      <p className="text-sm">{m.description}</p>
                    )}

                    {m.region_code && (
                      <div className="text-xs text-gray-400">
                        <p>Source: {m.region_code.toUpperCase()}</p>
                        {assignedUser && <p>Assigned to {assignedUser}</p>}
                      </div>
                    )}
                  </div>
                </NavLink>
              </li>
            );
          })}
        </ul>
      </nav>
    </aside>
  );
};
