import { FunctionComponent, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { convertDateToUTC } from '@mosey/utils/dates';
import { isObject } from '@mosey/utils/types';
import {
  FieldDependency,
  FieldRule,
  RuleCondition,
  RuleConditionAnd,
  RuleConditionEqual,
  RuleConditionLowerBounds,
  RuleConditionNot,
  RuleConditionOr,
  RuleConditionType,
  RuleConditionUpperBounds,
} from '../../types';

type FieldWrapperProps = {
  name: string;
  fieldDependencies?: FieldDependency[];
  fieldRules?: FieldRule[];
  children?: React.ReactNode;
};

export const fieldRuleConditionToVisibilityFunction = (
  rule: RuleCondition,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): ((v: any) => boolean) => {
  const conditionType = rule.type as RuleConditionType;
  switch (conditionType) {
    case RuleConditionType.Equals: {
      const eqRule = rule as RuleConditionEqual;
      return (v: string | number) => v === eqRule.value;
    }
    case RuleConditionType.Exists:
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return (v: any) => !!v;
    case RuleConditionType.Not: {
      const notRule = rule as RuleConditionNot;
      const nestedConditionFn = fieldRuleConditionToVisibilityFunction(
        notRule.not_condition,
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return (v: any) => !nestedConditionFn(v);
    }
    case RuleConditionType.And: {
      const andRule = rule as RuleConditionAnd;
      const nestedAndFns = andRule.and_conditions.map((c: RuleCondition) =>
        fieldRuleConditionToVisibilityFunction(c),
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return (v: any) =>
        nestedAndFns.map((fn) => fn(v)).every((result) => result === true);
    }
    case RuleConditionType.Or: {
      const orRule = rule as RuleConditionOr;
      const nestedOrFns = orRule.or_conditions.map((c: RuleCondition) =>
        fieldRuleConditionToVisibilityFunction(c),
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return (v: any) =>
        nestedOrFns.map((fn) => fn(v)).some((result) => result === true);
    }
    case RuleConditionType.LowerBounds: {
      const lbRule = rule as RuleConditionLowerBounds;
      return (v: number) => v > lbRule.value;
    }
    case RuleConditionType.UpperBounds: {
      const upRule = rule as RuleConditionUpperBounds;
      return (v: number) => v < upRule.value;
    }
    default:
      return () => false;
  }
};

export const VisibilityWrapper: FunctionComponent<FieldWrapperProps> = ({
  name,
  fieldDependencies,
  fieldRules,
  children,
}) => {
  const { watch, unregister, getValues } = useFormContext();
  const [isVisible, setIsVisible] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const VisibilityMap: Record<string, any> = {
    isNotEmpty: (v: string) => v !== undefined && v !== '',
    isChecked: (v: boolean) => v,
    isUnchecked: (v: boolean) => !v,
    isTodayOrPastDate: (v: Date) => {
      if (!v) {
        return false;
      }
      const today = convertDateToUTC(new Date());
      const utcVal = convertDateToUTC(v);

      const isThisYear = utcVal.getFullYear() === today.getFullYear();
      const isThisMonth = utcVal.getMonth() === today.getMonth();

      return (
        utcVal.getFullYear() < today.getFullYear() ||
        (isThisYear && utcVal.getMonth() < today.getMonth()) ||
        (isThisYear && isThisMonth && utcVal.getDate() <= today.getDate())
      );
    },
    otherIsSelected: (v: string) => v === 'other',
    otherIsNotSelected: (v: string) => v !== 'other',
    isGreaterThanZero: (v: number) => v !== undefined && v > 0,
    hasIdentifier: (v: string) => v === 'ssn' || v === 'itin',
    isExistingPerson: (v: string) => v === 'existing_person_option',
    isNewPerson: (v: string) => v === 'new_person_option',
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let fieldValues: any[] = [];
  if (fieldDependencies) {
    const fieldTargets: string[] = fieldDependencies.map(
      (dependency) => dependency.targetField,
    );
    // ordering is important here - should be determinate
    fieldValues = watch(fieldTargets);
  }
  if (fieldRules) {
    const fieldTargets: string[] = fieldRules.map((rule) => rule.targetField);
    // ordering is important here - should be determinate
    fieldValues = watch(fieldTargets);
  }
  useEffect(() => {
    let shouldBeVisible = true;
    if (fieldDependencies) {
      for (let i = 0; i < fieldValues.length; i += 1) {
        shouldBeVisible =
          shouldBeVisible &&
          VisibilityMap[fieldDependencies[i].visibilityFunction](
            fieldValues[i],
          );
      }
    }
    if (fieldRules && fieldRules.length > 0) {
      for (let i = 0; i < fieldValues.length; i += 1) {
        const visibilityFn = fieldRuleConditionToVisibilityFunction(
          fieldRules[i].condition,
        );
        shouldBeVisible = shouldBeVisible && visibilityFn(fieldValues[i]);
      }
    }
    if (shouldBeVisible !== isVisible) {
      setIsVisible(shouldBeVisible);

      // unregister fields being hidden
      // if name is `mailing_address` all child values ie `mailing_address.line_1`,
      // will also be unregistered
      if (!shouldBeVisible) {
        const allFormValues = getValues();

        const valueToUnregister = name
          .split('.')
          .reduce((value, nameSegment) => {
            return value[nameSegment];
          }, allFormValues);

        let shouldUnregister = false;

        if (valueToUnregister) {
          shouldUnregister = true;

          if (isObject(valueToUnregister)) {
            const fieldValues = Object.values(valueToUnregister);

            if (fieldValues.length === 0) {
              shouldUnregister = false;
            } else if (
              fieldValues.some((value) => value == undefined || value === null)
            ) {
              shouldUnregister = false;
            }
          }
        }

        if (shouldUnregister) {
          unregister(name);
        }
      }
    }
  }, [fieldValues, isVisible]);

  return <>{isVisible && children}</>;
};
