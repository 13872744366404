import { clsx } from 'clsx';
import { NavLink, NavLinkProps } from 'react-router-dom';

export type StepProps = {
  children: React.ReactNode;
  isMarked?: boolean;
} & Omit<NavLinkProps, 'className'>;

export const Step = ({ isMarked = false, children, ...rest }: StepProps) => {
  return (
    <li className="group/stepper-item w-full">
      <NavLink {...rest} className="block">
        {({ isActive }) => {
          const isHighlighted = isActive || isMarked;

          return (
            <div
              className={clsx(
                'relative flex flex-col items-center justify-center gap-y-1 text-sm font-bold',
                isHighlighted && 'text-teal-800',
                !isHighlighted && 'text-zinc-600',
              )}
            >
              <div
                className={clsx(
                  'absolute -left-1/2 right-1/2 top-1 isolate -z-10 h-0.5 group-first/stepper-item:left-1/2',
                  isHighlighted && 'bg-teal-800',
                  !isHighlighted && 'bg-gray-200',
                )}
                aria-hidden="true"
              />

              <div
                className={clsx(
                  'size-[10px] rounded-full',
                  isHighlighted && 'bg-teal-800',
                  !isHighlighted && 'border border-gray-200 bg-white',
                  isActive && 'border border-white outline outline-teal-800',
                )}
                aria-hidden="true"
              />

              <div className="line-clamp-2 text-center">{children}</div>
            </div>
          );
        }}
      </NavLink>
    </li>
  );
};
