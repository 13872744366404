import { isTaskDone } from '@mosey/utils/tasks';
import {
  useTasksOverviewData,
  useTasksOverviewLocationId,
} from '../utils/hooks';
import { TasksOverviewInterstitial } from './TasksOverviewInterstitial';

interface TasksOverviewSetupGateProps {
  children: React.ReactNode;
}

export const TasksOverviewSetupGate = ({
  children,
}: TasksOverviewSetupGateProps) => {
  const locationId = useTasksOverviewLocationId();
  const { assessmentTasks = [] } = useTasksOverviewData();

  if (
    locationId &&
    assessmentTasks.length > 0 &&
    assessmentTasks.filter(isTaskDone).length === 0
  ) {
    return <TasksOverviewInterstitial />;
  }

  return children;
};
