import { clsx } from 'clsx';
import { forwardRef } from 'react';
import { useTasks } from '../utils/hooks';

interface TaskContainerProps {
  children: React.ReactNode;
}

export const TaskContainer = forwardRef<HTMLDivElement, TaskContainerProps>(
  function TaskContainer({ children }, ref) {
    const tasks = useTasks();

    return (
      <article
        ref={ref}
        className={clsx(
          'flex h-full grow flex-col gap-y-4 overflow-y-auto py-8 pr-8',
          tasks.length <= 1 && 'pl-8',
          tasks.length > 1 && 'pl-4',
        )}
      >
        {children}
      </article>
    );
  },
);
