import { Link } from 'react-router-dom';
import { PolicyContentResource } from '@mosey/components/handbook/types';
import { ExtLinkIcon, LinkIcon } from '@mosey/components/Icons';
import { Button } from '@mosey/components/buttons/Button';

type PolicyResourcesProps = {
  resources: Array<PolicyContentResource>;
};

export const PolicyResources = ({ resources }: PolicyResourcesProps) => {
  return (
    <>
      <h3 className="mx-6 font-medium">Resources</h3>
      <ul className="mx-6 mb-4 mt-3 flex flex-col gap-3">
        {resources?.map((resource) => (
          <li
            key={resource.url}
            className="flex items-center gap-4 rounded border border-teal-350 bg-teal-100 p-4"
          >
            <div className="flex size-16 items-center justify-center rounded-full border border-teal-350 bg-teal-300">
              <LinkIcon className="size-6" />
            </div>
            <div className="flex flex-1 flex-col">
              <h4 className="text-lg font-medium">{resource.title}</h4>
              <p className="text-sm text-teal-700">
                {new URL(resource.url).hostname}
              </p>
            </div>
            <Button
              as={Link}
              variant="secondary"
              to={resource.url}
              className="flex items-center"
              leftIcon={<ExtLinkIcon className="size-6" />}
              target="_blank"
              rel="noopener noreferrer"
            >
              Visit <span className="sr-only">{resource.title}</span>
            </Button>
          </li>
        ))}
      </ul>
    </>
  );
};
