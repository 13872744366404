import clsx from 'clsx';
import { Field, Input } from '@headlessui/react';
import { FieldError } from './FieldError';
import { TaskLabel } from './TaskLabel';
import { TaskQuestionInputBaseProps } from './types';

type TaskDateFieldProps = TaskQuestionInputBaseProps;

export const TaskDateField = ({
  variant = 'large',
  error: serverError,
  label,
  disabled,
  defaultValue,
  onChange,
  ...rest
}: TaskDateFieldProps) => {
  const { name } = rest;
  const errorMessageId = `form-field-error-message-${name}`;
  const showServerError = Boolean(serverError);

  return (
    <Field disabled={disabled}>
      <div
        className={clsx(
          variant === 'medium' && 'flex flex-wrap items-center gap-x-6 gap-y-2',
        )}
      >
        <TaskLabel
          variant={variant}
          label={label}
          isVisuallyHidden={variant === 'large'}
        />

        <Input
          {...rest}
          type="date"
          max={'9999-12-31'}
          invalid={showServerError}
          className={clsx(
            'cursor-text rounded border border-stone-200 py-2 text-zinc-800 transition-shadow duration-150 hover:border-gray-300 focus:border-teal-500 focus:ring-teal-500 data-[invalid]:border-red-300 data-[invalid]:hover:border-red-500 data-[invalid]:focus:border-red-500 data-[invalid]:focus:ring-red-500 [&:user-invalid]:border-red-300 [&:user-invalid]:hover:border-red-500 [&:user-invalid]:focus:border-red-500 [&:user-invalid]:focus:ring-red-500',
            variant === 'large' &&
              'h-20 px-6 text-[40px] font-medium leading-[48px]',
            variant === 'medium' && 'px-3',
          )}
          defaultValue={defaultValue}
          aria-errormessage={errorMessageId}
          onChange={(event) => {
            if (onChange) {
              onChange(event);
            }

            event.target.reportValidity();
          }}
        />

        <FieldError id={errorMessageId} show={showServerError}>
          {serverError}
        </FieldError>
      </div>
    </Field>
  );
};
