import { clsx } from 'clsx';
import { NextButton } from '../common/NextButton';
import {
  useIsLocationDetail,
  useTasksOverviewLocationId,
} from '../utils/hooks';
import { AssessmentInterstitial } from '../resolver/AssessmentInterstitial';
import { generateResolverTasksBrowserUrl } from '../utils';
import { ResolverType } from '../utils/types';

export const TasksOverviewInterstitial = () => {
  const locationId = useTasksOverviewLocationId();
  const isLocationDetail = useIsLocationDetail();

  return (
    <div
      className={clsx(
        'mx-8 mb-8 overflow-hidden rounded-lg border border-teal-350',
        isLocationDetail && 'mt-8',
      )}
    >
      <AssessmentInterstitial
        actions={
          <NextButton
            to={generateResolverTasksBrowserUrl({
              locationId,
              resolverType: ResolverType.Assessment,
              searchParams: { skip: 'true' },
            })}
          />
        }
      />
    </div>
  );
};
