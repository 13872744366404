import { QuestionsCollection } from './QuestionsCollection';
import { SetupCollection } from './SetupCollection';
import { InProgressCollection } from './InProgressCollection';
import { LegislationCollection } from './LegislationCollection';
import { HandbookCTA } from './HandbookCTA';

export const TasksOverviewSidebar = () => {
  return (
    <aside className="isolate flex flex-col gap-y-7 pt-0">
      <SetupCollection />
      <QuestionsCollection />
      <HandbookCTA />
      <InProgressCollection />
      <LegislationCollection />
    </aside>
  );
};
