import { useEffect, useMemo, useState } from 'react';
import {
  useActionData,
  useAsyncValue,
  useLocation,
  useMatch,
  useMatches,
  useParams,
  useRouteLoaderData,
} from 'react-router-dom';
import { components, LocationScope, paths, TaskType } from '@mosey/api-types';
import {
  useNextSearchParamsValue,
  usePendingSearchParamsValue,
} from '@mosey/components/hooks/navigation';
import {
  USStateAbbrev,
  USStateName,
  isUSStateCode,
} from '@mosey/utils/constants/us-states';
import { getTaskDueDate, isTaskOverdue, isTaskTodo } from '@mosey/utils/tasks';
import { ResolverType, TaskRouteParams } from './types';
import { TaskActionData } from '../tasks/types';
import { AutomationTypeEnum } from '../../../types';
import { getNextTask } from '.';
import { useUser } from '../../../hooks/useUser';
import { clearNewTasksForSession, getNewTasksForSession } from './session';
import { PolicyInstance } from '../../handbook/types';

type TasksResponse =
  paths['/api/compliance/tasks']['get']['responses']['200']['content']['application/json'];
type Legislation =
  paths['/api/legislation']['get']['responses']['200']['content']['application/json'];
type AllLocationsSummary =
  paths['/api/legal_entity/locations/summary']['get']['responses']['200']['content']['application/json'];
type LocationSummary =
  paths['/api/legal_entity/locations/summary/{region_code}']['get']['responses']['200']['content']['application/json'];
type RegionConfiguration =
  paths['/api/compliance/tasks/{region_code}/configuration']['get']['responses']['200']['content']['application/json'];
type UsersResponse =
  paths['/api/users']['get']['responses']['200']['content']['application/json'];
type WizardConfig =
  paths['/api/wizard/{wizard_slug}/config']['get']['responses']['200']['content']['application/json'];

type TaskRemediation = components['schemas']['TaskRemediation'];
type TaskRef = components['schemas']['TaskRef'];
type Task = components['schemas']['Task'];

export const useTasks = (exactRouteId?: string) => {
  const { resolverType, locationId } = useParams<TaskRouteParams>();
  const matches = useMatches();
  let routeId;

  if (exactRouteId) {
    routeId = matches.find(({ id }) => {
      return id === exactRouteId;
    })?.id;
  } else {
    routeId = matches.find(({ id }) => {
      return id.startsWith('resolver-tasks/');
    })?.id;
  }

  const tasksFromApi = useRouteLoaderData(routeId || '') as TaskRef[];
  const newTasksThisSessionJSON = getNewTasksForSession(
    locationId,
    resolverType,
  );

  return useMemo(() => {
    if (!tasksFromApi) {
      return [];
    }

    let newTasksThisSessionByParentId: Record<string, string[]> = {};

    if (newTasksThisSessionJSON) {
      try {
        newTasksThisSessionByParentId = JSON.parse(newTasksThisSessionJSON);
      } catch (_error) {
        // No new tasks for this session
        clearNewTasksForSession(locationId, resolverType);
      }
    }

    const newTasksThisSessionArr = newTasksThisSessionByParentId
      ? Object.entries(newTasksThisSessionByParentId).reduce(
          (acc, [parentId, newTaskIds]) => {
            if (!tasksFromApi.find((task) => task.id === parentId)) {
              /**
               * If the parent task is not in the list of tasks from the API, don't
               * include its child tasks in the new tasks array to be filtered out.
               */
              return acc;
            }

            return [...acc, ...newTaskIds];
          },
          [] as string[],
        )
      : [];

    return tasksFromApi
      .filter((task) => {
        /**
         * Don't include any tasks that are new this session. They will be added
         * after their parent task below.
         */
        return !newTasksThisSessionArr.includes(task.id);
      })
      .flatMap((task) => {
        const groupedTasks = [task];
        const childTasks = newTasksThisSessionByParentId[task.id];

        if (childTasks) {
          for (let j = 0; j < childTasks.length; j++) {
            const newTaskId = childTasks[j];
            const newTask = tasksFromApi.find((t) => t.id === newTaskId);

            if (newTask) {
              groupedTasks.push(newTask);
            }
          }
        }

        return groupedTasks;
      });
  }, [locationId, tasksFromApi, newTasksThisSessionJSON, resolverType]);
};

const useTaskLoaderData = () => {
  const matches = useMatches();
  const routeId = matches.find(({ id }) => {
    return id.startsWith('task/');
  })?.id;

  if (!routeId) {
    throw new Error(
      'The useTask/useTaskPolicyInstances hooks must be used within a Task route.',
    );
  }

  return useRouteLoaderData(routeId) as {
    task: Task;
    policyInstances: Array<PolicyInstance>;
    users: UsersResponse;
  };
};

export const useTaskPolicyInstances = () => {
  const { policyInstances } = useTaskLoaderData();
  return policyInstances;
};

export const useTask = () => {
  const { task } = useTaskLoaderData();
  return task;
};

export const useTaskSourceQuestion = () => {
  const { source } = useTask();

  return source as components['schemas']['TaskQuestion'];
};

export const useTaskSourceRequirement = () => {
  const { source } = useTask();

  return source as components['schemas']['TaskRequirement'];
};

export const useRemediations = () => {
  const {
    legal_entity: { name: legalEntityName, notice_url: noticeUrl },
  } = useUser();
  const { remediations, location, summary } = useTaskSourceRequirement();
  const isNotices = useIsNoticesAndPostersTask();
  const isPolicyGen = useIsPolicyGenTask();

  if (isNotices) {
    if (!noticeUrl) {
      throw new Error(
        'The notice_url is missing and is required for notice tasks.',
      );
    }

    return [
      {
        id: 'notices',
        title: 'Link to Your Notice Board',
        description:
          'Add the link to your notice board to an internal wiki or document that is readily accessible to all remote employees.',
        order: 0,
        paths: [
          {
            type: 'link',
            value: `${noticeUrl}?region=${location.name}`,
            text: `${location.name} Notice Board – ${legalEntityName}`,
          },
          {
            type: 'link',
            value: noticeUrl,
            text: `Notice Board (All Locations) – ${legalEntityName}`,
          },
        ],
      } as TaskRemediation,
      ...remediations,
    ];
  } else if (isPolicyGen) {
    return [
      {
        id: 'policy-gen',
        title: 'Establish policies',
        description: summary,
        order: 0,
        paths: [
          {
            type: 'policy-generation',
            value: 'policy-generation',
          },
        ],
      } as TaskRemediation,
    ];
  }

  return remediations;
};

export const useTodoTasks = () => {
  const tasks = useTasks();

  return tasks.filter(isTaskTodo);
};

export const useResolverUrl = () => {
  const matches = useMatches();
  return matches.find(({ id }) => id.startsWith('resolver-tasks/'))?.pathname;
};

export const useWizardUrl = () => {
  const matches = useMatches();
  return matches.find(({ id }) => id.startsWith('wizard/'))?.pathname;
};

export const useTaskUrl = () => {
  const matches = useMatches();
  const url = matches.find(({ id }) => id.startsWith('task/'));

  if (!url) {
    throw new Error(
      'The useTaskUrl hook must be used within a Task Resolver Task route.',
    );
  }

  return url.pathname;
};

export const useTasksOverviewUrl = () => {
  const matches = useMatches();
  const url = matches.find(({ id }) => id.startsWith('tasks-overview/'));

  if (!url) {
    throw new Error(
      'The useTasksOverviewUrl hook must be used within a Tasks Overview Task route.',
    );
  }

  return url.pathname;
};

export const useNextTask = () => {
  const { resolverType, taskId } = useParams();
  const tasks = useTasks();

  return getNextTask(resolverType, tasks, taskId);
};

export const useNextTaskUrl = () => {
  const nextTask = useNextTask();
  const resolverUrl = useResolverUrl();

  if (nextTask && resolverUrl) {
    return `${resolverUrl}/tasks/${nextTask.id}`;
  }

  return null;
};

export const useNextResolverUrl = () => {
  const nextTaskUrl = useNextTaskUrl();
  const resolverUrl = useResolverUrl();

  if (!nextTaskUrl && resolverUrl) {
    return `${resolverUrl}/complete`;
  }

  return nextTaskUrl;
};

export const useTasksFrameworkLocation = () => {
  const { locationId } = useParams();

  if (locationId) {
    return {
      code: locationId as USStateAbbrev,
      name: USStateName[locationId.toUpperCase() as keyof typeof USStateName],
    };
  }

  return null;
};

export const useTaskLocation = () => {
  const location = useTasksFrameworkLocation();
  const { source } = useTask();

  if (location) {
    return location;
  } else if (source.type === TaskType.requirement) {
    return {
      code: source.location.id as USStateAbbrev,
      name: source.location.name as USStateName,
    };
  } else if (
    source.type === TaskType.question &&
    source.location_scope !== LocationScope.entity
  ) {
    return {
      code: source.locations[0].id as USStateAbbrev,
      name: source.locations[0].name as USStateName,
    };
  }

  return null;
};

export const useTaskActionData = () => {
  return useActionData() as TaskActionData;
};

export const useNewTasks = () => {
  const allTasks = useTasks();
  const taskActionData = useTaskActionData();
  const { state } = useLocation();
  let newTasks: TaskRef[] | undefined;

  if (taskActionData?.newTasks && taskActionData.newTasks.length > 0) {
    newTasks = taskActionData.newTasks;
  } else if (state?.newTasks?.length > 0) {
    newTasks = state.newTasks as TaskRef[];
  }

  if (newTasks) {
    if (allTasks.length > 0) {
      return newTasks.filter((newTask) => {
        return allTasks.find((task) => task.id === newTask.id);
      });
    }

    return newTasks;
  }

  return [];
};

export const useIsNewTask = (id: string) => {
  const newTasks = useNewTasks();
  const newTaskIndex = newTasks.findIndex((newTask) => newTask.id === id);

  return newTaskIndex > -1;
};

export const useIsNoticesAndPostersTask = () => {
  const { source } = useTask();

  return (
    source.type === TaskType.requirement &&
    source.tags.includes(AutomationTypeEnum.NoticeBoard)
  );
};

export const useIsPolicyGenTask = () => {
  const { source } = useTask();

  return (
    source.type === TaskType.requirement &&
    source.tags.includes(AutomationTypeEnum.Handbook)
  );
};

export const useNewTasksTimeToReveal = () => {
  const newTasks = useNewTasks();
  const analyzingTime = 3000;
  const generatingTime = 1000;
  const visibilityDelay = analyzingTime + generatingTime;
  const idealIndividualRevealTime = 1000;
  const maxTotalRevealTimeWithoutDelay = 3000;
  const totalRevealTime = Math.min(
    maxTotalRevealTimeWithoutDelay,
    newTasks.length * idealIndividualRevealTime,
  );

  return { analyzingTime, generatingTime, visibilityDelay, totalRevealTime };
};

export const useNewTaskRevealDelay = (id: string) => {
  const newTasks = useNewTasks();
  const newTaskIndex = newTasks.findIndex((newTask) => newTask.id === id);
  const { visibilityDelay, totalRevealTime } = useNewTasksTimeToReveal();

  return (totalRevealTime / newTasks.length) * newTaskIndex + visibilityDelay;
};

export const useNewTaskVisibility = (id: string) => {
  const { resolverType } = useParams();
  let timeToShow = useNewTaskRevealDelay(id);

  if (resolverType === ResolverType.Assessment) {
    timeToShow = 0;
  }

  const [isVisible, setIsVisible] = useState(timeToShow === 0);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;

    if (!isVisible) {
      timeoutId = setTimeout(setIsVisible, timeToShow, true);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isVisible, timeToShow]);

  return isVisible;
};

export const useIsStandaloneRoute = () => {
  const matches = useMatches();
  return !matches.find(({ id }) => id.startsWith('resolver-tasks/'));
};

export const useIsAutomationRoute = () => {
  const taskUrl = useTaskUrl();

  return Boolean(useMatch(`${taskUrl}/automation`));
};

export const useIsOverdue = () => {
  const task = useTask();

  return isTaskOverdue(task);
};

export const useDueDate = () => {
  const task = useTask();

  return getTaskDueDate(task);
};

interface TasksOverviewData {
  tasks: TasksResponse;
  inProgress: TasksResponse;
  handbookTodos: TasksResponse;
  assessmentTasks?: TasksResponse;
  allQuestionTasks?: TasksResponse;
  setupTasks?: TasksResponse;
  legislation: Legislation;
  summary: LocationSummary | AllLocationsSummary;
  configuration?: RegionConfiguration;
}

export const useTasksOverviewPendingData = () => {
  const matches = useMatches();
  const routeId = matches.find(({ id }) => {
    return id.startsWith('tasks-overview/');
  })?.id;

  if (!routeId) {
    throw new Error(
      'The useTasksOverviewPendingData hook must be used within a Tasks Overview route.',
    );
  }

  const promise = useRouteLoaderData(routeId) as
    | {
        data: Promise<Response>;
      }
    | undefined;

  if (!promise) {
    throw new Error('Attempted to render Tasks Overview without API data!');
  }

  return promise.data;
};

export const useTasksOverviewData = (): TasksOverviewData => {
  const data = useAsyncValue() as TasksOverviewData;

  if (data === undefined) {
    throw new Error(
      'The useTasksOverviewData hook must be used within a Tasks Overview route.',
    );
  }

  return data;
};

export const useNextTasksOverviewLocationId = () => {
  return useNextSearchParamsValue('location_id');
};

export const useTasksOverviewLocationId = () => {
  const locationId = usePendingSearchParamsValue('location_id');
  const params = useParams();

  return locationId || params.locationId || null;
};

export const useIsLocationDetail = () => {
  return useMatch('/locations/:countryId/:locationId/*');
};

export const useUsers = () => {
  const { users } = useTaskLoaderData();
  return users;
};

export const useTasksOverviewLocation = () => {
  const locationId = useTasksOverviewLocationId();

  if (locationId && isUSStateCode(locationId.toUpperCase())) {
    return {
      code: locationId as USStateAbbrev,
      name: USStateName[locationId.toUpperCase() as keyof typeof USStateName],
    };
  }

  return null;
};

export const useWizardConfigMaybe = () => {
  const matches = useMatches();
  const routeId = matches.find(({ id }) => {
    return id.startsWith('wizard/');
  })?.id;

  return useRouteLoaderData(routeId ?? '') as WizardConfig | undefined;
};

export const useWizardConfig = () => {
  const maybeWizardConfig = useWizardConfigMaybe();

  if (!maybeWizardConfig) {
    throw new Error(
      'The useWizardConfig hook must be used within a Wizard route.',
    );
  }

  return maybeWizardConfig;
};

export const useWizardCurrentStep = () => {
  const { current_step: currentStep } = useWizardConfig();

  return currentStep;
};

export const useWizardCurrentStepIndex = () => {
  const { steps, current_step: currentStep } = useWizardConfig();

  return steps.findIndex(({ slug }) => slug === currentStep?.slug);
};

export const useWizardCurrentTask = () => {
  const { current_task: currentTask } = useWizardConfig();

  return currentTask;
};

export const useWizardCurrentTaskUrl = () => {
  const wizardUrl = useWizardUrl();
  const currentStep = useWizardCurrentStep();
  const currentTask = useWizardCurrentTask();

  if (currentStep && currentTask) {
    return `${wizardUrl}/step/${currentStep.slug}/tasks/${currentTask.id}`;
  }

  return null;
};
/**
 * This Hook will return the index of the wizard step the user is currently on.
 *
 * Note: The step the user is viewing is not necessarily the same as the current
 * step in the wizard configuration.
 *
 * @returns The index of the step the user is viewing.
 */
export const useWizardRouteStepIndex = () => {
  const { wizardStepSlug } = useParams();
  const { steps } = useWizardConfig();

  return steps.findIndex(({ slug }) => slug === wizardStepSlug);
};

/**
 * This Hook will return the next step after the one currently being viewed. If
 * there are no more steps, it will use the current step defined by the server
 * if available. If not, returns null.
 *
 * @returns The next wizard step or null
 */
export const useWizardNextStep = () => {
  const { steps } = useWizardConfig();
  const currentStepIndex = useWizardCurrentStepIndex();
  const routeStepIndex = useWizardRouteStepIndex();

  // Get the next step after the one currently being viewed
  let nextStepIndex = routeStepIndex + 1;

  // If index is out of bounds, check if server defines a current step
  if (nextStepIndex >= steps.length) {
    if (currentStepIndex > -1) {
      nextStepIndex = currentStepIndex;
    } else {
      // If no current step, wizard is complete so return null
      return null;
    }
  }

  return steps[nextStepIndex];
};

/**
 * This Hook will return the wizard step the user is currently on.
 *
 * Note: The step the user is viewing is not necessarily the same as the current
 * step in the wizard configuration.
 *
 * @returns The wizard step the user is viewing.
 */
export const useWizardRouteStep = () => {
  const { steps } = useWizardConfig();
  const routeStepIndex = useWizardRouteStepIndex();

  if (routeStepIndex <= -1) {
    throw new Error('Attempting to access a non-existent wizard step.');
  }

  return steps[routeStepIndex];
};

export const useWizardNextStepUrl = () => {
  const wizardUrl = useWizardUrl();
  const nextStep = useWizardNextStep();

  return `${wizardUrl}/${nextStep ? `step/${nextStep.slug}` : 'complete'}`;
};

export const useWizardInterstitial = () => {
  const step = useWizardRouteStep();

  return step?.interstitial ?? null;
};

export const useFinishUrl = () => {
  const location = useTasksFrameworkLocation();

  return location ? `/locations/usa/${location.code}/overview` : '/home';
};
