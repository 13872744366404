import { useProgressBar, AriaProgressBarProps } from 'react-aria';

export type StepperProps = {
  children: React.ReactNode;
  progress?: number;
} & Omit<AriaProgressBarProps, 'value'>;

export const Stepper = ({ children, progress, ...rest }: StepperProps) => {
  const { progressBarProps, labelProps } = useProgressBar({
    ...rest,
    value: progress,
  });

  return (
    <nav>
      <div {...progressBarProps} className="sr-only">
        {/**
         * Note: For now, the label is hidden by default because we don't have
         * designs that utilize a label. In the future, we may want to show the
         * label visually
         */}
        {rest.label && <div {...labelProps}>{rest.label}</div>}
        <div>{progressBarProps['aria-valuetext']}</div>
      </div>

      <ol className="isolate flex">{children}</ol>
    </nav>
  );
};
