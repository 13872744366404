import { useEffect, useRef } from 'react';
import { clsx } from 'clsx';
import { useParams } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import { components } from '@mosey/api-types';
import { CardWrapper } from '@mosey/components/layout/CardWrapper';
import { TaskSummaryCard } from '@mosey/components/layout/Card';
import { getCalculatedTaskStatus } from '@mosey/utils/tasks';
import {
  useTasks,
  useResolverUrl,
  useIsNewTask,
  useNewTaskVisibility,
  useTasksFrameworkLocation,
} from '../utils/hooks';
import { ResolverType, TaskRouteParams } from '../utils/types';
import { getFinishRedirectUrl } from '../utils/session';
import { BackButton } from '../../../components';

type TaskRef = components['schemas']['TaskRef'];

interface ResolverSidebarItemProps {
  task: TaskRef;
}

const ResolverSidebarItem = ({ task }: ResolverSidebarItemProps) => {
  const { id, title } = task;
  const ref = useRef<HTMLLIElement>(null);
  const { taskId } = useParams<TaskRouteParams>();
  const urlPrefix = useResolverUrl();
  const isCurrentTask = taskId === id;
  const isNew = useIsNewTask(id);
  const isVisible = useNewTaskVisibility(id);

  useEffect(() => {
    if (isCurrentTask && ref.current && isVisible) {
      ref.current.scrollIntoView({ block: 'nearest' });
    }
  }, [isCurrentTask, isVisible]);

  return (
    <CardWrapper className={clsx(!isVisible && 'hidden')} ref={ref}>
      <TaskSummaryCard
        variant="nav"
        to={`${urlPrefix}/tasks/${id}`}
        status={isNew ? 'new' : getCalculatedTaskStatus(task)}
        title={title}
      />
    </CardWrapper>
  );
};

export const ResolverSidebar = () => {
  const { resolverType } = useParams();
  const tasks = useTasks();
  const location = useTasksFrameworkLocation();
  const backUrl = getFinishRedirectUrl();

  let heading = 'Tasks';
  let body = 'Complete these tasks to stay compliant';

  if (
    resolverType === ResolverType.Assessment ||
    resolverType === ResolverType.Review
  ) {
    heading = 'Configure';
    body =
      'Setup tasks and additional questions will be generated based on your responses.';
  } else if (resolverType === ResolverType.Setup) {
    heading = 'Set up';
    body =
      'These tasks are based on payroll data and your responses to questions.';
  } else if (resolverType === ResolverType.Question) {
    heading = `Tell us more about your business ${location?.name ? ` ${location.name}` : ''}`;
    body = 'Please provide some additional information to ensure compliance.';
  } else if (resolverType === ResolverType.Managed) {
    body = 'These tasks are managed by your payroll provider.';
  }

  return (
    <Transition
      as="div"
      unmount={false}
      show={tasks.length > 1}
      className="shrink-0 overflow-x-hidden transition-nav duration-300 ease-in-out"
      leaveFrom="w-72 @6xl/task:w-96"
      leaveTo="w-0 opacity-0"
      enterFrom="w-0 opacity-0"
      enterTo="w-72 @6xl/task:w-96"
    >
      <aside className="my-8 w-72 overflow-y-auto pl-8 pr-4 @6xl/task:w-96">
        <nav
          className="flex flex-col gap-y-1"
          aria-live="polite"
          aria-relevant="additions"
        >
          {backUrl && (
            <div className="mb-2">
              <BackButton to={backUrl} />
            </div>
          )}

          <h2 className="text-lg font-semibold leading-7 text-zinc-800 @6xl/task:text-base">
            {heading}
          </h2>
          <p className="text-sm leading-5 text-zinc-600">{body}</p>

          <ul className="mt-3 grow pb-px">
            {tasks.map((task) => (
              <ResolverSidebarItem key={task.id} task={task} />
            ))}
          </ul>
        </nav>
      </aside>
    </Transition>
  );
};
