import { clsx } from 'clsx';
import { ExclamationIcon } from '@heroicons/react/outline';

interface FieldErrorProps {
  id: string;
  show?: boolean;
  children: React.ReactNode;
}

export const FieldError = ({ id, show = false, children }: FieldErrorProps) => {
  return (
    <p
      id={id}
      className={clsx(
        'flex w-full items-center gap-x-2 text-rose-800',
        show && 'block',
        !show && 'hidden',
      )}
    >
      <ExclamationIcon className="size-5" />
      {children}
    </p>
  );
};
