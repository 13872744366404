import { Link, useParams } from 'react-router-dom';
import { components } from '@mosey/api-types';
import { Button } from '@mosey/components/buttons/Button';
import { useIsLocationDetail, useTasksOverviewData } from '../utils/hooks';
import {
  formatQuestionAnswer,
  generateResolverTasksBrowserUrl,
} from '../utils';
import { ResolverType } from '../utils/types';

interface ConfigurationItemProps {
  title: React.ReactNode;
  value?: components['schemas']['TaskQuestion']['answer'];
}

export const ConfigurationItem = ({ title, value }: ConfigurationItemProps) => {
  if (value) {
    return (
      <li className="flex h-12 flex-col items-start justify-center gap-y-0.5">
        <div className="whitespace-nowrap text-xs uppercase tracking-widest text-sage-800">
          {title}
        </div>
        <div className="text-zinc-800">{formatQuestionAnswer(value)}</div>
      </li>
    );
  }

  return null;
};

export const ConfigurationBanner = () => {
  const { configuration } = useTasksOverviewData();
  const isLocationDetail = useIsLocationDetail();
  const { locationId } = useParams();

  if (
    isLocationDetail &&
    locationId &&
    configuration &&
    Object.values(configuration).some(Boolean)
  ) {
    return (
      <div className="flex items-center gap-x-8 border-b border-gray-200 bg-sage-200 px-8 py-4">
        <ul className="flex h-12 flex-wrap items-center gap-x-8 overflow-hidden">
          <ConfigurationItem
            title="Registered Agent"
            value={configuration.registered_agent}
          />
          <ConfigurationItem
            title="Employees"
            value={configuration.employees}
          />
          <ConfigurationItem
            title="Sales Tax"
            value={configuration.sales_tax_nexus}
          />
          <ConfigurationItem
            title="Business Income Tax"
            value={configuration.business_income_tax_nexus}
          />
          <ConfigurationItem
            title="Secretary of State"
            value={configuration.doing_business}
          />
        </ul>

        <div className="ml-auto">
          <Button
            variant="secondary"
            as={Link}
            to={generateResolverTasksBrowserUrl({
              locationId,
              resolverType: ResolverType.Review,
            })}
          >
            Review configuration
          </Button>
        </div>
      </div>
    );
  }

  return null;
};
