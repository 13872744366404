import { CheckCircleIcon } from '@heroicons/react/solid';
import { getStatusIconData } from '@mosey/components/layout/StatusIcon';
import { QueryFilter } from '@mosey/components/menus/QueryFilter';
import { RobotWithRefreshIcon } from '@mosey/components/Icons';
import { TasksFilterStatus } from '@mosey/api-types';
import { useSubmit } from 'react-router-dom';
import { useTasksOverviewUrl } from '../utils/hooks';
import { FilterOption } from '@mosey/components/menus/Filter';

export const TasksOverviewStatusFilter = () => {
  const submit = useSubmit();
  const tasksOverviewUrl = useTasksOverviewUrl();
  return (
    <QueryFilter
      queryKey="status"
      handleChange={(option: FilterOption) => {
        submit(
          { status: option.value, intent: 'update-tasks-filter-preference' },
          {
            method: 'POST',
            action: tasksOverviewUrl,
            navigate: false,
          },
        );
      }}
      options={[
        {
          label: 'Action required',
          value: TasksFilterStatus.todo,
          icon: getStatusIconData('new'),
        },
        {
          label: 'Overdue',
          value: TasksFilterStatus.overdue,
          icon: getStatusIconData('overdue'),
        },
        {
          label: 'Done',
          value: TasksFilterStatus.done,
          icon: {
            Component: CheckCircleIcon,
            baseColor: 'text-teal-700',
          },
        },
        {
          label: 'Automated',
          value: TasksFilterStatus.automated,
          icon: getStatusIconData('automated'),
        },
        {
          label: 'Managed',
          value: TasksFilterStatus.managed,
          icon: {
            Component: RobotWithRefreshIcon,
            baseColor: 'text-violet-600',
          },
        },
      ]}
    />
  );
};
