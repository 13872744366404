import { Animation } from '../animation/Animation';

export const ProcessingTasks = () => {
  return (
    <Animation
      loadAnimation={() => import('./assets/processing-tasks.json')}
      className="aspect-[820/220] w-full"
    />
  );
};
