import { ReactNode } from 'react';

type PolicyDetailsContainerProps = {
  header: ReactNode;
  content: ReactNode;
};

export const PolicyDetailsContainer = ({
  header,
  content,
}: PolicyDetailsContainerProps) => {
  return (
    <div className="w-full bg-amber-50 px-4 pb-4">
      <div className="group flex w-full items-center gap-1 rounded pt-2 text-sm font-semibold text-amber-900 outline-none focus-visible:ring-2 focus-visible:ring-rose-600">
        {header}
      </div>
      <div className="text-sm text-amber-900">{content}</div>
    </div>
  );
};
