import { useState } from 'react';
import { Form, useFetcher } from 'react-router-dom';
import { Field, Input, Label } from '@headlessui/react';
import { Button } from '@mosey/components/buttons/Button';
import { components } from '@mosey/api-types';
import { Placeholder } from '@mosey/components/navigation/Placeholder';
import { CopyButton } from '@mosey/components/buttons/CopyButton';

interface AgencyAccountAttributeProps {
  attribute: components['schemas']['AgencyAccountAttribute'];
}

export const AgencyAccountAttributeFormKey = 'add-agency-account-datum-form';

export const AgencyAccountAttribute = ({
  attribute,
}: AgencyAccountAttributeProps) => {
  const [showForm, setShowForm] = useState(false);
  const fetcher = useFetcher();

  if (attribute.value) {
    return (
      <dl>
        <dt className="text-sm text-zinc-700" aria-label={attribute.name}>
          {attribute.name}
        </dt>
        <dd
          className="flex items-center gap-x-1 text-teal-800"
          aria-label={attribute.value}
        >
          {attribute.value}

          <CopyButton
            label={attribute.name}
            valueToCopy={attribute.value}
            variant="inline"
          />
        </dd>
      </dl>
    );
  }

  if (showForm) {
    return (
      <Form
        className="flex items-end gap-x-2"
        method="POST"
        navigate={false}
        fetcherKey={AgencyAccountAttributeFormKey}
      >
        <Field className="w-full">
          <Label className="block text-sm text-zinc-700">
            {attribute.name}
          </Label>

          <Input
            type={attribute.type === 'date' ? 'date' : 'text'}
            min={attribute.type === 'date' ? '1000-01-01' : undefined}
            max={attribute.type === 'date' ? '9999-12-31' : undefined}
            required
            aria-required="true"
            className="mt-2 w-full rounded border-gray-300 px-3 py-2 outline-none transition-shadow duration-150 focus:border-teal-500 focus:ring-teal-500 focus-visible:border-teal-600 focus-visible:outline-2 focus-visible:outline-offset-0 focus-visible:outline-teal-600"
            name="value"
          />
        </Field>

        <Button
          type="submit"
          variant="secondary"
          name="public_id"
          isLoading={fetcher.state !== 'idle'}
          value={attribute.public_id}
          size="large"
        >
          Save
        </Button>
      </Form>
    );
  }

  return (
    <Placeholder
      as="button"
      size="small"
      variant="dynamic"
      fitToWidth
      onClick={() => {
        setShowForm(true);
      }}
    >
      Add {attribute.name}
    </Placeholder>
  );
};
