import { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { ErrorIcon } from '@mosey/components/Icons';
import { fetchApi } from '../utils/fetchApi';
import { ApiStatus, IApiData } from '../utils/types';
import { Loading } from '../views/Loading';

type InboxMessageProps = {
  title: string;
  description: string | ReactNode;
  icon: ReactNode;
};

export const InboxMessage: FunctionComponent<InboxMessageProps> = ({
  title,
  description,
  icon,
}) => {
  return (
    <div className="flex h-full flex-col items-center justify-center bg-white">
      <div className="max-w-md">
        <div className="mb-6 size-10 rounded bg-gray-100 p-2 text-gray-500">
          {icon}
        </div>
        <div className="mb-6">
          <h3 className="text-xl font-bold">{title}</h3>
          <p className="text-gray-500">{description}</p>
        </div>
      </div>
    </div>
  );
};

export type MailPreviewProps = {
  url: string;
};

export const MailPreview: FunctionComponent<MailPreviewProps> = ({ url }) => {
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsError(false);
    setIsLoading(true);

    // The URL returned by the Mail API includes the hostname so we need
    // to remove it in order to use it with fetchAPI
    const path = new URL(url).pathname;
    let objectUrl: null | string = null;

    // Download the pdf and generate an object URL that can be used by
    // the iframe to view the file
    fetchApi({ url: path, method: 'GET', contentType: '' }).then(
      ({ status, data }: IApiData) => {
        if (status === ApiStatus.Success) {
          objectUrl = URL.createObjectURL(data);
          setPreviewUrl(objectUrl);
        } else {
          setIsError(true);
        }
        setIsLoading(false);
      },
    );

    // Cleanup function to revoke the object URL when the component unmounts or the URL changes
    return () => {
      if (objectUrl) {
        URL.revokeObjectURL(objectUrl);
        objectUrl = null;
      }
    };
  }, [url]);

  if (isError) {
    return (
      <InboxMessage
        icon={<ErrorIcon />}
        title="Preview unavailable"
        description={
          <span className="mt-4 block">
            <span>
              A preview scan is not yet available. Please allow 24 hours for the
              scan to complete or contact us if the issue persists.
            </span>
          </span>
        }
      />
    );
  }

  if (!previewUrl || isLoading) {
    return <Loading />;
  }

  return (
    <>
      <iframe
        src={`${previewUrl}#toolbar=0`}
        title={url}
        className="w-full flex-1"
      />
    </>
  );
};
