import { FunctionComponent } from 'react';

import {
  HumanResourcesIcon,
  InsuranceIcon,
  PayrollIcon,
  RegistrationIcon,
  TaxIcon,
} from '@mosey/components/Icons';
import { RequirementCategory } from '../types';

export const CategoryToIconMapping: Record<
  RequirementCategory,
  FunctionComponent
> = {
  [RequirementCategory.Payroll]: PayrollIcon,
  [RequirementCategory.PayrollSetup]: PayrollIcon,
  [RequirementCategory.Registration]: RegistrationIcon,
  [RequirementCategory.RegistrationMaintenance]: RegistrationIcon,
  [RequirementCategory.HR]: HumanResourcesIcon,
  [RequirementCategory.Insurance]: InsuranceIcon,
  [RequirementCategory.InsuranceSetup]: InsuranceIcon,
  [RequirementCategory.Tax]: TaxIcon,
  [RequirementCategory.TaxSetup]: TaxIcon,
  [RequirementCategory.SalesTax]: TaxIcon,
};

export const CategoryToStringMapping: Record<RequirementCategory, string> = {
  [RequirementCategory.Payroll]: 'Payroll',
  [RequirementCategory.PayrollSetup]: 'Payroll setup',
  [RequirementCategory.Registration]: 'Registration',
  [RequirementCategory.RegistrationMaintenance]: 'Maintenance',
  [RequirementCategory.HR]: 'HR',
  [RequirementCategory.Insurance]: 'Insurance',
  [RequirementCategory.InsuranceSetup]: 'Insurance setup',
  [RequirementCategory.Tax]: 'Tax',
  [RequirementCategory.TaxSetup]: 'Tax setup',
  [RequirementCategory.SalesTax]: 'Sales tax',
};
