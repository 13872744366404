import { useEffect, useMemo, useState } from 'react';
import { clsx } from 'clsx';
import { Form, Link, useActionData, useNavigation } from 'react-router-dom';
import { components, TasksFilterStatus } from '@mosey/api-types';
import { TaskSummaryCard } from '@mosey/components/layout/Card';
import { usePendingSearchParamsValue } from '@mosey/components/hooks/navigation';
import { Field, Label } from '@headlessui/react';
import { Toggle } from '@mosey/components/toggles/Toggle';
import { Button } from '@mosey/components/buttons/Button';
import { BlockAlert } from '@mosey/components/layout/BlockAlert';
import {
  getCalculatedTaskStatus,
  getTaskDueDate,
  isTaskTodo,
} from '@mosey/utils/tasks';
import {
  useTasksOverviewData,
  useTasksOverviewLocationId,
  useTasksOverviewUrl,
} from '../utils/hooks';
import { generateResolverTasksBrowserUrl } from '../utils';
import { TasksOverviewZeroState } from './TasksOverviewZeroState';
import { TasksOverviewStatusFilter } from './TasksOverviewStatusFilter';
import { Loading } from '../../Loading';
import { TasksOverviewActionResponse } from './types';
import { ResolverType } from '../utils/types';
import { QuestionsAlert } from './alerts/QuestionsAlert';
import { SetupAlert } from './alerts/SetupAlert';
import { HandbookAlert } from './alerts/HandbookAlert';
import { NewCoverageAlert } from './alerts/NewCoverageAlert';
import { TasksOverviewCategoryFilter } from './TasksOverviewCategoryFilter';

type TaskRef = components['schemas']['TaskRef'];

export const TasksOverviewMainTasks = () => {
  const [isBulkEdit, setIsBulkEdit] = useState(false);
  const [selectedTasks, setSelectedTasks] = useState<
    Record<TaskRef['id'], boolean>
  >({});
  const navigation = useNavigation();
  const actionData = useActionData() as TasksOverviewActionResponse | undefined;
  const status =
    usePendingSearchParamsValue('status') || TasksFilterStatus.todo;
  const category = usePendingSearchParamsValue('category') || null;
  const locationId = useTasksOverviewLocationId();
  const resolverType = status ?? ResolverType.Todo;
  const { tasks } = useTasksOverviewData();
  const tasksOverviewUrl = useTasksOverviewUrl();

  const sortedTasks = useMemo(() => {
    return tasks.sort((a, b) => {
      if (a.source.type === 'requirement' && b.source.type === 'requirement') {
        const aDueDate = a.source.due_date;
        const bDueDate = b.source.due_date;

        if (!aDueDate && !bDueDate) {
          return 0;
        } else if (!aDueDate && bDueDate) {
          return 1;
        } else if (aDueDate && !bDueDate) {
          return -1;
        } else if (aDueDate && bDueDate) {
          const aDate = new Date(aDueDate);
          const bDate = new Date(bDueDate);

          if (aDate < bDate) {
            return -1;
          } else if (aDate > bDate) {
            return 1;
          }
        }

        return 0;
      }

      return 0;
    });
  }, [tasks]);

  useEffect(() => {
    if (navigation.state === 'loading') {
      setIsBulkEdit(false);
      setSelectedTasks({});
    }
  }, [navigation.state]);

  const numberOfSelectedTasks =
    Object.values(selectedTasks).filter(Boolean).length;

  const isReloadingTasks =
    navigation.state === 'loading' &&
    navigation.location.pathname === location.pathname;

  return (
    <section className="flex flex-col gap-y-2">
      <div className="contents">
        <div className="mb-4 flex flex-col gap-y-3 empty:mb-0">
          <QuestionsAlert />
          <SetupAlert />
          <NewCoverageAlert />
          <HandbookAlert />
        </div>
      </div>

      <div className="flex items-center">
        <div className="flex gap-x-8">
          <TasksOverviewStatusFilter />
          <TasksOverviewCategoryFilter />
        </div>

        {(status === null ||
          status === TasksFilterStatus.todo ||
          status === TasksFilterStatus.overdue) &&
          !isReloadingTasks && (
            <div className="ml-auto flex items-center gap-x-6">
              {sortedTasks.length > 1 && (
                <Field className="flex items-center gap-x-2">
                  <Toggle checked={isBulkEdit} onChange={setIsBulkEdit} />
                  <Label className="text-sm text-zinc-700">Bulk edit</Label>
                </Field>
              )}

              {sortedTasks.length > 0 && (
                <Button
                  as={Link}
                  size="small"
                  to={generateResolverTasksBrowserUrl({
                    locationId,
                    resolverType,
                    category,
                  })}
                  rightIcon={
                    <div
                      aria-label={`${sortedTasks.length} tasks`}
                      className="min-w-4 rounded-full bg-rose-50 px-1 text-center text-[10px] font-medium text-rose-700"
                    >
                      {sortedTasks.length}
                    </div>
                  }
                >
                  Start
                </Button>
              )}
            </div>
          )}
      </div>

      <div aria-live="polite">
        {isReloadingTasks ? (
          <div className="h-dvh max-h-[calc(100vh-80px-100px-40px)]">
            <Loading />
          </div>
        ) : sortedTasks.length > 0 ? (
          <Form
            method="POST"
            action={tasksOverviewUrl}
            className="isolate flex flex-col gap-y-2"
          >
            <BlockAlert
              variant="error"
              message={actionData?.errors?.submit}
              scrollIntoView
              show={!!actionData?.errors?.submit && navigation.state === 'idle'}
              aria-live="assertive"
            />

            {isBulkEdit && (
              <div className="sticky top-2 isolate z-10 flex items-center rounded-sm border border-teal-300 bg-teal-100 px-3 py-2 text-sm text-zinc-700">
                <p>
                  {numberOfSelectedTasks > 0
                    ? `${numberOfSelectedTasks} task${numberOfSelectedTasks > 1 ? 's' : ''} selected`
                    : `Select multiple tasks to edit in bulk`}
                </p>

                <div
                  className={clsx(
                    'ml-auto',
                    numberOfSelectedTasks === 0 && 'invisible',
                  )}
                >
                  <Button
                    disabled={numberOfSelectedTasks === 0}
                    isLoading={navigation.state === 'submitting'}
                    type="submit"
                    name="intent"
                    value="mark-as-done"
                    variant="secondary"
                  >
                    Mark as done
                  </Button>
                </div>
              </div>
            )}

            <ul className="isolate space-y-2">
              {sortedTasks.map((task) => {
                const { id, title, source } = task;
                const formattedDueDate = getTaskDueDate(task);
                const calculatedStatus = getCalculatedTaskStatus(task);

                return (
                  <li key={id} className="flex items-center gap-x-3">
                    {isBulkEdit && (
                      <>
                        <span
                          className="sr-only"
                          id={`task-checkbox-label-${id}`}
                        >
                          {title}
                        </span>
                        <input
                          type="checkbox"
                          aria-labelledby={`task-checkbox-label-${id}`}
                          name={id}
                          value={id}
                          disabled={
                            !isTaskTodo(task) ||
                            navigation.state === 'submitting'
                          }
                          checked={selectedTasks[id] ?? false}
                          onChange={(event) => {
                            setSelectedTasks((prev) => ({
                              ...prev,
                              [id]: event.target.checked,
                            }));
                          }}
                          className="w-4 rounded"
                        />
                      </>
                    )}

                    <div className="min-w-0 max-w-full shrink grow">
                      <TaskSummaryCard
                        status={calculatedStatus}
                        title={title}
                        size="medium"
                        description={
                          source.type === 'requirement'
                            ? source.summary || source.description
                            : undefined
                        }
                        reason={
                          formattedDueDate
                            ? {
                                title: `Due ${formattedDueDate}`,
                                type: 'due',
                              }
                            : undefined
                        }
                        to={generateResolverTasksBrowserUrl({
                          resolverType,
                          locationId,
                          taskId: id,
                          category,
                        })}
                        region={
                          source.type === 'requirement'
                            ? source.location.name
                            : source.type === 'question'
                              ? source.locations[0].name
                              : ''
                        }
                      />
                    </div>
                  </li>
                );
              })}
            </ul>
          </Form>
        ) : (
          <TasksOverviewZeroState />
        )}
      </div>
    </section>
  );
};
