import {
  components,
  paths,
  TagInstance,
  TaskCategory,
  TaskDateFilter,
  TaskStatus,
  TaskType,
} from '@mosey/api-types';
import { toUTCMidnight } from '@mosey/utils/dates';
import { isTaskTodo } from '@mosey/utils/tasks';
import { isUSStateCode } from '@mosey/utils/constants/us-states';
import { ResolverType } from './types';
import { isoDate } from '../../../utils/format';
import { IFetchApi } from '../../../utils/types';
import { generatePath, redirect } from 'react-router-dom';
import { getResolverStartDate } from './session';
import { createSearchParamsFromObject } from '@mosey/utils/data/api';

type Task = components['schemas']['Task'];
type TaskRef = components['schemas']['TaskRef'];

export const getNextTask = (
  resolverType: string | undefined,
  tasks: TaskRef[],
  taskId?: Task['id'],
) => {
  if (taskId) {
    const currentIndex = tasks.findIndex(({ id }) => id === taskId) + 1;

    if (resolverType === ResolverType.Review) {
      return tasks.at(currentIndex);
    }

    return tasks
      .slice(currentIndex)
      .concat(tasks.slice(0, currentIndex))
      .find(isTaskTodo);
  }

  return tasks.find(isTaskTodo);
};

export const formatQuestionAnswer = (
  answer: components['schemas']['TaskQuestion']['answer'],
) => {
  if (answer === 'true') {
    return 'Yes';
  } else if (answer === 'false') {
    return 'No';
  }

  return answer;
};

export const isResolverType = (value: string): value is ResolverType => {
  return Object.values(ResolverType).includes(value as ResolverType);
};

type WizardStepRequestQueryParameters =
  paths['/api/wizard/{wizard_slug}/step/{wizard_step_slug}']['get']['parameters']['query'];

export const generateWizardStepTasksAPIUrl = (
  wizardSlug: string,
  wizardStepSlug: string,
  locationId?: string | null,
): IFetchApi => {
  let url = `/api/wizard/${wizardSlug}/step/${wizardStepSlug}`;
  const queryParameters: WizardStepRequestQueryParameters = {};

  if (locationId) {
    if (isUSStateCode(locationId.toUpperCase())) {
      queryParameters.region_id = locationId;
    } else {
      throw redirect('/home');
    }
  }

  const searchParams = createSearchParamsFromObject(queryParameters);

  if (searchParams.size > 0) {
    url += `?${searchParams.toString()}`;
  }

  return { url, method: 'GET' };
};

const setCategoryQueryParam = (
  queryParameters: TasksRequestQueryParameters,
  category?: string | null,
) => {
  if (category && queryParameters) {
    switch (category) {
      case TaskCategory.payroll:
        queryParameters.categories = [
          TaskCategory.payroll,
          TaskCategory.insurance,
        ];
        break;
      case TaskCategory.hr:
        queryParameters.categories = [TaskCategory.hr];
        break;
      case TaskCategory.registration:
        queryParameters.categories = [
          TaskCategory.registration,
          TaskCategory.registration_maintenance,
        ];
        break;
      case TaskCategory.tax:
        queryParameters.categories = [TaskCategory.tax, TaskCategory.sales_tax];
        break;
    }
  }
  return queryParameters;
};

type TasksRequestQueryParameters =
  paths['/api/compliance/tasks']['get']['parameters']['query'];

export const generateResolverTasksAPIUrl = (
  resolverType?: string | null,
  locationId?: string | null,
  category?: string | null,
  isResolver?: boolean,
): IFetchApi => {
  let url = '/api/compliance/tasks';
  const queryParameters: TasksRequestQueryParameters = {};

  if (locationId) {
    if (isUSStateCode(locationId.toUpperCase())) {
      queryParameters.region_id = locationId;
    } else {
      throw redirect('/home');
    }
  }

  let endDate: Date | string = toUTCMidnight(new Date());

  endDate.setDate(endDate.getDate() + 90);
  endDate = isoDate(endDate);

  if (resolverType) {
    if (!isResolverType(resolverType)) {
      throw redirect('/home');
    }

    switch (resolverType) {
      case ResolverType.Review:
        queryParameters.excluded_tags = [TagInstance.handbook];
        queryParameters.statuses = [TaskStatus.todo];
        queryParameters.task_type = TaskType.question;
        queryParameters.is_managed = false;

        if (isResolver) {
          queryParameters.statuses.push(TaskStatus.done);
        }

        break;

      case ResolverType.Assessment:
        queryParameters.excluded_tags = [TagInstance.handbook];
        queryParameters.task_type = TaskType.question;
        queryParameters.is_managed = false;
        queryParameters.is_setup = true;

        break;

      case ResolverType.Setup:
        queryParameters.excluded_tags = [TagInstance.handbook];
        queryParameters.is_setup = true;
        queryParameters.task_type = TaskType.requirement;
        queryParameters.is_managed = false;

        break;

      case ResolverType.Question:
        queryParameters.excluded_tags = [TagInstance.handbook];
        queryParameters.statuses = [TaskStatus.todo];
        queryParameters.completed_start_time = getResolverStartDate(
          resolverType,
          locationId,
        );
        queryParameters.task_type = TaskType.question;
        queryParameters.is_managed = false;

        if (isResolver) {
          queryParameters.statuses.push(TaskStatus.done);
        }

        break;

      case ResolverType.Todo:
        setCategoryQueryParam(queryParameters, category);
        queryParameters.excluded_tags = [TagInstance.handbook];
        queryParameters.end_date = endDate;
        queryParameters.completed_start_time = getResolverStartDate(
          resolverType,
          locationId,
        );
        queryParameters.task_type = TaskType.requirement;
        queryParameters.statuses = [TaskStatus.todo];

        if (isResolver) {
          queryParameters.statuses.push(TaskStatus.done);
        }

        queryParameters.is_managed = false;
        break;

      case ResolverType.Overdue:
        setCategoryQueryParam(queryParameters, category);
        queryParameters.excluded_tags = [TagInstance.handbook];
        queryParameters.end_date = endDate;
        queryParameters.completed_start_time = getResolverStartDate(
          resolverType,
          locationId,
        );
        queryParameters.date_filter = TaskDateFilter.overdue;
        queryParameters.task_type = TaskType.requirement;
        queryParameters.statuses = [TaskStatus.todo];

        if (isResolver) {
          queryParameters.statuses.push(TaskStatus.done);
        }

        queryParameters.is_managed = false;
        break;

      case ResolverType.Done:
        queryParameters.excluded_tags = [TagInstance.handbook];
        queryParameters.task_type = TaskType.requirement;
        queryParameters.statuses = [TaskStatus.done];
        queryParameters.is_automation = false;
        queryParameters.is_managed = false;

        break;

      case ResolverType.Automated:
        queryParameters.excluded_tags = [TagInstance.handbook];
        queryParameters.task_type = TaskType.requirement;
        queryParameters.statuses = [TaskStatus.done];
        queryParameters.is_automation = true;
        queryParameters.is_managed = false;

        break;

      case ResolverType.Managed:
        queryParameters.excluded_tags = [TagInstance.handbook];
        queryParameters.end_date = endDate;
        queryParameters.task_type = TaskType.requirement;
        queryParameters.is_managed = true;

        break;

      case ResolverType.InProgress:
        queryParameters.excluded_tags = [TagInstance.handbook];
        queryParameters.task_type = TaskType.requirement;
        queryParameters.statuses = [TaskStatus.in_progress];
        queryParameters.is_automation = true;
        queryParameters.is_managed = false;

        break;

      case ResolverType.Handbook:
        queryParameters.task_type = TaskType.requirement;
        queryParameters.tags = [TagInstance.handbook];
        queryParameters.statuses = [TaskStatus.todo];
        queryParameters.completed_start_time = getResolverStartDate(
          resolverType,
          locationId,
        );

        if (isResolver) {
          queryParameters.statuses.push(TaskStatus.done);
        }

        queryParameters.is_managed = false;
        break;
    }
  }

  const searchParams = createSearchParamsFromObject(queryParameters);

  if (searchParams.size > 0) {
    url += `?${searchParams.toString()}`;
  }

  return { url, method: 'GET' };
};

export const generateResolverTasksBrowserUrl = ({
  resolverType,
  locationId,
  taskId,
  searchParams,
  category,
}: {
  resolverType?: string | null;
  locationId?: string | null;
  taskId?: string | null;
  searchParams?: ConstructorParameters<typeof URLSearchParams>[0];
  category?: string | null;
} = {}) => {
  let path = '';

  if (locationId) {
    if (isUSStateCode(locationId.toUpperCase())) {
      path += '/locations/:countryId/:locationId';
      locationId = locationId.toLowerCase();
    } else {
      throw new Error(`Invalid locationId: ${locationId}`);
    }
  } else {
    path += '/home';
  }

  if (resolverType && !isResolverType(resolverType)) {
    throw new Error(`Invalid resolverType: ${resolverType}`);
  }

  path += '/resolver/:resolverType?';

  if (category && category !== 'all') {
    path += '/category/:category?';
  }

  if (taskId) {
    path += '/tasks/:taskId';
  }

  let renderedPath = generatePath(path, {
    countryId: 'usa',
    locationId,
    resolverType,
    taskId,
    category,
  });

  searchParams = new URLSearchParams(searchParams);

  if (searchParams && searchParams.size > 0) {
    renderedPath += `?${searchParams.toString()}`;
  }

  return renderedPath;
};
