import { CopyField } from '@mosey/components/buttons/CopyField';
import { Table } from '@mosey/components/table/Table';
import { HeaderRow } from '@mosey/components/table/HeaderRow';
import { HeaderCell } from '@mosey/components/table/HeaderCell';
import { TableRow } from '@mosey/components/table/TableRow';
import { TableBody } from '@mosey/components/table/TableBody';
import { TableCell } from '@mosey/components/table/TableCell';
import { ConfigurationItem } from '../../tasks-framework/common/ConfigurationBanner';
import { ActiveTaxAccountBadge } from './TaxAccounts';

type AccountHistoryItem = {
  period: string;
  paymentType: string;
  payDate: string;
  origPayment: string;
  appliedAmount: string;
  ui: string;
  ett: string;
  sdi: string;
  pit: string;
  pAndI: string;
};

const TAX_ACCOUNT_HISTORY_ITEMS: AccountHistoryItem[] = [
  {
    period: '15-Jan-2025',
    paymentType: 'DE88 Payment',
    payDate: '15-Jan-2025',
    origPayment: '$644.52',
    appliedAmount: '$644.52',
    ui: '$0.00',
    ett: '$0.00',
    sdi: '$87.50',
    pit: '$557.02',
    pAndI: '$0.00',
  },
  {
    period: '31-Dec-2024',
    paymentType: 'DE88 Payment',
    payDate: '31-Dec-2024',
    origPayment: '$643.28',
    appliedAmount: '$643.28',
    ui: '$0.00',
    ett: '$0.00',
    sdi: '$80.21',
    pit: '$563.07',
    pAndI: '$0.00',
  },
  {
    period: '13-Dec-2024',
    paymentType: 'DE88 Payment',
    payDate: '13-Dec-2024',
    origPayment: '$643.28',
    appliedAmount: '$643.28',
    ui: '$0.00',
    ett: '$0.00',
    sdi: '$80.21',
    pit: '$563.07',
    pAndI: '$0.00',
  },
  {
    period: '29-Nov-2024',
    paymentType: 'DE88 Payment',
    payDate: '29-Nov-2024',
    origPayment: '$643.28',
    appliedAmount: '$643.28',
    ui: '$0.00',
    ett: '$0.00',
    sdi: '$80.21',
    pit: '$563.07',
    pAndI: '$0.00',
  },
  {
    period: '15-Nov-2024',
    paymentType: 'DE88 Payment',
    payDate: '15-Nov-2024',
    origPayment: '$643.28',
    appliedAmount: '$643.28',
    ui: '$0.00',
    ett: '$0.00',
    sdi: '$80.21',
    pit: '$563.07',
    pAndI: '$0.00',
  },
  {
    period: '31-Oct-2024',
    paymentType: 'DE88 Payment',
    payDate: '31-Oct-2024',
    origPayment: '$643.28',
    appliedAmount: '$643.28',
    ui: '$0.00',
    ett: '$0.00',
    sdi: '$80.21',
    pit: '$563.07',
    pAndI: '$0.00',
  },
];

export const TaxAccountDetail = () => {
  return (
    <>
      <div className="flex items-center gap-x-8 border-b border-gray-200 bg-sage-200 px-8 py-4">
        <ul className="flex h-12 flex-wrap items-center gap-x-8 overflow-hidden">
          <ConfigurationItem
            title="Status"
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            value={(<ActiveTaxAccountBadge bordered />) as any}
          />
          <ConfigurationItem title="Account Balance" value="$0.41" />
        </ul>
      </div>
      <div className="space-y-8 p-8">
        <div>
          <h2 className="mb-2 font-bold text-zinc-800">Account Info</h2>
          <div className="inline-flex w-full flex-col items-start justify-start overflow-hidden rounded-sm border border-[#edf0f1] bg-white">
            <div className="inline-flex flex-wrap items-start justify-between gap-3 self-stretch p-4">
              <div className="inline-flex flex-col items-start justify-start gap-0.5">
                <div className="text-sm font-normal leading-tight text-[#40434c]">
                  Account Number
                </div>
                <div className="inline-flex items-center justify-start gap-0.5">
                  <div className="flex items-center gap-1 text-base font-normal leading-normal text-[#006265]">
                    <CopyField
                      id="eddnum"
                      label="Employment Development Department (EDD) Number"
                    >
                      0080000092831
                    </CopyField>
                  </div>
                  <div className="relative size-3 overflow-hidden" />
                </div>
              </div>
              <div className="inline-flex flex-col items-start justify-start gap-0.5">
                <div className="text-sm font-normal leading-tight text-[#40434c]">
                  Initial UI Tax Rate
                </div>
                <div className="inline-flex items-center justify-start gap-0.5">
                  <div className="text-base font-normal leading-normal text-[#006265]">
                    <CopyField
                      id="ui"
                      label="Initial Unemployment Insurance (UI) Tax Rate"
                    >
                      3.10%
                    </CopyField>
                  </div>
                  <div className="relative size-3 overflow-hidden" />
                </div>
              </div>
              <div className="inline-flex flex-col items-start justify-start gap-0.5">
                <div className="text-sm font-normal leading-tight text-[#40434c]">
                  Initial ETT Rate
                </div>
                <div className="inline-flex items-center justify-start gap-0.5">
                  <div className="text-base font-normal leading-normal text-[#006265]">
                    <CopyField
                      id="ett"
                      label="Initial Employment Training Tax (ETT) Rate"
                    >
                      0.1175%
                    </CopyField>
                  </div>
                  <div className="relative size-3 overflow-hidden" />
                </div>
              </div>
              <div className="inline-flex w-72 flex-col items-start justify-start gap-0.5">
                <div className="text-sm font-normal leading-tight text-[#40434c]">
                  EDD PIN
                </div>
                <div className="inline-flex items-center justify-start gap-1.5">
                  <div className="text-base font-normal leading-normal text-[#006265]">
                    <CopyField id="eddpin" label="EDD PIN">
                      ••••
                    </CopyField>
                  </div>
                  <div className="relative size-3.5 overflow-hidden" />
                  <div className="relative size-3 overflow-hidden" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h2 className="mb-2 font-bold text-zinc-800">Account History</h2>
          <Table>
            <HeaderRow>
              <HeaderCell>Period</HeaderCell>
              <HeaderCell>Payment Type</HeaderCell>
              <HeaderCell>Pay Date</HeaderCell>
              <HeaderCell>Orig Payment</HeaderCell>
              <HeaderCell>Applied Amount</HeaderCell>
              <HeaderCell>UI</HeaderCell>
              <HeaderCell>ETF</HeaderCell>
              <HeaderCell>SDI</HeaderCell>
              <HeaderCell>PIT</HeaderCell>
              <HeaderCell>P & I</HeaderCell>
            </HeaderRow>
            <TableBody>
              {TAX_ACCOUNT_HISTORY_ITEMS.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.period}</TableCell>
                  <TableCell>{item.paymentType}</TableCell>
                  <TableCell>{item.payDate}</TableCell>
                  <TableCell>{item.origPayment}</TableCell>
                  <TableCell>{item.appliedAmount}</TableCell>
                  <TableCell>{item.ui}</TableCell>
                  <TableCell>{item.ett}</TableCell>
                  <TableCell>{item.sdi}</TableCell>
                  <TableCell>{item.pit}</TableCell>
                  <TableCell>{item.pAndI}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </>
  );
};
