import { Suspense, useEffect } from 'react';
import { clsx } from 'clsx';
import {
  ActionFunction,
  Await,
  defer,
  LoaderFunction,
  matchPath,
  useNavigation,
  useSearchParams,
} from 'react-router-dom';
import { toUTCMidnight } from '@mosey/utils/dates';
import {
  TasksFilterCategory,
  TasksFilterStatus,
  TaskStatus,
} from '@mosey/api-types';
import { api, apiBatch } from '../../../utils/fetchApi';
import { Loading } from '../../Loading';
import { TasksOverviewMainTasks } from './TasksOverviewMainTasks';
import { TasksOverviewSetupGate } from './TasksOverviewSetupGate';
import { TasksOverviewSidebar } from './sidebar/TasksOverviewSidebar';
import { TaskOverviewRegionFilters } from './region-filters/TaskOverviewRegionFilters';
import {
  useIsLocationDetail,
  useNextTasksOverviewLocationId,
  useTasksOverviewPendingData,
} from '../utils/hooks';
import { isoDate } from '../../../utils/format';
import { TasksOverviewActionResponse } from './types';
import { generateResolverTasksAPIUrl } from '../utils';
import { ConfigurationBanner } from '../common/ConfigurationBanner';
import { IFetchApi } from '../../../utils/types';
import { ResolverType } from '../utils/types';
import { useUser } from '../../../hooks/useUser';

export const action: ActionFunction = async ({
  request,
}): Promise<TasksOverviewActionResponse | Response> => {
  const { searchParams } = new URL(request.url);
  const locationId = searchParams.get('location_id');
  const { intent, ...data } = Object.fromEntries(await request.formData());

  if (intent === 'mark-as-done') {
    try {
      const batchSize = 25;
      const taskPublicIds = Object.values(data);
      for (let i = 0; i < taskPublicIds.length; i += batchSize) {
        const batch = taskPublicIds.slice(i, i + batchSize);
        await api({
          url: `/api/compliance/tasks/batch_status`,
          method: request.method,
          body: {
            task_public_ids: batch,
            status: TaskStatus.done,
            scoped_to_single_location: !!locationId,
          },
        });
      }
    } catch (_error) {
      return {
        errors: {
          submit: 'Something went wrong, please try again.',
        },
        resolved: false,
      };
    }
  }
  if (intent === 'update-tasks-filter-preference') {
    try {
      await api({
        url: `/api/compliance/tasks/filter_preference`,
        method: request.method,
        body: {
          category: data.category,
          status: data.status,
        },
      });
    } catch (_error) {
      return {
        errors: {
          submit: 'Something went wrong, please try again.',
        },
        resolved: false,
      };
    }
  }

  return {
    errors: {},
    resolved: true,
  };
};

export const loader: LoaderFunction = async ({ params, request }) => {
  const { searchParams, pathname } = new URL(request.url);

  const isLocationDetail = matchPath(
    '/locations/:countryId/:locationId/*',
    pathname,
  );

  const locationId = searchParams.get('location_id') || params.locationId;
  const status = searchParams.get('status') || TaskStatus.todo;
  const category = searchParams.get('category') || 'all';

  const endDate = toUTCMidnight(new Date());
  endDate.setDate(endDate.getDate() + 90);

  const calls: Record<string, IFetchApi> = {
    // TODO: Can probably use individual api calls instead of apiBatch
    tasks: generateResolverTasksAPIUrl(status, locationId, category),
    legislation: {
      url: '/api/legislation',
      method: 'GET',
    },
    summary: {
      url: `/api/legal_entity/locations/summary${isLocationDetail ? `/${locationId}` : ''}?end_date=${isoDate(endDate)}`,
      method: 'GET',
    },
    inProgress: generateResolverTasksAPIUrl(
      ResolverType.InProgress,
      locationId,
    ),
    handbookTodos: generateResolverTasksAPIUrl(
      ResolverType.Handbook,
      locationId,
    ),
  };

  if (isLocationDetail) {
    calls.configuration = {
      url: `/api/compliance/tasks/${locationId}/configuration`,
      method: 'GET',
    };
  }

  if (locationId) {
    calls.assessmentTasks = generateResolverTasksAPIUrl(
      ResolverType.Assessment,
      locationId,
    );
    calls.allQuestionTasks = generateResolverTasksAPIUrl(
      ResolverType.Question,
      locationId,
    );

    calls.setupTasks = generateResolverTasksAPIUrl(
      ResolverType.Setup,
      locationId,
    );
  }

  return defer({
    data: apiBatch(calls).then((response) => response.json()),
  });
};

export const Component = () => {
  const isLocationDetail = useIsLocationDetail();
  const [searchParams, setSearchParams] = useSearchParams();
  const locationId = searchParams.get('location_id');
  const nextLocationId = useNextTasksOverviewLocationId();
  const navigation = useNavigation();
  const data = useTasksOverviewPendingData();
  const { user_preference: userPreference } = useUser();

  useEffect(() => {
    if (userPreference?.tasks_filter) {
      const updatedParams = new URLSearchParams(searchParams);
      const preferredStatus = userPreference.tasks_filter.status;
      let needsUpdate = false;
      if (
        preferredStatus &&
        !searchParams.get('status') &&
        preferredStatus !== TasksFilterStatus.todo // no need to update if it's the default status
      ) {
        needsUpdate = true;
        updatedParams.set('status', preferredStatus);
      }
      const preferredCategory = userPreference.tasks_filter.category;
      if (
        preferredCategory &&
        !searchParams.get('category') &&
        preferredCategory !== TasksFilterCategory.all // no need to update if it's the default category
      ) {
        needsUpdate = true;
        updatedParams.set('category', preferredCategory);
      }
      if (needsUpdate) {
        setSearchParams(updatedParams, { replace: true });
      }
    }
  }, []);

  return (
    <div className="relative flex grow flex-col overflow-auto @container/tasks-overview">
      <Suspense fallback={<Loading />}>
        <Await resolve={data}>
          {!isLocationDetail && <TaskOverviewRegionFilters />}

          {navigation.state === 'loading' &&
          locationId !== nextLocationId &&
          navigation.location.pathname === location.pathname ? (
            <Loading />
          ) : (
            <TasksOverviewSetupGate>
              <ConfigurationBanner />

              <div
                className={clsx(
                  'space-y-7 px-8 pb-6 @5xl/tasks-overview:grid @5xl/tasks-overview:grid-cols-[minmax(0,_1fr)_374px] @5xl/tasks-overview:gap-x-10 @5xl/tasks-overview:space-y-0',
                  isLocationDetail && 'mt-6',
                )}
              >
                <TasksOverviewMainTasks />
                <TasksOverviewSidebar />
              </div>
            </TasksOverviewSetupGate>
          )}
        </Await>
      </Suspense>
    </div>
  );
};
