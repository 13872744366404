import { Link, Navigate, useParams } from 'react-router-dom';
import { Button } from '@mosey/components/buttons/Button';
import { LoadingTasks } from '@mosey/components/graphics/LoadingTasks';
import {
  useWizardConfig,
  useWizardCurrentTaskUrl,
  useWizardNextStepUrl,
} from '../utils/hooks';
import { WizardInterstitial } from './WizardInterstitial';

export const WizardWelcome = () => {
  const { wizardSlug } = useParams();
  const {
    welcome: { title, body },
  } = useWizardConfig();
  const nextStepUrl = useWizardNextStepUrl();
  const currentTaskUrl = useWizardCurrentTaskUrl();

  if (currentTaskUrl) {
    return <Navigate to={currentTaskUrl} replace />;
  }

  let graphic = null;

  // TODO: Move this to Wizard Config once we have Admin capabilities
  if (wizardSlug === 'new-coverage' || wizardSlug === 'new-coverage-local') {
    graphic = <LoadingTasks />;
  }

  return (
    <WizardInterstitial
      title={title}
      body={body}
      graphic={graphic}
      actions={
        <Button as={Link} size="xlarge" to={nextStepUrl}>
          Get started
        </Button>
      }
    />
  );
};
