import { CheckCircleIcon } from '@heroicons/react/solid';
import { components } from '@mosey/api-types';
import { getCalculatedTaskStatus } from '@mosey/utils/tasks';
import { Pill, PillProps } from './Pill';
import {
  StopwatchIcon,
  RobotWithCheckIcon,
  RobotWithRefreshIcon,
  TodoCircleIcon,
  IconProps,
  RobotWithClockIcon,
} from '../Icons';

type Task = components['schemas']['Task'];
type TaskRef = components['schemas']['TaskRef'];

export interface TaskStatusPillProps
  extends Omit<PillProps, 'Icon' | 'variant' | 'children'> {
  task: Task | TaskRef;
  isNew?: boolean;
  children?: React.ReactNode;
}

const getNewIcon = (isCircle: PillProps['isCircle']) => {
  const NewIcon = (props: IconProps) => {
    return (
      <div className="relative">
        <TodoCircleIcon {...props} />
        {isCircle && (
          <p className="absolute -bottom-3 left-1/2 -translate-x-1/2 rounded-lg bg-teal-800 px-1 py-[2px] text-[7px] font-medium uppercase leading-[7px] text-rose-50">
            New
          </p>
        )}
      </div>
    );
  };

  return NewIcon;
};

export const TaskStatusPill = ({
  task,
  isNew = false,
  children,
  ...rest
}: TaskStatusPillProps) => {
  let content = 'To do';
  let Icon = TodoCircleIcon;
  let variant: PillProps['variant'] = 'secondary';
  const status = getCalculatedTaskStatus(task);

  switch (status) {
    case 'overdue':
      content = 'Overdue';
      variant = 'primary';
      Icon = StopwatchIcon;
      break;

    case 'done':
      content = 'Done';
      variant = 'success';
      Icon = CheckCircleIcon;
      break;

    case 'in-progress':
      content = 'In progress';
      variant = 'automation';
      Icon = RobotWithClockIcon;
      break;

    case 'automated':
      content = 'Automated';
      variant = 'automation';
      Icon = RobotWithCheckIcon;
      break;

    case 'managed':
      content = 'Managed';
      variant = 'automation';
      Icon = RobotWithRefreshIcon;
      break;
  }

  if (isNew) {
    Icon = getNewIcon(rest.isCircle);
  }

  return (
    <Pill Icon={Icon} variant={variant} {...rest}>
      {children || content}
    </Pill>
  );
};
