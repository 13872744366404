import { FunctionComponent, useState, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Navigate,
  useNavigate,
  useRevalidator,
  useSearchParams,
} from 'react-router-dom';
import { Button } from '@mosey/components/buttons/Button';
import { FormError } from '@mosey/components/forms/FormError';
import { useBatchApi } from '../hooks';
import {
  BatchApiStatusHandler,
  Renderer,
  Section,
  SectionHeading,
  formSpecToRendererConfig,
} from '../components';
import { FormSpec, SubscriptionPlanEnum } from '../types';
import {
  cleanBusinessInformationFormValues,
  cleanIntakeDates,
} from '../utils/intake';
import { successCallbackPath } from '../utils/paths';
import { fetchApi } from '../utils/fetchApi';
import { ApiStatus, IApiData } from '../utils/types';
import { transformServerFieldErrors } from '../utils/form';
import { OnboardingBusinessProfileSuccess } from './OnboardingBusinessProfileSuccess';
import { useUser } from '../hooks/useUser';

type BusinessInformationFormProps = {
  formSpecs: FormSpec[];
  // NOTE: destination should not include domain
  destination?: string;
};

const BusinessInformationForm: FunctionComponent<
  BusinessInformationFormProps
> = ({ formSpecs, destination }) => {
  const formSpec = formSpecs[0];
  const formIsEmpty = formSpec.sections[0].form_fields.length === 0;
  const revalidator = useRevalidator();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const callbackUrl = searchParams.get('callback_url');
  const user = useUser();
  const hasBasicPlan =
    user.legal_entity.subscription_plan === SubscriptionPlanEnum.basic;
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [hasSubmitError, setHasSubmitError] = useState<boolean>(false);
  const [submitSucceeded, setSubmitSucceeded] = useState<boolean>(false);
  const formChildrenRef = useRef<HTMLDivElement>(null);
  const formMethods = useForm({ defaultValues: formSpec?.default_values });
  const {
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = formMethods;

  const onSubmit = async (data: unknown) => {
    setIsSubmitting(true);
    setHasSubmitError(false);
    clearErrors();
    let cleanedData = cleanIntakeDates(data);
    cleanedData = cleanBusinessInformationFormValues(data);
    const payload = { data: cleanedData };

    // save the business information
    const { status: formCaptureStatus, error: formCaptureError }: IApiData =
      await fetchApi({
        url: `/api/form_captures/save/business_information`,
        method: 'POST',
        body: payload,
      });

    // handle errors
    if (
      formCaptureStatus === ApiStatus.Error ||
      formCaptureError === ApiStatus.ErrorUnauthorized
    ) {
      if (formCaptureError.detail && Array.isArray(formCaptureError.detail)) {
        const errorMap = transformServerFieldErrors(formCaptureError.detail);
        Object.keys(errorMap).forEach((fieldKey) =>
          setError(fieldKey, errorMap[fieldKey]),
        );
      } else {
        setHasSubmitError(true);
      }
      setIsSubmitting(false);
      return;
    }

    // finish setting up business profile
    await fetchApi({
      url: '/api/onboarding/configure_business_profile',
      method: 'POST',
    });

    revalidator.revalidate();

    // redirect to callback url if provided
    if (callbackUrl) {
      navigate(successCallbackPath(callbackUrl), { replace: true });
      return;
    }

    if (hasBasicPlan) {
      navigate('/onboarding/basic');
      return;
    }

    setSubmitSucceeded(true);

    setIsSubmitting(false);
  };

  if (formIsEmpty || submitSucceeded) {
    if (destination) {
      return <Navigate to={destination} replace />;
    } else if (submitSucceeded) {
      return <OnboardingBusinessProfileSuccess />;
    } else {
      return <Navigate to="/" />;
    }
  } else {
    return (
      <Section className="flex flex-col items-center">
        <SectionHeading className="w-96 pb-4" text={formSpec.title} />
        <FormProvider {...formMethods}>
          <form className="my-8 w-96" onSubmit={handleSubmit(onSubmit)}>
            <div ref={formChildrenRef}>
              <Renderer
                config={formSpecToRendererConfig(formSpec)}
                errors={errors}
              />
            </div>
            <Button
              type="submit"
              isFullWidth
              size="large"
              isLoading={isSubmitting}
            >
              Continue
            </Button>
            {hasSubmitError && <FormError />}
          </form>
        </FormProvider>
      </Section>
    );
  }
};

type BusinessInformationFormViewProps = {
  destination?: string;
};

export const BusinessInformationFormView: FunctionComponent<
  BusinessInformationFormViewProps
> = ({ destination }) => {
  const batchResponse = useBatchApi([
    {
      url: '/api/form_captures/view/business_information',
      method: 'POST',
    },
  ]);

  const componentPropsFn = ([
    formSpecs,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ]: any[]): BusinessInformationFormProps => {
    return {
      formSpecs,
      destination,
    };
  };

  return (
    <BatchApiStatusHandler
      batchResponse={batchResponse}
      component={BusinessInformationForm}
      componentProps={componentPropsFn}
    />
  );
};
