import { Suspense } from 'react';
import { Await, Navigate } from 'react-router-dom';
import { TasksOverviewSetupGate } from './tasks-framework/overview/TasksOverviewSetupGate';
import { useTasksOverviewPendingData } from './tasks-framework/utils/hooks';
import { Loading } from './Loading';

export const LocationDetailIndex = () => {
  const data = useTasksOverviewPendingData();

  return (
    <Suspense fallback={<Loading />}>
      <Await resolve={data}>
        <TasksOverviewSetupGate>
          <Navigate to="overview" replace />;
        </TasksOverviewSetupGate>
      </Await>
    </Suspense>
  );
};
