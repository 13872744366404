import { WizardCard } from '@mosey/components/layout/WizardCard';
import {
  useIsLocationDetail,
  useTasksOverviewData,
  useTasksOverviewLocation,
} from '../../utils/hooks';
import { useUser } from '../../../../hooks/useUser';

export const NewCoverageAlert = () => {
  const user = useUser();
  const location = useTasksOverviewLocation();
  const isLocationDetail = useIsLocationDetail();
  const { summary } = useTasksOverviewData();

  if (user.role !== 'admin') {
    return null;
  }

  const wizardSlugs = Object.values(summary).reduce((acc, { wizards }) => {
    for (let i = 0; i < wizards.length; i++) {
      const slug = wizards[i];

      if (slug.startsWith('new-coverage')) {
        acc.add(wizards[i]);
      }
    }

    return acc;
  }, new Set<string>());

  return [...wizardSlugs].map((slug) => {
    const title =
      slug === 'new-coverage-local'
        ? 'Review local requirements'
        : 'Review additional requirements';

    return (
      <WizardCard
        key={slug}
        title={title}
        aria-label={title}
        description={`We’ve expanded our ${slug === 'new-coverage-local' ? 'local' : ''} coverage. See what’s new.`}
        to={
          isLocationDetail && location
            ? `/locations/usa/${location.code}/wizard/${slug}`
            : `/home/wizard/${slug}`
        }
      />
    );
  });
};
