import { TruncatedList } from '@mosey/components/layout/TruncatedList';
import { CardWrapper } from '@mosey/components/layout/CardWrapper';
import { TaskSummaryCard } from '@mosey/components/layout/Card';
import { useLegalEntityRegions } from '../../../../hooks/useUser';
import {
  useTasksOverviewData,
  useTasksOverviewLocationId,
} from '../../utils/hooks';
import { ResolverType } from '../../utils/types';
import { generateResolverTasksBrowserUrl } from '../../utils';

export const QuestionsCollection = () => {
  const locationId = useTasksOverviewLocationId();
  const regions = useLegalEntityRegions();
  const { summary } = useTasksOverviewData();

  if (!locationId) {
    const assessmentAlerts = regions.filter(
      (region) => summary[region.code]?.is_assessment,
    );

    return (
      <TruncatedList
        title="Answer Questions"
        body="Your responses generate setup tasks."
        itemLabel="locations"
        as="nav"
        aria-live="polite"
      >
        {assessmentAlerts.map((region) => {
          return (
            <CardWrapper key={region.code} as="li">
              <TaskSummaryCard
                title={region.name}
                status="question-collection"
                variant="collection"
                size="medium"
                to={generateResolverTasksBrowserUrl({
                  locationId: region.code,
                  resolverType: ResolverType.Assessment,
                })}
                aria-label={`${region.name} assessment`}
              />
            </CardWrapper>
          );
        })}
      </TruncatedList>
    );
  }

  return null;
};
