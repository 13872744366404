import { Animation } from '../animation/Animation';

export const CompleteTasks = () => {
  return (
    <Animation
      loadAnimation={() => import('./assets/complete-tasks.json')}
      className="aspect-[750/360] w-full"
    />
  );
};
