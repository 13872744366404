import { WizardCard } from '@mosey/components/layout/WizardCard';
import { isTaskTodo } from '@mosey/utils/tasks';
import {
  useTasksOverviewData,
  useTasksOverviewLocation,
} from '../../utils/hooks';
import { generateResolverTasksBrowserUrl } from '../../utils';
import { ResolverType } from '../../utils/types';

export const QuestionsAlert = () => {
  const location = useTasksOverviewLocation();
  const { allQuestionTasks = [] } = useTasksOverviewData();

  if (location && allQuestionTasks.some(isTaskTodo)) {
    return (
      <WizardCard
        variant="questions"
        title={`Tell us more about your business in ${location.name}`}
        description={`Your responses determine which ${location.name} requirements apply to you.`}
        to={generateResolverTasksBrowserUrl({
          locationId: location.code,
          resolverType: ResolverType.Question,
        })}
        truncate={false}
      />
    );
  }

  return null;
};
