import { WizardCard } from '@mosey/components/layout/WizardCard';
import { isTaskTodo } from '@mosey/utils/tasks';
import {
  useTasksOverviewData,
  useTasksOverviewLocation,
} from '../../utils/hooks';
import { generateResolverTasksBrowserUrl } from '../../utils';
import { ResolverType } from '../../utils/types';
import {
  useIsHandbookEnabled,
  useIsHandbookPresent,
} from '../../../handbook/hooks/useIsHandbook';

export const HandbookAlert = () => {
  const location = useTasksOverviewLocation();
  const { handbookTodos } = useTasksOverviewData();
  const isHandbookEnabled = useIsHandbookEnabled();
  const isHandbookPresent = useIsHandbookPresent();

  if (location && handbookTodos.some(isTaskTodo)) {
    return (
      <WizardCard
        variant="handbook"
        title="Adopt employee policies"
        description={`Add these ${location.name} policies to your handbook to stay compliant.`}
        to={
          isHandbookEnabled || isHandbookPresent
            ? '/handbook'
            : generateResolverTasksBrowserUrl({
                locationId: location.code,
                resolverType: ResolverType.Handbook,
              })
        }
      />
    );
  }

  const handbookRegionCount = handbookTodos.reduce((acc, task) => {
    if (task.source.type === 'requirement') {
      acc.add(task.source.location.id);
    } else if (task.source.type === 'question') {
      for (let i = 0; i < task.source.locations.length; i++) {
        acc.add(task.source.locations[i].id);
      }
    }

    return acc;
  }, new Set()).size;

  if (handbookRegionCount > 0) {
    return (
      <WizardCard
        variant="handbook"
        title="Adopt employee policies"
        description="Add these policies to your handbook to stay compliant."
        to={
          isHandbookEnabled || isHandbookPresent
            ? '/handbook'
            : generateResolverTasksBrowserUrl({
                resolverType: ResolverType.Handbook,
              })
        }
        truncate={false}
      />
    );
  }

  return null;
};
