import {
  MenuItems as HeadlessUIMenuItems,
  MenuItemsProps as HeadlessUiMenuItemsProps,
} from '@headlessui/react';

type MenuItemsProps = Omit<
  HeadlessUiMenuItemsProps,
  'className' | 'transition'
>;

export const MenuItems = (props: MenuItemsProps) => {
  return (
    <HeadlessUIMenuItems
      {...props}
      className="z-[1000] w-48 rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 transition duration-100 ease-out data-[closed]:scale-95 data-[closed]:opacity-0"
      transition
    />
  );
};
