import { USStateName } from '@mosey/utils/constants/us-states';
import { DEFAULT_DATE_OPTIONS, doesDateMatch } from '@mosey/utils/dates';
import {
  components,
  ETAUnit,
  FeeFixedType,
  FeeRangeType,
  FeeVariableType,
} from '@mosey/api-types';
import {
  RequirementStatus,
  RequirementComputedStatus,
  User,
  Fee,
  Criteria,
  CriteriaType,
  CriteriaEmployeeCount,
  criteriaTypesToSkipJM,
  ThresholdBoundsType,
  ThresholdBoundsGreaterThan,
  ThresholdBounds,
  ThresholdBoundsLessThan,
  ThresholdBoundsRange,
  LocationScope,
  LocationScopeType,
  LocationScopeCity,
  LocationScopeZip,
  ThresholdDateRangeType,
  CriteriaRegionEmployees,
  CriteriaRegionPhysicalLocation,
  CriteriaPayrollAmount,
  RequirementOverview,
  UserRef,
} from '../types';
import { isOverdue } from './requirement';

type ETARange = components['schemas']['ETARange'];

const DEFAULT_TIME_OPTIONS: Intl.DateTimeFormatOptions = {
  minute: '2-digit',
  hour: 'numeric',
  hour12: true,
};

export const formatLocalDate = (d: Date, opts = DEFAULT_DATE_OPTIONS) => {
  return d.toLocaleDateString('en-US', opts);
};
export const formatLocalTime = (d: Date, opts = DEFAULT_TIME_OPTIONS) => {
  return d.toLocaleTimeString('en-US', opts);
};

export const isoDate = (date: Date): string => {
  return date.toISOString().split('T')[0];
};

export const requirementComputedStatus = (
  req: RequirementOverview,
): RequirementComputedStatus => {
  if (req.status === RequirementStatus.Done) {
    return RequirementComputedStatus.Done;
  }

  if (req.status === RequirementStatus.Deferred) {
    return RequirementComputedStatus.Deferred;
  }

  if (req.status === RequirementStatus.InProgress) {
    return RequirementComputedStatus.InProgress;
  }

  if (req.is_blocked) {
    return RequirementComputedStatus.Locked;
  }

  if (req.is_managed) {
    return RequirementComputedStatus.Managed;
  }

  if (isOverdue(req)) {
    return RequirementComputedStatus.Overdue;
  }

  return RequirementComputedStatus.Todo;
};

// TODO: Fix this when we start generating types based on the OpenAPI schema
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const dueDateOccurrenceDescriptor = (schedule: any | null) => {
  if (!schedule) {
    return '';
  }
  switch (schedule.category) {
    case 'non-reporting':
      return `${schedule.month_days.length} occurences per year`;
    case 'reporting':
      return schedule.recurrence;
    default:
      return schedule.category;
  }
};

export const hostnameFromURL = (url: string): string => {
  try {
    const { hostname } = new URL(url);
    return hostname;
  } catch {
    console.error(`hostnameFromURL: Invalid url ${url}`);
    return url;
  }
};

export const capitalize = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const nameFromUser = (user: User | UserRef) => {
  if (user.first_name && user.last_name) {
    return `${user.first_name} ${user.last_name}`;
  }

  return user.email;
};

export const riskScoreToString = (score: number): string => {
  if (score >= 0 && score < 0.4) {
    return 'low';
  }
  if (score >= 0.4 && score < 0.8) {
    return 'med';
  }
  if (score >= 0.8) {
    return 'high';
  }

  // This shouldn't happen
  return 'low';
};

export const formatFee = (fee: Fee) => {
  let value = '';
  let description: React.ReactNode = null;
  const explanation: React.ReactNode = (
    <>
      Fees are based on state, filing, and shipping costs if required, and may
      be subject to change.
    </>
  );

  if (fee.type === FeeFixedType.fixed) {
    value = `$${fee.amount}`;
    description = (
      <>The estimated fee passed through to your account is {value}.</>
    );
  } else if (fee.type === FeeRangeType.range) {
    value = `$${fee.min} to $${fee.max}`;
    description = (
      <>The estimated fee passed through to your account ranges from {value}.</>
    );
  } else if (fee.type === FeeVariableType.variable) {
    value = 'Variable';
    description = (
      <>The estimated fee passed through to your account is variable.</>
    );
  }

  return { value, description, explanation };
};

const TIME_UNIT_LABEL: Record<ETAUnit, string> = {
  [ETAUnit.hour]: 'Hours',
  [ETAUnit.day]: 'Business Days',
  [ETAUnit.week]: 'Weeks',
  [ETAUnit.month]: 'Month',
};

export const formatEta = (eta: ETARange) => {
  const value = `${eta.min}-${eta.max}`;
  const unit = TIME_UNIT_LABEL[eta.unit];
  return { value, unit };
};

export const formatEtaAsProse = (eta: ETARange) => {
  const { unit, value } = formatEta(eta);
  return `The estimated processing time for this filing is ${value} ${unit?.toLowerCase()}.`;
};

enum RelativeDateType {
  Missed,
  LaterToday,
  FutureDate,
}
const formatRelativeDateTime = (date: Date, relativeDate: Date) => {
  if (date <= relativeDate) {
    return {
      value: 'Today',
      type: RelativeDateType.Missed,
    };
  } else if (doesDateMatch(relativeDate, date)) {
    return {
      value: formatLocalTime(date),
      type: RelativeDateType.LaterToday,
    };
  } else {
    return {
      value: formatLocalDate(date),
      type: RelativeDateType.FutureDate,
    };
  }
};

export const formatCompletionDateRange = (
  earliest: string,
  latest: string,
  relativeDate = new Date(),
): string => {
  const earliestDate = formatRelativeDateTime(
    new Date(`${earliest}Z`),
    relativeDate,
  );
  let { value: earliestValue } = earliestDate;
  const latestDate = formatRelativeDateTime(
    new Date(`${latest}Z`),
    relativeDate,
  );

  if (
    latestDate.type === RelativeDateType.FutureDate &&
    earliestDate.type === RelativeDateType.LaterToday
  ) {
    // avoid mixing date and time formats
    earliestValue = 'Today';
  } else if (
    latestDate.type === RelativeDateType.LaterToday &&
    earliestDate.type === RelativeDateType.Missed
  ) {
    // missed the earliest date, just show the latest time
    earliestValue = latestDate.value;
  }

  return earliestValue === latestDate.value
    ? earliestValue
    : `${earliestValue} - ${latestDate.value}`;
};

const formatThesholdBounds = (
  bounds: ThresholdBounds,
  criteriaType: CriteriaType,
): string => {
  const { type } = bounds;
  // currency only needed for PayrollAmount criteria type,
  // needs localization in the future
  const maybeCurrencySymbol =
    criteriaType === CriteriaType.PayrollAmount ? '$' : '';
  let typedBounds;
  switch (type) {
    case ThresholdBoundsType.GreaterThan:
      typedBounds = bounds as ThresholdBoundsGreaterThan;
      return `${maybeCurrencySymbol}${typedBounds.lower_bound + 1} or more`;
    case ThresholdBoundsType.LessThan:
      typedBounds = bounds as ThresholdBoundsLessThan;
      return `${maybeCurrencySymbol}${typedBounds.upper_bound - 1} or less`;
    case ThresholdBoundsType.Range:
      typedBounds = bounds as ThresholdBoundsRange;
      return `between ${maybeCurrencySymbol}${typedBounds.lower_bound} and ${maybeCurrencySymbol}${typedBounds.upper_bound}`;
    default:
      throw Error('unknown threshold bounds type');
  }
};

const formatLocationScope = (
  scope: LocationScope,
  regionName: string,
): string => {
  const { type } = scope;
  let typedScope;
  switch (type) {
    case LocationScopeType.Entity:
      return `at your company`;
    case LocationScopeType.Region:
      return `within ${regionName}`;
    case LocationScopeType.City:
      typedScope = scope as LocationScopeCity;
      return `within ${typedScope.city}`;
    case LocationScopeType.Zip:
      typedScope = scope as LocationScopeZip;
      // TODO: revisit formatting? list zip numbers?
      return `within ${typedScope.label}`;
    default:
      throw Error('unknown location scope type');
  }
};

const formatThresholdDateRange = (type: ThresholdDateRangeType): string => {
  switch (type) {
    case ThresholdDateRangeType.Current:
      return 'currently';
    case ThresholdDateRangeType.PreviousCalendarYear:
      return 'within the previous calendar year';
    case ThresholdDateRangeType.Terminated:
      return 'currently';
    default:
      throw Error('unknown threshold date range type');
  }
};

const formatCriteriaType = (type: CriteriaType): string => {
  switch (type) {
    case CriteriaType.EmployeeCount:
      // TODO: check for plurality?
      return 'employees';
    case CriteriaType.PayrollAmount:
      return 'total annual payroll expenses';
    default:
      throw Error('unknown criteria type');
  }
};

const formatBooleanCriteriaQuestion = (
  criteria: CriteriaRegionEmployees | CriteriaRegionPhysicalLocation,
  regionName: string,
): string => {
  const criteriaSubject =
    criteria.type === CriteriaType.RegionHasEmployees
      ? 'or plan to hire employees'
      : 'a physical location';
  return `Do you have ${criteriaSubject} in ${regionName}?`;
};

const formatComplexCriteriaQuestion = (
  criteria: CriteriaEmployeeCount | CriteriaPayrollAmount,
  regionName: string,
): string => {
  let maybeDateRange = '';
  if (criteria.type === CriteriaType.EmployeeCount) {
    const typedCriteria = criteria as CriteriaEmployeeCount;
    maybeDateRange = `${formatThresholdDateRange(
      typedCriteria.date_range_type,
    )} `;
  }
  const formattedBounds = formatThesholdBounds(criteria.bounds, criteria.type);
  const formattedCriteriaType = formatCriteriaType(criteria.type);
  const formattedLocationScope = formatLocationScope(
    criteria.scope,
    regionName,
  );
  return `Do you have ${formattedBounds} ${formattedCriteriaType} ${maybeDateRange}${formattedLocationScope}?`;
};

/**
 * Formats a Criteria object into a readable question.
 * Examples:
 * - Do you have $1500 or more total payroll expenses at your company?
 * - Do you have 5 or more employees currently at your company?
 */
export const formatCriteriaQuestion = (
  criteria: Criteria,
  regionName: string,
): string => {
  if (criteriaTypesToSkipJM.includes(criteria.type)) {
    return '';
  }
  if (
    criteria.type === CriteriaType.RegionHasEmployees ||
    criteria.type === CriteriaType.RegionHasPhysicalLocation
  ) {
    return formatBooleanCriteriaQuestion(criteria, regionName);
  }
  if (criteria.type === CriteriaType.EmployeeCount) {
    const employeeCountCriteria = criteria as CriteriaEmployeeCount;
    return formatComplexCriteriaQuestion(employeeCountCriteria, regionName);
  }
  const payrollAmountCriteria = criteria as CriteriaPayrollAmount;
  return formatComplexCriteriaQuestion(payrollAmountCriteria, regionName);
};

/*
 * Returns state's full name given a code
 */
export const getRegionNameByCode = (regionCode: string): string => {
  return (
    Object.entries(USStateName).find((entry) => {
      const [stateCode] = entry;
      return stateCode.toUpperCase() == regionCode.toUpperCase();
    })?.[1] || ''
  );
};
