import { FunctionComponent, useState } from 'react';
import {
  useFormContext,
  RegisterOptions,
  FieldError as FieldErrorT,
} from 'react-hook-form';
import { USStateName } from '@mosey/utils/constants/us-states';
import { PeopleOptionsType, Person, SelectOptionType } from '../../types';
import { filterPeople } from '../../utils/intake';
import { fetchApi } from '../../utils/fetchApi';
import {
  BaseSelectField,
  BaseSelectFieldProps,
  TextFieldSizes,
} from '@mosey/components/forms/BaseFormField';
import { FormField, FormFieldProps } from '@mosey/components/forms/FormField';
import { CountryCode } from '../../constants';
import { useUser } from '../../hooks/useUser';

type SelectFieldProps = {
  size?: TextFieldSizes;
  selectClassName?: string;
  options: {
    name?: string;
    value: string | number;
    disabled?: boolean;
  }[];
  reactFormConfig?: RegisterOptions;
} & BaseSelectFieldProps &
  FormFieldProps;

/**
 * Component to be used within a FormProvider to get the form context
 */
export const SelectField: FunctionComponent<SelectFieldProps> = ({
  name,
  label,
  rightButton,
  error,
  className,
  description,
  descriptionLink,
  size = 'medium',
  selectClassName,
  options,
  reactFormConfig,
  ...props
}) => {
  const { register } = useFormContext();

  return (
    <FormField
      name={name}
      className={className}
      error={error}
      label={label}
      description={description}
      descriptionLink={descriptionLink}
      rightButton={rightButton}
    >
      <BaseSelectField
        size={size}
        className={selectClassName}
        id={name}
        options={options}
        {...props}
        {...register(name, {
          ...reactFormConfig,
        })}
      />
    </FormField>
  );
};

const ADD_NEW_PERSON_ROUTE = '/settings/officers-and-owners/create';
const ADD_NEW_PERSON_VALUE = 'addNewPerson';

type PersonSelectFieldProps = {
  name: string;
  label: string;
  error?: FieldErrorT;
  className?: string;
  description?: string;
  descriptionLink?: { url: string; text: string };
  reactFormConfig?: RegisterOptions;
  peopleData: Person[];
  peopleType: PeopleOptionsType;
};

export const PersonSelectField: FunctionComponent<PersonSelectFieldProps> = ({
  name,
  label,
  error,
  className,
  description,
  descriptionLink,
  reactFormConfig,
  peopleData,
  peopleType,
}) => {
  const { watch, setValue, setError } = useFormContext();
  const user = useUser();
  const personValue = watch(name);

  const [people, setPeople] = useState<Person[]>(peopleData);

  const handleRefreshPeopleData = async () => {
    try {
      const { data } = await fetchApi({
        url: '/api/legal_entity/people',
        method: 'GET',
      });

      setPeople(data);
    } catch (_err) {
      setError(name, {
        type: 'manual',
        message: 'Something went wrong, please try again.',
      });
    }
  };

  const handlePersonChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event.target.value === ADD_NEW_PERSON_VALUE) {
      let baseUrl;
      if (import.meta.env.PROD) {
        baseUrl = 'https://app.mosey.com';
      } else {
        baseUrl = 'localhost:3000';
      }
      window.open(`${baseUrl}${ADD_NEW_PERSON_ROUTE}`, '_blank');

      // Prevent from selecting the add new person label
      setValue(name, personValue);
    }
  };

  const getOptions = () => {
    const result: SelectOptionType[] = [
      {
        name: 'Choose One',
        value: '',
      },
    ];

    const filteredOptions = filterPeople(peopleType, people);

    filteredOptions.forEach((person: Person) => {
      result.push({
        name: `${person.first_name} ${person.last_name}`,
        value: person.email,
      });
    });
    if (!user.is_platform_user) {
      result.push({
        name: 'Add a new person',
        value: ADD_NEW_PERSON_VALUE,
      });
    }
    return result;
  };

  return (
    <SelectField
      onClick={handleRefreshPeopleData}
      name={name}
      label={label}
      error={error}
      className={className}
      description={description}
      descriptionLink={descriptionLink}
      options={getOptions()}
      reactFormConfig={{
        onChange: handlePersonChange,
        ...reactFormConfig,
      }}
    />
  );
};

type UsStateSelectFieldProps = {
  name: string;
  label: string;
  error?: FieldErrorT;
  className?: string;
  description?: string;
  descriptionLink?: { url: string; text: string };
  reactFormConfig?: RegisterOptions;
  limitedStateOptions?: string[];
  disabledStateOptions?: string[];
  defaultValue?: string;
};

export const UsStateSelectField: FunctionComponent<UsStateSelectFieldProps> = ({
  name,
  label,
  error,
  className,
  description,
  descriptionLink,
  reactFormConfig,
  limitedStateOptions = [],
  disabledStateOptions = [],
  defaultValue,
}) => {
  const lowerLimitedStateOptions = limitedStateOptions.map((option) =>
    option.toLowerCase(),
  );
  const lowerDisabledStateOptions = disabledStateOptions.map((option) =>
    option.toLowerCase(),
  );
  const options: SelectOptionType[] = Object.entries(USStateName)
    .filter((entry) => {
      if (!lowerLimitedStateOptions.length) {
        return true;
      }
      const [regionCode, regionName] = entry;

      return (
        lowerLimitedStateOptions.includes(regionCode.toLowerCase()) ||
        lowerLimitedStateOptions.includes(regionName.toLowerCase())
      );
    })
    .map((entry) => {
      const [regionCode, regionName] = entry;

      const isDisabled =
        lowerDisabledStateOptions.includes(regionCode.toLowerCase()) ||
        lowerDisabledStateOptions.includes(regionName.toLowerCase());

      return { name: regionName, value: regionCode, disabled: isDisabled };
    });
  options.unshift({ name: 'Select a state', value: '' });

  return (
    <SelectField
      name={name}
      label={label}
      error={error}
      className={className}
      description={description}
      descriptionLink={descriptionLink}
      options={options}
      reactFormConfig={reactFormConfig}
      data-testid={`select-field-button-${name}`}
      defaultValue={defaultValue}
    />
  );
};

type CountrySelectFieldProps = UsStateSelectFieldProps;

export const CountrySelectField: FunctionComponent<CountrySelectFieldProps> = ({
  name,
  label,
  error,
  className,
  description,
  descriptionLink,
  reactFormConfig,
}) => {
  const options: SelectOptionType[] = Object.entries(CountryCode).map(
    (entry) => {
      const [countryCode, countryName] = entry;
      return { name: countryName, value: countryCode };
    },
  );
  options.unshift({ name: 'Select a country', value: '' });

  return (
    <SelectField
      name={name}
      label={label}
      error={error}
      className={className}
      description={description}
      descriptionLink={descriptionLink}
      options={options}
      reactFormConfig={reactFormConfig}
      defaultValue={'US'}
    />
  );
};
