import { Link, useParams } from 'react-router-dom';
import { Button } from '@mosey/components/buttons/Button';
import { ProcessingTasks } from '@mosey/components/graphics/ProcessingTasks.tsx';
import { CompleteTasks } from '@mosey/components/graphics/CompleteTasks';
import {
  useFinishUrl,
  useWizardConfig,
  useWizardCurrentStep,
  useWizardCurrentStepIndex,
  useWizardUrl,
} from '../utils/hooks';
import { WizardInterstitial } from './WizardInterstitial';

export const WizardConclusion = () => {
  const { wizardSlug } = useParams();
  const {
    conclusion: { title, body },
  } = useWizardConfig();
  const currentStep = useWizardCurrentStep();
  const currentStepIndex = useWizardCurrentStepIndex();
  const finishUrl = useFinishUrl();
  const wizardUrl = useWizardUrl();

  if (currentStep !== null) {
    return (
      <WizardInterstitial
        title="You’re not done quite yet"
        body="Please go back and complete all steps."
        graphic={<ProcessingTasks />}
        actions={
          <>
            <Button as={Link} size="xlarge" variant="secondary" to={finishUrl}>
              Finish later
            </Button>
            <Button
              as={Link}
              size="xlarge"
              to={`${wizardUrl}/step/${currentStep.slug}`}
            >
              Back to step {currentStepIndex + 1}
            </Button>
          </>
        }
      />
    );
  }

  let graphic = null;

  // TODO: Move this to Wizard Config once we have Admin capabilities
  if (wizardSlug === 'new-coverage' || wizardSlug === 'new-coverage-local') {
    graphic = <CompleteTasks />;
  }

  return (
    <WizardInterstitial
      title={title}
      body={body}
      graphic={graphic}
      actions={
        <Button as={Link} size="xlarge" to={finishUrl}>
          Finish
        </Button>
      }
    />
  );
};
