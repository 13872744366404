import { components, TaskStatus, TaskType } from '@mosey/api-types';
import {
  convertDateStringToLocalDate,
  formatDateFromString,
  getLocalTodayMidnight,
} from '../dates';

type Task = components['schemas']['Task'];
type TaskRef = components['schemas']['TaskRef'];

export const isTaskSetupRelated = ({
  source: { is_setup_related: isSetupRelated },
}: TaskRef) => {
  return isSetupRelated;
};

export const isTaskTodo = ({ status }: Task | TaskRef) => {
  return status === TaskStatus.todo;
};

export const isTaskDone = ({ status }: Task | TaskRef) => {
  return status === TaskStatus.done;
};

export const isTaskOverdue = ({ status, source }: Task | TaskRef) => {
  if (source.type === TaskType.requirement && 'due_date' in source) {
    const { due_date: dueDate, managed_provider: managedProvider } = source;

    if (managedProvider) {
      return false;
    }

    if (dueDate && status === TaskStatus.todo) {
      const localToday = getLocalTodayMidnight();
      const localDueDate = convertDateStringToLocalDate(dueDate);

      return localDueDate < localToday;
    }
  }

  return false;
};

/**
 * Automated here is past tense, so the task must have status of done. This is
 * distinct from managed or in progress tasks.
 */
export const isTaskAutomated = (task: TaskRef | Task) => {
  return (
    task.source.type === 'requirement' &&
    task.status === 'done' &&
    task.source.managed_provider?.name === 'Mosey'
  );
};

export const isTaskManaged = (task: TaskRef | Task) => {
  return (
    task.source.type === 'requirement' &&
    task.source.managed_provider &&
    task.source.managed_provider.name !== 'Mosey'
  );
};

export const isTaskInProgress = (task: TaskRef) => {
  return (
    task.source.type === 'requirement' &&
    task.status === 'in-progress' &&
    task.source.managed_provider?.name === 'Mosey'
  );
};

export type CalculatedTaskStatus =
  | Exclude<TaskStatus, 'deferred'>
  | 'managed'
  | 'overdue'
  | 'automated'
  | 'update-available';

export const getCalculatedTaskStatus = (
  task: TaskRef | Task,
): CalculatedTaskStatus => {
  const { status } = task;
  let calculatedStatus: CalculatedTaskStatus =
    status === 'deferred' ? TaskStatus.todo : status;

  if (
    status === 'todo' &&
    'creation_reason' in task.source &&
    task.source.creation_reason === 'policy_update'
  ) {
    calculatedStatus = 'update-available';
  } else if (isTaskManaged(task)) {
    calculatedStatus = 'managed';
  } else if (isTaskOverdue(task)) {
    calculatedStatus = 'overdue';
  } else if (isTaskAutomated(task)) {
    calculatedStatus = 'automated';
  }

  return calculatedStatus;
};

export const getTaskDueDate = ({ source }: Task | TaskRef) => {
  return source.type === TaskType.requirement &&
    'due_date' in source &&
    source.due_date
    ? formatDateFromString(source.due_date, {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      })
    : null;
};
